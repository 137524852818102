<style>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
    overflow-y: auto;
}
.modal-body{
     height: 500px;
  width: 100%;
    overflow-y: auto;
}
.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}
.pac-container {
    z-index: 10000 !important;
}
</style>
<template>
    <div class="row">
        <div v-if="showModal">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title text-info">
                                        Crear pvi
                                    </h4>
                                    <button
                                        type="button"
                                        class="close"
                                        @click="showModal = false"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body" id="errorsForm" ref="errorsForm">
                                    <div class="form-group" v-if="errorsPvi.length>0" >
                                        <div class="card">
                                            <div class="card-body bg-danger text-white">
                                                Errores al llenar los datos
                                                <ul>
                                                    <li :key="error" v-for="error in errorsPvi">{{ error }}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                   <div class="form-group">
                                        <label>Jovenes:</label>
                                        <v-select v-model="pvi.nnaj_id" label="nombres" :options="jovenes" @input="setProyect">
                                            <template slot="selected-option" slot-scope="option">
                                                <div class="flex">
                                                    <div class="col">
                                                    <span class="fa"></span>
                                                    <span>{{ `${option.nombres} ${option.apellido_paterno} ${option.apellido_materno}`}}</span>
                                                    </div>
                                                </div>
                                            </template>
                                            <template slot="option" slot-scope="option">
                                                <span>{{ `${option.nombres} ${option.apellido_paterno} ${option.apellido_materno}`}}</span>
                                            </template>
                                        </v-select>
                                    </div>
                                    <div class="form-group" v-if="pvi.nnaj_id&&proyectos.length>0">
                                        <label>Proyecto:</label>
                                       <v-select v-model="pvi.proyect_id" label="short_name" :options="proyectos" @input="setTimeProperty">
                                        </v-select>
                                    </div>
                                    <div class="form-group">
                                        <label>Tiempo permanencia (dia/s)</label>
                                        <input
                                            type="number"
                                            class="form-control"
                                            v-model="pvi.time_permanency"
                                            readonly
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label>Fecha</label>
                                        <input
                                            type="date"
                                            class="form-control"
                                            v-model="pvi.date"
                                        />
                                    </div>
                                     <div class="form-group">
                                        <label>Adjuntar archivo</label>
                                        <input type="file" class="form-control" ref="files" name="filesArray" multiple @change="onChangeFile">
                                    </div>
                                </div>
                               
                                <div class="modal-footer">
                                    <button
                                        class="btn btn-danger"
                                        @click="cancelarModal()"
                                    >
                                        <i class="fa fa-times"></i>
                                        Cancelar
                                    </button>
                                       <button
                                        class="btn btn-success"
                                        @click="createPvi"
                                    >
                                        <i class="fa fa-check"></i>
                                        Crear PVI
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <div v-if="showModalRueda">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title text-info">
                                        Rueda de la vida
                                    </h4>
                                    <button
                                        type="button"
                                        class="close"
                                        @click="showModal = false"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                               
                                <div class="modal-body">
                                      <div class="form-group" v-if="errorsWheel.length>0" >
                                        <div class="card">
                                            <div class="card-body bg-danger text-white">
                                                Errores al llenar los datos
                                                <ul>
                                                    <li :key="error" v-for="error in errorsWheel">{{ error }}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Dimension:</label>
                                        <v-select v-model="wheel.dimension_id" multiple @input="changeSelectWheel" label="name" :options="dimensiones"></v-select>
                                    </div>
                                    <div class="form-group" v-if="wheel.dimension_id.length>0">
                                        <label><b>Evalue las dimensiones:</b></label>
                                        <div :key="dimension.id" v-for="dimension in wheel.dimension_id">
                                            <label>{{ dimension.name }}</label>
                                            <input
                                                @keyup="evaluarDimension($event, dimension)"
                                                type="number"
                                                :min="0"
                                                :max="7"
                                                class="form-control"
                                            />
                                        </div>
                                    </div>
                                     <div class="form-group">
                                        <label>Observaciones</label>
                                        <vue-editor v-model="wheel.comment"></vue-editor>                                  
                                    </div>
                            
                                </div>
                               
                                <div class="modal-footer">
                                    <button
                                        class="btn btn-danger"
                                        @click="cancelarModal()"
                                    >
                                        <i class="fa fa-times"></i>
                                        Cancelar
                                    </button>
                                       <button
                                        class="btn btn-success"
                                        @click="createRueda"
                                    >
                                        <i class="fa fa-check"></i>
                                        Crear Ruerda de la vida
                                    </button>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
         <div v-if="showModalObjetivos">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title text-info">
                                        Objetivos pvi
                                    </h4>
                                    <button
                                        type="button"
                                        class="close"
                                        @click="cancelarModal()"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                     <div class="form-group" v-if="errorsObjetives.length>0" >
                                        <div class="card">
                                            <div class="card-body bg-danger text-white">
                                                Errores al llenar los datos
                                                <ul>
                                                    <li :key="error" v-for="error in errorsObjetives">{{ error }}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Dimension:</label>
                                        <v-select v-model="objetive.dimension_id" label="name" :options="dimensiones"></v-select>
                                    </div>
                                    <div class="form-group">
                                        <label>Objetivo</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="objetive.purpose"
                                        />
                                    </div>
                                     <div class="form-group">
                                        <label>Termino</label>
                                        <textarea
                                            type="text"
                                            class="form-control"
                                            v-model="objetive.term"
                                        >
                                        </textarea>
                                      
                                    </div>
                                </div>
                               
                                <div class="modal-footer">
                                    <button
                                        class="btn btn-danger"
                                        @click="cancelarModal()"
                                    >
                                        <i class="fa fa-times"></i>
                                        Cancelar
                                    </button>
                                       <button
                                        class="btn btn-success"
                                        @click="createObjetivos"
                                    >
                                        <i class="fa fa-check"></i>
                                        Crear Objetivo
                                    </button>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <div v-if="showModalSessionWeek">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title text-info">
                                        Sesion semanal PVI
                                    </h4>
                                    <button
                                        type="button"
                                        class="close"
                                        @click="cancelarModal()"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="form-group">
                                        <label>Asiste:</label>
                                        <div class="form-check">
                                            <label class="form-check-label">
                                                <input type="radio" id="asiste" :value="true" v-model="session_week.attend">
                                                Si
                                            </label>
                                            </div>
                                            <div class="form-check">
                                            <label class="form-check-label">
                                                <input type="radio" id="falta" :value="false" v-model="session_week.attend">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group" v-if="session_week.attend==false">
                                        <label>Justifica inasistencia:</label>
                                         <select class="form-control" v-model="session_week.justify_attend">
                                            <option value="">Seleccione una opcion</option>
                                            <option :value="true">Si</option>
                                            <option :value="false">No</option>
                                            <option value="No aplica">No aplica</option>
                                         </select>
                                    </div>
                                    <div class="form-group" v-if="session_week.justify_attend==true">
                                        <label>Justificacion:</label>
                                         <textarea
                                            type="text"
                                            class="form-control"
                                            v-model="session_week.justify_comment"
                                        >
                                        </textarea>
                                    </div>
                                     <div class="form-group">
                                        <label>Dimension:</label>
                                        <div class="form-check">
                                            <label class="form-check-label">
                                                <input type="radio" id="asiste" :value="true" v-model="session_week.has_dimension">
                                                Si
                                            </label>
                                            </div>
                                            <div class="form-check">
                                            <label class="form-check-label">
                                                <input type="radio" id="falta" :value="false" v-model="session_week.has_dimension">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group" v-if="session_week.has_dimension">
                                        <label>Dimension:</label>
                                        <v-select v-model="session_week.dimension_id" multiple @input="changeSelectSessionWeek" label="name" :options="dimensiones"></v-select>
                                    </div>
                                    <div class="form-group" v-if="session_week.dimension_id.length>0">
                                        <label><b>Evalue las dimensiones:</b></label>
                                        <div :key="dimension.id" v-for="dimension in session_week.dimension_id">
                                            <label>{{ dimension.name }}</label>
                                            <textarea
                                                @keyup="evaluarDimensionWeek($event, dimension)"
                                                type="text"
                                                class="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Observaciones</label>
                                        <textarea
                                            type="text"
                                            class="form-control"
                                            v-model="session_week.comment"
                                        >
                                        </textarea>
                                    </div>
                                </div>
                               
                                <div class="modal-footer">
                                    <button
                                        class="btn btn-danger"
                                        @click="cancelarModal()"
                                    >
                                        <i class="fa fa-times"></i>
                                        Cancelar
                                    </button>
                                       <button
                                        class="btn btn-success"
                                        @click="createSessionWeek"
                                    >
                                        <i class="fa fa-check"></i>
                                        Crear Sesion Semanal
                                    </button>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <!--- modal para ver el resumen con la opcion de exportarlo a pdf-->
        <div v-if="showModalResumen">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title text-info">
                                        Resumen pvi {{this.caso.id}}
                                    </h4>
                                    <button
                                        type="button"
                                        class="close"
                                        @click="cancelarModal()"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="form-group">
                                       <label>Fecha:</label> {{this.caso.date}}
                                    </div>
                                     <div class="form-group">
                                      Joven: {{this.caso.joven.nombres}} {{this.caso.joven.apellido_paterno}} {{this.caso.joven.apellido_matero}}
                                    </div>
                                     <div class="form-group">
                                      Proyecto: {{this.caso.proyecto.short_name}}
                                    </div>
                                     <div class="form-group">
                                      Tiempo permanencia: {{this.caso.time_permanency}} dia/s
                                    </div>
                                    <h3>Documentos</h3>
                                    <hr>
                                    <div class="form-group" v-if="this.caso.documentos.length>0">
                                        <li :key="item.id" v-for="item in this.caso.documentos">  
                                            {{item.name}}    
                                            <div class="btn-group">                       
                                                <a :href="`/uploadedimages/${item.uri}`" class="btn btn-info text-white" title="Ver adjunto" target="_blank">
                                                    <i class="fa fa-eye">
                                                    </i>
                                                </a>
                                                <a :href="`/uploadedimages/${item.uri}`" :download="item.name" title="Descargar adjunto" class="btn btn-success" target="_blank">
                                                    <i class="fa fa-download">
                                                    </i>
                                                </a>
                                            </div>
                                        </li>
                                    </div>
                                    <div class="form-group" v-else>
                                        <div class="card" >
                                            <div class="card-body bg-danger col-12 text-white text-center">
                                                <b>Sin documentos</b>
                                            </div>
                                        </div>
                                    </div>
                                    <h3>Objetivos</h3>
                                    <hr>
                                    <div class="form-group" v-if="this.caso.objetivos.length">
                                        <li :key="item.id" v-for="item in this.caso.objetivos">
                                           Dimension: {{ item.dimension }} - Objetivo: {{ item.purpose }} - Termino: {{ item.term}}
                                           <acciones-component :props-objetivo="item"></acciones-component>
                                        </li>
                                    </div>
                                    <div class="form-group" v-else>
                                        <div class="card" >
                                            <div class="card-body bg-danger col-12 text-white text-center">
                                                <b>Sin objetivos</b>
                                            </div>
                                        </div>
                                    </div>
                                    <h3>Rueda:</h3>
                                    <hr>
                                    <div class="form-group" v-if="this.caso.ruedas.length">
                                        <label>Evaluaciones:</label>
                                        <li :key="rueda.id" v-for="rueda in this.caso.ruedas">
                                            Dimension: <label class="badge badge-primary">{{ rueda.dimension }}</label> - Puntaje: {{rueda.score}}
                                        </li>
                                    </div>
                                    <div class="form-group" v-else>
                                        <div class="card" >
                                            <div class="card-body bg-danger col-12 text-white text-center">
                                                <b>Sin rueda</b>
                                            </div>
                                        </div>
                                    </div>
                                    <h3>Sesiones Semanales:</h3>
                                    <hr>
                                    <div v-if="this.caso.sessionSemanal.length">
                                        <div class="card bg-primary text-white" style="margin-botton:'10px';">
                                            <div class="card-body" :key="sesion.id" v-for="sesion in this.caso.sessionSemanal">
                                                <label>Asiste: {{sesion.attend ? 'Si': 'No'}}</label>
                                                <br>
                                                <label>Justifica Asistencia: {{sesion.justify_attend}}</label>
                                                <br>
                                                <label>Observacion: {{sesion.comment}}</label>
                                                <br>
                                                <li :key="evaluacion.id" v-for="evaluacion in sesion.evaluaciones">
                                                    Dimension: <label class="badge badge-primary">{{ evaluacion.dimension }}</label> - Puntaje: {{evaluacion.score}}
                                                </li>
                                            </div>      
                                        </div>
                                    </div>
                                    <div class="form-group" v-else>
                                        <div class="card" >
                                            <div class="card-body bg-danger col-12 text-white text-center">
                                                <b>Sin sesiones semanales</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button
                                        class="btn btn-danger"
                                        @click="showModalResumen = false"
                                    >
                                        <i class="fa fa-times"></i>
                                        Cerrar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <!-- modal creacion de PVI -->
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h1>PVI</h1>
                    <hr>
                    <div class="form-group text-right">
                        <button
                            class="btn btn-rosa"
                            @click="showModal = true"
                        >
                            <i class="fa fa-plus"></i>
                            Crear pvi
                        </button>
                    </div>
                    <div class="form-group">
                        <vue-good-table
                            compactMode
                            :columns="columns"
                            :rows="casos" 
                            :search-options="{
                                enabled: true,
                                placeholder: 'Buscar en la tabla',
                            }" 
                            :pagination-options="{
                                enabled: true,
                                mode: 'records',
                                perPage: 5,
                                perPageDropdown: [3, 7, 9],
                                dropdownAllowAll: true,
                                setCurrentPage: 1,
                                nextLabel: 'siguiente',
                                prevLabel: 'anterior',
                                rowsPerPageLabel: 'Filas por página',
                                ofLabel: 'de',
                                pageLabel: 'página', // for 'pages' mode
                                allLabel: 'Todos',
                            }">
                              <template slot="table-row" slot-scope="props">
                                  <span v-if="props.column.field == 'participantes'">
                                      <ul>
                                         <li :key="item.id" v-for="item in props.row.users">{{item.name}}</li>
                                      </ul>
                                  </span>
                                <span v-if="props.column.field == 'actions'">
                                    <div class="btn-group">
                                         <button class="btn btn-danger text-white" @click="generarPdf(props.row.id)">
                                            <i class="fa fa-file-pdf"></i> 
                                        </button>
                                        <button class="btn btn-primary text-white" title="Ver resumen" @click="verResumen(props.row)">
                                            <i class="fa fa-eye"></i> 
                                        </button>
                                        <button class="btn btn-info text-white" title="Ver rueda de la vida" @click="verRueda(props.row)">
                                            <i class="fa fa-heart"></i>
                                        </button>
                                        <button class="btn btn-success text-white" title="Crear Objetivos" @click="verObjetivo(props.row)">
                                            <i class="fa fa-check-square-o"></i>
                                        </button>                                       
                                        <button class="btn btn-warning text-white" title="Crear sesion semanal" @click="verSesionSemanal(props.row)">
                                            <i class="fa fa-calendar"></i>
                                        </button>
                                        <button class="btn btn-danger" @click="eliminarCaso(props.row.id)">
                                            <i class="fa fa-trash"></i>
                                        </button>
                                    </div>
                                </span>
                                <span v-else>
                                    {{props.formattedRow[props.column.field]}}
                                </span>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import Swal from 'sweetalert2';
import 'vue-select/dist/vue-select.css';
export default {
    props:['propsUsuarios', 'propsDimensiones', 'propsJovenes'],
    computed: {},
    created() {
        this.usuarios = JSON.parse(this.propsUsuarios);
        this.dimensiones = JSON.parse(this.propsDimensiones);
        console.log("los dimensiones son: ", this.dimensiones);

        this.jovenes = JSON.parse(this.propsJovenes);
        console.log("los usuarios son: ", this.usuarios);
       
        console.log("los jovenes son: ", this.jovenes);
    },
    async mounted(){
        let names = this.dimensiones.map( e =>  e.name);
        console.log("los scoreDimensions son: ", names);

        await this.getPvi();
    },
    data() {
        return {
            errorsObjetives: [],
            errorsPvi: [],
            errorsWheel: [],
            scoreDimensions: [],
            objetive:{
                term:"",
                purpose: "",
                dimension_id: ""
            },
            pvi:{
                id: "",
                time_permancy: "",
                nnaj_id: "",
                date: "",
                proyect_id: ""
            },
            wheel:{
                dimension_id: [],
                scores: [],
                nnaj_id: "",
                comment: "",
                scores:[]
            },
            session_week:{
                attend: true,
                justify_attend: "",
                justify_comment: "",
                has_dimension: false,
                dimension_id: "",
                comment: "",
                scores:[]
            },
            columns: [
                {
                    label: 'Fecha',
                    field: 'date',
                    dateInputFormat: 'yyyy-MM-dd',
                    dateOutputFormat: 'MMM do yy',
                },
                {
                    label: 'Joven',
                    field: 'joven.nombres',
                    type: 'string',
                },
                {
                    label: 'Creado en',
                    field: 'created_at',
                    formatFn: this.formatDate,
                },
                {
                    label: 'Acciones',
                    field: 'actions',
                    tdClass: 'text-center text-nowrap',
                    thClass: 'text-center text-nowrap',
                    sortable: false,
            }],
            attachment: [],
            filesArray: null,
            pvi_id:null,
            score: "",
            term: "",
            purpose: "",
            usuarios: [],
            dimensiones: [],
            jovenes: [],
            user_id: "",
            time_permancy:"",
            file_id:"",
            users: [],
            date: "",
            nnaj_id: "",
            dimension_id: "",
            comment: "",
            showModal: false,
            showModalView: false,
            casos: [],
            respuestas: false,
            relato: "",
            hecho: "",
            necesidad: "",
            interes: "",
            emocion: "",
            posicion: "",
            actores: "",
            conflicto: "",
            keyword: "",
            pregunta: "",
            estrategia: "",
            ////
            showRelato: "",
            showHecho: "",
            showNecesidad: "",
            showInteres: "",
            showEmocion: "",
            showPosicion: "",
            showActores: "",
            showConflicto: "",
            showKeyword: "",
            showPregunta: "",
            showEstrategia: "",
            showModalRueda: false,
            showModalObjetivos: false,
            showModalResumen: false,
            showModalSessionWeek: false,
        };
    },
    methods: {
         async generarPdf(id){
            
            Swal.fire({
                title: 'Estamos generando tu reporte, espera unos segundos',
                text: 'El reporte PDF simplemente se desrgara en su equipo...',
                iconHtml: "<i class='fas fa-circle-notch fa-spin'></i>",
                timer: 5000,
                timerProgressBar: true,
                showConfirmButton: false,
                didOpen: () => {
                    window.location.href = `/generate-pdf-pvi/${id}`;
                }
            }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                    console.log('I was closed by the timer')
                }
            });
        },
        setProyect(){
            console.log("el joven del pvi es: ", this.pvi.nnaj_id);
            let proyectos = this.pvi.nnaj_id.proyectos.map(e => {
                return e.proyecto;
            });
            this.proyectos = proyectos;
            console.log("los proyectos son: ", proyectos);
        },
        setTimeProperty(e){
            console.log("valor select proyectoes : ", e.time_permanency);
            this.pvi.time_permanency = e.time_permanency;
        },
        formatDate(date){
            var date = new Date(date);
            return date.toLocaleString();
        },
        evaluarDimension(event, dimension){
            console.log("el evento evaluando dimension: ", event.target.value);
            console.log("la dimension es: ", dimension);
            let foundedScore = this.wheel.scores.find(e => e.dimension_id === dimension.id);
            if(foundedScore){
                foundedScore.score = event.target.value;
            }else{
                this.wheel.scores.push({ "dimension_id": dimension.id, "score": event.target.value });
            }
            
        },
        evaluarDimensionWeek(event, dimension){
            console.log("el evento es: ", event.target.value);
            console.log("la dimension es: ", dimension);
            let foundedScore = this.session_week.scores.find(e => e.dimension_id === dimension.id);
            if(foundedScore){
                foundedScore.score = event.target.value;
            }else{
                this.session_week.scores.push({ "dimension_id": dimension.id, "score": event.target.value });
            }  
        },
        changeSelectWheel(e){
            if(e.length == 0){
                this.wheel.scores = [];
            }else{
                this.wheel.scores = [];
                e.map(dimension => {
                    this.wheel.scores.push({ "dimension_id": dimension.id, "score": '' });
                }); 
            }
        },
        changeSelectSessionWeek(e){
            if(e.length == 0){
                this.session_week.scores = [];
            }else{
                this.session_week.scores = [];
                e.map(dimension => {
                    this.session_week.scores.push({ "dimension_id": dimension.id, "score": '' });
                }); 
            }
        },
        onChangeFile(event){
            let selectedFiles = event.target.files;
            if(!selectedFiles.length) {
                return false;
            }
            console.log("los selectedFiles son: ", selectedFiles);
            for (let i = 0; i < selectedFiles.length; i++){
                this.attachment.push(selectedFiles[i]);
            }
            console.log("los archivos son: ", this.filesArray);
        },
        getPvi() {
            axios
                .get("/getPvi")
                .then(response => {
                    console.log("la response es: ", response.data);
                    this.casos = response.data.casos;
                })
                .catch(function(error) {
                    // handle error
                    console.log("ocurrio un error: ", error);
                });
        },
        checkFormPvi: function (e) {
            console.log("el evento a validar es: ", e);
            e.preventDefault();
            let errors = [];
            console.log("el caso es: ", this.caso);
            if (!this.pvi.date) {
                errors.push('Debe seleccionar un fecha.');
            }
            if (!this.pvi.proyect_id) {
                errors.push('Debe seleccionar un proyecto.');
            }
            if (!this.pvi.nnaj_id) {
                errors.push('Debe seleccionar un joven.');
            }
            if(errors.length > 0 ){
                this.errorsPvi = errors;
                return false;
            }else{
                return true;
            }
        },
        checkFormRueda: function (e) {
            console.log("el evento a validar es: ", e);
            e.preventDefault();
            let errors = [];
            console.log("el caso es: ", this.caso);
            if (!this.wheel.dimension_id) {
                errors.push('Debe seleccionar al menos una dimension a evaluar.');
            }
            if (!this.wheel.comment) {
                errors.push('Debe seleccionar un comentario.');
            }
            if (this.wheel.scores.length == 0) {
                errors.push('Debe seleccionar una dimension para al menos tener una evaluacion.');
            }
            if (this.wheel.scores.length > 0) {
                this.wheel.scores.map(e => {
                    if(e.score == ""){
                        errors.push(`Debe ingresar un puntaje para la dimension seleccionada`);
                    }
                });
            }
            if(errors.length > 0 ){
                this.errorsWheel = errors;
                return false;
            }else{
                return true;
            }
        },
        checkFormObjetivos: function (e) {
            console.log("el evento a validar es: ", e);
            e.preventDefault();
            let errors = [];
            console.log("el caso es: ", this.caso);
     
            if (!this.objetive.dimension_id) {
                errors.push('Debe seleccionar una dimension para el objetivo.');
            }
            if (!this.objetive.purpose) {
                errors.push('Debe ingresar un proposito.');
            }
            if (!this.objetive.term) {
                errors.push('Debe ingresar un termino.');
            }
            if(errors.length > 0 ){
                this.errorsObjetives = errors;
                return false;
            }else{
                return true;
            }
        },
        createPvi(e) {       
            let validForm = this.checkFormPvi(e);
            if(validForm){
                let formData = new FormData();
                formData.append('date', this.pvi.date);
                formData.append('nnaj_id', this.pvi.nnaj_id.id);
                formData.append('proyect_id', this.pvi.proyect_id.id);
                console.log("el proyecto id es: ", this.pvi.proyect_id);
                for (let index = 0; index < this.attachment.length; index++) {
                    formData.append('files[]', this.attachment[index]);
                }
                console.log(
                    "la data al agregar el pvi es, multipart-2: ",
                    formData
                );
                let me = this;
                axios
                    .post("/create_pvi",
                    formData, {
                        headers: {
                            'Content-Type': "multipart/form-data" 
                        }
                                
                    })
                    .then(function(response) {
                        console.log(
                            "la repsuesta al agregar el pvi es: ",
                            response
                        );
                        Swal.fire('Pvi creado!', '', 'success');
                        me.cancelarModal();
                        me.getPvi();
                
                    })
                    .catch(function(error) {
                        console.log("FAILURE!!", error);
                    });
            }else{

            }

        },
         createRueda(e) {
             let validForm = this.checkFormRueda(e);
             if(validForm){
            let data = {
                pvi_id: this.wheel.pvi_id,
                scores: this.wheel.scores,
                comment: this.wheel.comment
            };
            console.log(
                "la data al agregar la rueda es: ",
                data
            );
            let me = this;
            axios
                .post("/create_rueda", data, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then(function(response) {
                    console.log(
                        "la repsuesta al agregar el rueda es: ",
                        response
                    );
                    Swal.fire('Rueda pvi creada!', '', 'success')
                    me.cancelarModal();
                    me.getPvi();
               
                })
                .catch(function(error) {
                    console.log("FAILURE!!", error);
                });
             }else{
                 
             }
        
        },
        createObjetivos(e) {
            let validatedForm = this.checkFormObjetivos(e);
            if(validatedForm){
                let data = {
                    pvi_id: this.objetive.pvi_id,
                    purpose: this.objetive.purpose,
                    dimension_id: this.objetive.dimension_id.id,
                    term: this.objetive.term,
                };
                console.log(
                    "la data al agregar los objetivos la data  es: ",
                    data
                );
                let me = this;
                axios
                .post("/create_objetivos", data, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then(function(response) {
                    console.log(
                        "la repsuesta al agregar el rueda es: ",
                        response
                    );
                    Swal.fire('Objetivo pvi creado!', '', 'success')
                    me.cancelarModal();
                    me.getPvi();
               
                })
                .catch(function(error) {
                    console.log("FAILURE!!", error);
                });
            }else{

            }
      
        },
        createSessionWeek(e) {
            let data = {
                attend: this.session_week.attend,
                justify_attend: this.session_week.justify_attend,
                justify_comment: this.session_week.justify_comment,
                scores: this.session_week.has_dimension == true ? this.session_week.scores : null,
                comment: this.session_week.comment,
                pvi_id: this.session_week.pvi_id
            };
            console.log(
                "la data al agregar la sesion semanal es la data  es: ",
                data
            );
            let me = this;
            axios
                .post("/create_session_week", data, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then(function(response) {
                    console.log(
                        "la repsuesta al agregar la sesion semanal es: ",
                        response
                    );
                    me.cancelarModal();
                    me.getPvi();
                    Swal.fire('Session semanal creada!', '', 'success')
                })
                .catch(function(error) {
                    console.log("FAILURE!!", error);
                });
        },
        verCaso(caso){
            console.log("el caso es: ",caso),
            this.caso = caso;
            this.showModalView = true;
        },
        verRueda(caso){
            console.log("el caso es: ",caso),
            this.caso = caso;
            this.wheel.pvi_id = caso.id;
            this.showModalRueda = true;
        },
         verResumen(caso){
            console.log("el caso es: ",caso),
            this.caso = caso;
            this.showModalResumen = true;
        },
        verObjetivo(caso){
            console.log("el caso es: ",caso),
            this.caso = caso;
            this.objetive.pvi_id = caso.id;
            this.showModalObjetivos = true;
        },
        verSesionSemanal(caso){
            console.log("el caso es: ",caso),
            this.caso = caso;
            this.session_week.pvi_id = caso.id;
            this.showModalSessionWeek = true;
        },
        cancelarModal(){
            this.showModal = false;
            this.showModalRueda = false;
            this.showModalResumen = false;
            this.showModalObjetivos = false;
            this.showModalSessionWeek = false;
            this.limpiarFormulario();
        },
        limpiarFormulario(){
            
            this.objetive = {
                term: "",
                dimension_id: "",
                purpose: "",
            };
            this.pvi = {
                id: "",
                time_permancy: 60,
                nnaj_id: "",
                date: ""
            };
            this.wheel = {
                dimension_id: [],
                nnaj_id: "",
                comment: "",
                scores:[]
            };
            this.session_week = {
                attend: true,
                justify_attend: "",
                justify_comment: "",
                has_dimension: false,
                dimension_id: "",
                comment: "",
                scores:[]
            };
            this.attachment = [],
            this.filesArray =  null;
            //limpieza de errores
            this.errorsPvi = [];
            this.errorsWheel = [];
            this.errorsObjetives = [];
        },
        eliminarCaso(id){
            let me = this;
            Swal.fire({
                title: '¿Esta seguro de querer borrar el PVI?',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: `Eliminar`,
                cancelButtonText:'Cancelar',
                }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    axios.delete(`/delete_pvi/${id}`, {
                            headers: {
                                "Content-Type": "application/json"
                            }
                        })
                        .then(function(response) {
                            console.log(
                                "la repsuesta al eliminar el institucion es: ",
                                response
                            );
                             Swal.fire('Pvi eliminado!', '', 'success')
                            me.getPvi();
                    
                        })
                        .catch(function(error) {
                            console.log("FAILURE!!", error);
                        });
                   
                } else if (result.isDenied) {
                    Swal.fire('Changes are not saved', '', 'info')
                }
                })
        },
    }
};
</script>
