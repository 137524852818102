<template>
<div class="container-fluid">
                <div class="card" style="width: 100%;" >
                    <div v-if="respuestas" >
                        <div class="card">
                            <div class="card-body">
                                <div class="col-12">
                                    1 {{ pregunta1.key }}
                                </div>
                                <div class="col-12">
                                    Respuesta: {{ pregunta1.answer }}
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <div class="col-12">
                                    2 {{ pregunta2.key }}
                                </div>
                                <div class="col-12">
                                    Respuesta: {{ pregunta2.answer }}
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <div class="col-12">
                                    3 {{ pregunta3.key }}
                                </div>
                                <div class="col-12">
                                    Respuesta: {{ pregunta3.answer }}
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <div class="col-12">
                                    2 {{ pregunta4.key }}
                                </div>
                                <div class="col-12">
                                    Respuesta: {{ pregunta4.answer }}
                                </div>
                            </div>
                        </div>
                        <button class="btn btn-danger" @click="respuestas=false">Ocultar respuestas</button>
                     </div>
                     <button class="btn btn-info" @click="respuestas=true">Ver respuestas</button>
                    <div class="card-header" :class="[step1 ? 'bg-success' : 'bg-danger']" >EDUCACIÓN Y FORMACIÓN</div>
                    <div class="card-body" v-if="!step1">
                        <div class="row">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-header">
                                        <h1>1.{{ pregunta1.key }}
                                            <button disabled class="btn btn-lg" :class="pregunta1.value ? 'btn-success' : 'btn-warning text-white' ">{{ pregunta1.value ? 'Contestada' : 'Sin contestar' }}</button>
                                            <button @click="showQuestion(pregunta1)" class="btn btn-lg" :class="pregunta1.value ? 'btn-success' : 'btn-info text-white' ">{{ pregunta1.visible ? 'Ocultar' : 'Mostrar' }}</button>
                                        </h1>
                                    </div>
                                    <div class="card-body" >
                                       
                                        <div class="form-group">
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta1.value"
                                                        class="form-check-input"
                                                        name="pregunta1"
                                                        @change="getRespuesta(pregunta1, 'Nunca asistio' )"
                                                        value="0">Nunca asistio
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta1.value"
                                                        class="form-check-input"
                                                        name="pregunta1"
                                                        @change="getRespuesta(pregunta1, 'Educacion especial (diferencial)' )"
                                                        value="1">Educacion especial (diferencial)
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta1.value"
                                                        class="form-check-input"
                                                        name="pregunta1"
                                                        @change="getRespuesta(pregunta1, 'Educacion basica incompleta' )"
                                                        value="2">Educacion basica incompleta
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta1.value"
                                                        class="form-check-input"
                                                        value="3"
                                                        @change="getRespuesta(pregunta1, 'Educacion basica completa' )"
                                                        name="pregunta1">Educacion basica completa
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta1.value"
                                                        class="form-check-input"
                                                        name="pregunta1"
                                                        @change="getRespuesta(pregunta1, 'Educacion media cientifico-humanista incompleta' )"
                                                        value="4">Educacion media cientifico-humanista incompleta
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta1.value"
                                                        class="form-check-input"
                                                        name="pregunta1"
                                                        @change="getRespuesta(pregunta1, 'Educacion media cientifico-humanista completa' )"
                                                        value="5">Educacion media cientifico-humanista completa
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta1.value"
                                                        class="form-check-input"
                                                        name="pregunta1"
                                                        @change="getRespuesta(pregunta1, 'Educacion media tecnico profesional incompleta' )"
                                                        value="6">Educacion media tecnico profesional incompleta
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta1.value"
                                                        class="form-check-input"
                                                        name="pregunta1"
                                                        @change="getRespuesta(pregunta1, 'Educacion media tecnico profesional completa' )"
                                                        value="7">Educacion media tecnico profesional completa
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta1.value"
                                                        class="form-check-input"
                                                        name="pregunta1"
                                                        @change="getRespuesta(pregunta1, 'Tecnico de nivel superior incompleta' )"
                                                        value="8">Tecnico de nivel superior incompleta
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta1.value"
                                                        class="form-check-input"
                                                        name="pregunta1"
                                                        @change="getRespuesta(pregunta1, 'Tecnico de nivel superior completa' )"
                                                        value="9">Tecnico de nivel superior completa
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta1.value"
                                                        class="form-check-input"
                                                        name="pregunta1"
                                                        @change="getRespuesta(pregunta1, 'Profesional incompleta' )"
                                                        value="10">Profesional incompleta
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta1.value"
                                                        class="form-check-input"
                                                        name="pregunta1"
                                                        @change="getRespuesta(pregunta1, 'Profesional completa' )"
                                                        value="11">Profesional completa
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="col-12" v-if="pregunta1.value">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="form-group">
                                            <label>2.
                                                {{ pregunta2.key }}</label>
                                            <input v-model="pregunta2.value" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
        
                            <div class="col-12"  v-if="pregunta2.value">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <label>3 {{pregunta3.key}}</label>
                                                <div class="form-group">
                                                    <div class="form-check">
                                                        <label class="form-check-label">
                                                            <input
                                                                type="radio"
                                                                v-model="pregunta3.value"
                                                                class="form-check-input"
                                                                name="pregunta3"
                                                                @change="getRespuesta(pregunta3, 'Si' )"
                                                                value="1">Si
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label class="form-check-label">
                                                            <input
                                                                type="radio"
                                                                v-model="pregunta3.value"
                                                                class="form-check-input"
                                                                name="pregunta3"
                                                                @change="getRespuesta(pregunta3, 'No' )"
                                                                value="0">No
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
        
                            <div class="col-12" v-if="pregunta3.value=='1'">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <label>3.1 {{pregunta3_1.key}}</label>
                                                <div class="form-group">
                                                    <div class="form-check">
                                                        <label class="form-check-label">
                                                            <input type="radio" v-model="pregunta3_1.value" @change="getRespuesta(pregunta3_1, 'Escuela' )" class="form-check-input" name="pregunta3_1" value="1">Escuela
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label class="form-check-label">
                                                            <input type="radio" v-model="pregunta3_1.value" @change="getRespuesta(pregunta3_1, 'Instituto' )" class="form-check-input" name="pregunta3_1" value="2">Instituto
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label class="form-check-label">
                                                            <input type="radio" v-model="pregunta3_1.value" @change="getRespuesta(pregunta3_1, 'CFT' )" class="form-check-input" name="pregunta3_1" value="3">CFT
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label class="form-check-label">
                                                            <input type="radio" v-model="pregunta3_1.value" @change="getRespuesta(pregunta3_1, 'Centro de Capacitacion' )" class="form-check-input" name="pregunta3_1" value="4">Centro de Capacitacion
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label class="form-check-label">
                                                            <input type="radio" v-model="pregunta3_1.value" @change="getRespuesta(pregunta3_1, 'Universidad' )" class="form-check-input" name="pregunta3_1" value="5">Universidad
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label class="form-check-label">
                                                            <input type="radio" v-model="pregunta3_1.value" @change="getRespuesta(pregunta3_1, 'Otro' )" class="form-check-input" name="pregunta3_1" value="6">Otro
                                                        </label>
                                                    </div>
                                                    <label v-if="pregunta3_1.value=='6'">{{ pregunta3_1A.key }}</label>
                                                    <textarea v-if="pregunta3_1.value=='6'"  v-model="pregunta3_1A.value" type="text" class="form-control"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12" v-if="pregunta3_1A.value || pregunta3.value == '1' ">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label>4. {{pregunta4.key}}</label>
                                                    <input  v-model="pregunta4.value" type="text" class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12" v-if="pregunta3.value && pregunta3.value == '0' || pregunta4.value">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label>5. {{pregunta5.key}}</label>
                                                    <div class="form-check">
                                                        <label class="form-check-label">
                                                            <input type="radio" v-model="pregunta5.value" class="form-check-input" name="pregunta5" value="0">No
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label class="form-check-label">
                                                            <input type="radio" v-model="pregunta5.value" class="form-check-input" name="pregunta5" value="1">Si
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12" v-if="pregunta5.value == '1' ">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label>5.1 {{pregunta5_1.key}}</label>
                                                    <input v-if="pregunta5.value=='1'"  v-model="pregunta5_1.value" type="text" class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12" v-if="pregunta5.value || pregunta5_1.value">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label>6. {{pregunta6.key}}</label>
                                                    <div class="form-check">
                                                        <label class="form-check-label">
                                                            <input type="radio" v-model="pregunta6.value" class="form-check-input" name="pregunta6" value="1">Enseñanza básica completa
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label class="form-check-label">
                                                            <input type="radio" v-model="pregunta6.value" class="form-check-input" name="pregunta6" value="2">No creo que complete la enseñanza media
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label class="form-check-label">
                                                            <input type="radio" v-model="pregunta6.value" class="form-check-input" name="pregunta6" value="3">Enseñanza media completa
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label class="form-check-label">
                                                            <input type="radio" v-model="pregunta6.value" class="form-check-input" name="pregunta6" value="4">Carrera en Centro de Formación Técnica
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label class="form-check-label">
                                                            <input type="radio" v-model="pregunta6.value" class="form-check-input" name="pregunta6" value="5">Carrera en Instituto Profesional
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label class="form-check-label">
                                                            <input type="radio" v-model="pregunta6.value" class="form-check-input" name="pregunta6" value="6">Carrera Profesional Universitaria
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label class="form-check-label">
                                                            <input type="radio" v-model="pregunta6.value" class="form-check-input" name="pregunta6" value="88">No sabe
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label class="form-check-label">
                                                            <input type="radio" v-model="pregunta6.value" class="form-check-input" name="pregunta6" value="99">No responde
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12" v-if="pregunta6.value">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label>7. {{pregunta7.key}}</label>
                                                    <div class="form-check">
                                                        <label class="form-check-label">
                                                            <input type="radio" v-model="pregunta7.value" class="form-check-input" name="pregunta7" value="1">Enseñanza básica completa
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label class="form-check-label">
                                                            <input type="radio" v-model="pregunta7.value" class="form-check-input" name="pregunta7" value="2">Enseñanza media incompleta
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label class="form-check-label">
                                                            <input type="radio" v-model="pregunta7.value" class="form-check-input" name="pregunta7" value="3">Enseñanza media completa
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label class="form-check-label">
                                                            <input type="radio" v-model="pregunta7.value" class="form-check-input" name="pregunta7" value="4">Carrera en Centro de Formación Técnica
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label class="form-check-label">
                                                            <input type="radio" v-model="pregunta7.value" class="form-check-input" name="pregunta7" value="5">Carrera en Instituto Profesional
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label class="form-check-label">
                                                            <input type="radio" v-model="pregunta7.value" class="form-check-input" name="pregunta7" value="6">Carrera Profesional Universitaria
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label class="form-check-label">
                                                            <input type="radio" v-model="pregunta7.value" class="form-check-input" name="pregunta7" value="88">No sabe
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label class="form-check-label">
                                                            <input type="radio" v-model="pregunta7.value" class="form-check-input" name="pregunta7" value="99">No responde
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12" v-if="pregunta7.value">
                                <div class="card">
                                    <div class="form-group text-center">
                                        <button class="btn btn-success" @click="checkFormacionEmpleo" style="margin-top: 20px;"> Continuar</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="card" style="width: 100%;" >
                    <div class="card-header" :class="[step2 ? 'bg-success' : 'bg-danger']" >EMPLEO</div>
                    <div class="card-body" v-if="!step2">
                        <div class="col-12"  v-if="pregunta7.value">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <label>8 {{pregunta8.key}}</label>
                                            <div class="form-group">
                                                <div class="form-check">
                                                    <label class="form-check-label">
                                                        <input
                                                            type="radio"
                                                            v-model="pregunta8.value"
                                                            class="form-check-input"
                                                            name="pregunta8"
                                                            value="1">Si
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <label class="form-check-label">
                                                        <input
                                                            type="radio"
                                                            v-model="pregunta8.value"
                                                            class="form-check-input"
                                                            name="pregunta8"
                                                            value="0">No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12"  v-if="pregunta8.value=='0' ">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <label>8.1 {{pregunta8_1.key}}</label>
                                            <input v-model="pregunta8_1.value" type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12"  v-if="pregunta8_1.value">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <label>9{{pregunta9.key}}</label>
                                            <div class="form-group">
                                                <div class="form-check">
                                                    <label class="form-check-label">
                                                        <input
                                                            type="radio"
                                                            v-model="pregunta9.value"
                                                            class="form-check-input"
                                                            name="pregunta9"
                                                            value="1">Si
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <label class="form-check-label">
                                                        <input
                                                            type="radio"
                                                            v-model="pregunta9.value"
                                                            class="form-check-input"
                                                            name="pregunta9"
                                                            value="0">No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12"  v-if="pregunta9.value == '1' ">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <label>9.1 {{pregunta9_1.key}}</label>
                                            <input v-model="pregunta9_1.value" type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12"  v-if="pregunta9.value == '0' ">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <label>9.2 {{pregunta9_2.key}}</label>
                                            <input v-model="pregunta9_2.value" type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12"  v-if="pregunta8.value == '1' || pregunta9_1.value || pregunta9_2.value ">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <label>10 {{pregunta10.key}}</label>
                                            <input v-model="pregunta10.value" type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12"  v-if="pregunta10.value">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <label>11 {{pregunta11.key}}</label>
                                            <input v-model="pregunta11.value" type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12"  v-if="pregunta11.value">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <label>12 {{pregunta12.key}}</label>
                                            <input v-model="pregunta12.value" type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12"  v-if="pregunta12.value">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <label>13 {{pregunta13.key}}</label>
                                            <input v-model="pregunta13.value" type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12"  v-if="pregunta13.value">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <label>14 {{pregunta14.key}}</label>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta14.value"
                                                        class="form-check-input"
                                                        name="pregunta14"
                                                        value="1">Nunca
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta14.value"
                                                        class="form-check-input"
                                                        name="pregunta14"
                                                        value="2">Rara vez
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta14.value"
                                                        class="form-check-input"
                                                        name="pregunta14"
                                                        value="3">Casi siempre
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta14.value"
                                                        class="form-check-input"
                                                        name="pregunta14"
                                                        value="4">Siempre
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12"  v-if="pregunta14.value">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <label>15 {{pregunta15.key}}</label>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta15.value"
                                                        class="form-check-input"
                                                        name="pregunta15"
                                                        value="1">Casi nunca
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta15.value"
                                                        class="form-check-input"
                                                        name="pregunta15"
                                                        value="2">Algunos meses
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta15.value"
                                                        class="form-check-input"
                                                        name="pregunta15"
                                                        value="3">Casi todos los meses
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta15.value"
                                                        class="form-check-input"
                                                        name="pregunta15"
                                                        value="4">Todos los meses
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta15.value"
                                                        class="form-check-input"
                                                        name="pregunta15"
                                                        value="88">No sabe
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta15.value"
                                                        class="form-check-input"
                                                        name="pregunta15"
                                                        value="99">No responde
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12"  v-if="pregunta15.value">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <label>16 {{pregunta16.key}}</label>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta16.value"
                                                        class="form-check-input"
                                                        name="pregunta16"
                                                        value="1">Si
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta16.value"
                                                        class="form-check-input"
                                                        name="pregunta16"
                                                        value="0">No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12"  v-if="pregunta16.value=='1' ">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <label>16a {{pregunta16_A.key}}</label>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta16_A.value"
                                                        class="form-check-input"
                                                        name="pregunta16_A"
                                                        value="1">Indefinido
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta16_A.value"
                                                        class="form-check-input"
                                                        name="pregunta16_A"
                                                        value="2">A plazo fijo
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta16_A.value"
                                                        class="form-check-input"
                                                        name="pregunta16_A"
                                                        value="3">Contrato a honorarios
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta16_A.value"
                                                        class="form-check-input"
                                                        name="pregunta16_A"
                                                        value="4">Por obra o faena
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta16_A.value"
                                                        class="form-check-input"
                                                        name="pregunta16_A"
                                                        value="5">De aprendizaje
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12"  v-if="pregunta16.value=='0' ">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <label>16b {{pregunta16_B.key}}</label>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta16_B.value"
                                                        class="form-check-input"
                                                        name="pregunta16_B"
                                                        value="1">Trabajo independiente
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta16_B.value"
                                                        class="form-check-input"
                                                        name="pregunta16_B"
                                                        value="2">Trabajo dependiente informal
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12"  v-if="pregunta16_A.value || pregunta16_B.value">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <label>17 {{pregunta17.key}}</label>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta17.value"
                                                        class="form-check-input"
                                                        name="pregunta17"
                                                        value="1">Muy inestable
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta17.value"
                                                        class="form-check-input"
                                                        name="pregunta17"
                                                        value="2">Inestable
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta17.value"
                                                        class="form-check-input"
                                                        name="pregunta17"
                                                        value="3">Ni estable ni inestable
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta17.value"
                                                        class="form-check-input"
                                                        name="pregunta17"
                                                        value="4">Estable
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta17.value"
                                                        class="form-check-input"
                                                        name="pregunta17"
                                                        value="5">Muy estable
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta17.value"
                                                        class="form-check-input"
                                                        name="pregunta17"
                                                        value="88">No responde
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta17.value"
                                                        class="form-check-input"
                                                        name="pregunta17"
                                                        value="99">No sabe
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12"  v-if="pregunta17.value">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <label>18 {{pregunta18.key}}</label>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta18.value"
                                                        class="form-check-input"
                                                        name="pregunta18"
                                                        value="1">Si
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta18.value"
                                                        class="form-check-input"
                                                        name="pregunta18"
                                                        value="2">No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12"  v-if="pregunta18.value">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <label>19 {{pregunta19.key}}</label>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta19.value"
                                                        class="form-check-input"
                                                        name="pregunta19"
                                                        value="1">Bastante difícil
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta19.value"
                                                        class="form-check-input"
                                                        name="pregunta19"
                                                        value="2">Difícil
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta19.value"
                                                        class="form-check-input"
                                                        name="pregunta19"
                                                        value="3">Ni fácil ni difícil
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta19.value"
                                                        class="form-check-input"
                                                        name="pregunta19"
                                                        value="4">Fácil
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta19.value"
                                                        class="form-check-input"
                                                        name="pregunta19"
                                                        value="5">Bastante fácil
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta19.value"
                                                        class="form-check-input"
                                                        name="pregunta19"
                                                        value="88">No responde
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="radio"
                                                        v-model="pregunta19.value"
                                                        class="form-check-input"
                                                        name="pregunta19"
                                                        value="99">No sabe
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="card" style="width: 100%;" >
                    <div class="card-header" :class="[step1 ? 'bg-success' : 'bg-danger']" >REDES SOCIALES Y COMUNITARIAS</div>
                    <div class="card-body" >
                        <div class="col-12" >
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">

                                        <div class="col-12">
                                            <label>20 {{pregunta20.key}}</label>
                                            <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th></th>
                                                    <th>Nunca</th>
                                                    <th>Una vez al año</th>
                                                    <th>Una vez al mes</th>
                                                    <th>Una vez a la semana</th>
                                                    <th>Varios días a la semana</th>
                                                    <th>No aplica o no tiene</th>
                                                    <th>NS/NR</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td> {{ pregunta20_A.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_A.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_A"
                                                                    value="1">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_A.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_A"
                                                                    value="2">Una vez al año
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_A.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_A"
                                                                    value="3">Una vez al mes
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_A.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_A"
                                                                    value="4">Una vez a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_A.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_A"
                                                                    value="5">Varios días a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_A.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_A"
                                                                    value="77">No aplica o no tiene
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_A.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_A"
                                                                    value="88">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td> {{ pregunta20_B.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_B.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_B"
                                                                    value="1">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_B.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_B"
                                                                    value="2">Una vez al año
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_B.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_B"
                                                                    value="3">Una vez al mes
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_B.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_B"
                                                                    value="4">Una vez a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_B.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_B"
                                                                    value="5">Varios días a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_B.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_B"
                                                                    value="77">No aplica o no tiene
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_B.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_B"
                                                                    value="88">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td> {{ pregunta20_C.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_C.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_C"
                                                                    value="1">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_C.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_C"
                                                                    value="2">Una vez al año
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_C.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_C"
                                                                    value="3">Una vez al mes
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_C.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_C"
                                                                    value="4">Una vez a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_C.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_C"
                                                                    value="5">Varios días a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_C.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_C"
                                                                    value="77">No aplica o no tiene
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_C.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_C"
                                                                    value="88">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td> {{ pregunta20_D.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_D.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_D"
                                                                    value="1">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_D.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_D"
                                                                    value="2">Una vez al año
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_D.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_D"
                                                                    value="3">Una vez al mes
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_D.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_D"
                                                                    value="4">Una vez a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_D.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_D"
                                                                    value="5">Varios días a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_D.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_D"
                                                                    value="77">No aplica o no tiene
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_D.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_D"
                                                                    value="88">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td> {{ pregunta20_E.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_E.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_E"
                                                                    value="1">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_E.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_E"
                                                                    value="2">Una vez al año
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_E.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_E"
                                                                    value="3">Una vez al mes
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_E.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_E"
                                                                    value="4">Una vez a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_E.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_E"
                                                                    value="5">Varios días a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_E.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_E"
                                                                    value="77">No aplica o no tiene
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_E.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_E"
                                                                    value="88">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td> {{ pregunta20_F.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_F.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_F"
                                                                    value="1">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_F.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_F"
                                                                    value="2">Una vez al año
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_F.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_F"
                                                                    value="3">Una vez al mes
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_F.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_F"
                                                                    value="4">Una vez a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_F.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_F"
                                                                    value="5">Varios días a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_F.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_F"
                                                                    value="77">No aplica o no tiene
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_F.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_F"
                                                                    value="88">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td> {{ pregunta20_G.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_G.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_G"
                                                                    value="1">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_G.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_G"
                                                                    value="2">Una vez al año
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_G.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_G"
                                                                    value="3">Una vez al mes
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_G.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_G"
                                                                    value="4">Una vez a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_G.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_G"
                                                                    value="5">Varios días a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_G.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_G"
                                                                    value="77">No aplica o no tiene
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_G.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_G"
                                                                    value="88">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td> {{ pregunta20_H.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_H.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_H"
                                                                    value="1">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_H.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_H"
                                                                    value="2">Una vez al año
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_H.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_H"
                                                                    value="3">Una vez al mes
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_H.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_H"
                                                                    value="4">Una vez a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_H.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_H"
                                                                    value="5">Varios días a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_H.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_H"
                                                                    value="77">No aplica o no tiene
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta20_H.value"
                                                                    class="form-check-input"
                                                                    name="pregunta20_H"
                                                                    value="88">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div class="col-12">
                                            <label>21 {{pregunta21.key}}</label>
                                            <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th></th>
                                                    <th>Nunca</th>
                                                    <th>Una vez al año</th>
                                                    <th>Una vez al mes</th>
                                                    <th>Una vez a la semana</th>
                                                    <th>Varios días a la semana</th>
                                                    <th>No aplica o no tiene</th>
                                                    <th>NS/NR</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td> {{ pregunta21_A.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_A.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_A"
                                                                    value="1">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_A.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_A"
                                                                    value="2">Una vez al año
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_A.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_A"
                                                                    value="3">Una vez al mes
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_A.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_A"
                                                                    value="4">Una vez a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_A.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_A"
                                                                    value="5">Varios días a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_A.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_A"
                                                                    value="77">No aplica o no tiene
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_A.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_A"
                                                                    value="88">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td> {{ pregunta21_B.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_B.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_B"
                                                                    value="1">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_B.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_B"
                                                                    value="2">Una vez al año
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_B.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_B"
                                                                    value="3">Una vez al mes
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_B.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_B"
                                                                    value="4">Una vez a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_B.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_B"
                                                                    value="5">Varios días a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_B.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_B"
                                                                    value="77">No aplica o no tiene
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_B.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_B"
                                                                    value="88">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td> {{ pregunta21_C.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_C.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_C"
                                                                    value="1">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_C.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_C"
                                                                    value="2">Una vez al año
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_C.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_C"
                                                                    value="3">Una vez al mes
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_C.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_C"
                                                                    value="4">Una vez a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_C.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_C"
                                                                    value="5">Varios días a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_C.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_C"
                                                                    value="77">No aplica o no tiene
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_C.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_C"
                                                                    value="88">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td> {{ pregunta21_D.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_D.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_D"
                                                                    value="1">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_D.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_D"
                                                                    value="2">Una vez al año
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_D.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_D"
                                                                    value="3">Una vez al mes
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_D.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_D"
                                                                    value="4">Una vez a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_D.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_D"
                                                                    value="5">Varios días a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_D.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_D"
                                                                    value="77">No aplica o no tiene
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_D.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_D"
                                                                    value="88">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td> {{ pregunta21_E.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_E.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_E"
                                                                    value="1">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_E.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_E"
                                                                    value="2">Una vez al año
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_E.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_E"
                                                                    value="3">Una vez al mes
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_E.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_E"
                                                                    value="4">Una vez a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_E.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_E"
                                                                    value="5">Varios días a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_E.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_E"
                                                                    value="77">No aplica o no tiene
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_E.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_E"
                                                                    value="88">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td> {{ pregunta21_F.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_F.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_F"
                                                                    value="1">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_F.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_F"
                                                                    value="2">Una vez al año
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_F.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_F"
                                                                    value="3">Una vez al mes
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_F.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_F"
                                                                    value="4">Una vez a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_F.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_F"
                                                                    value="5">Varios días a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_F.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_F"
                                                                    value="77">No aplica o no tiene
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_F.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_F"
                                                                    value="88">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td> {{ pregunta21_G.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_G.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_G"
                                                                    value="1">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_G.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_G"
                                                                    value="2">Una vez al año
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_G.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_G"
                                                                    value="3">Una vez al mes
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_G.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_G"
                                                                    value="4">Una vez a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_G.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_G"
                                                                    value="5">Varios días a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_G.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_G"
                                                                    value="77">No aplica o no tiene
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_G.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_G"
                                                                    value="88">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td> {{ pregunta21_H.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_H.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_H"
                                                                    value="1">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_H.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_H"
                                                                    value="2">Una vez al año
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_H.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_H"
                                                                    value="3">Una vez al mes
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_H.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_H"
                                                                    value="4">Una vez a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_H.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_H"
                                                                    value="5">Varios días a la semana
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_H.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_H"
                                                                    value="77">No aplica o no tiene
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta21_H.value"
                                                                    class="form-check-input"
                                                                    name="pregunta21_H"
                                                                    value="88">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  
                                                </tbody>
                                            </table>
                                        </div>

                                        <div class="col-12" >
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <label>22 {{pregunta22.key}}</label>
                                                            <div class="form-check">
                                                                <label class="form-check-label">
                                                                    <input
                                                                        type="radio"
                                                                        v-model="pregunta22.value"
                                                                        class="form-check-input"
                                                                        name="pregunta22"
                                                                        value="1">Si
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <label class="form-check-label">
                                                                    <input
                                                                        type="radio"
                                                                        v-model="pregunta22.value"
                                                                        class="form-check-input"
                                                                        name="pregunta22"
                                                                        value="2">No
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12"  v-if="pregunta22.value=='1' ">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <label>22.1 {{pregunta22_1.key}}</label>
                                                            <input v-model="pregunta22_1.value" type="text" class="form-control">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12"  v-if="pregunta22.value=='2' || pregunta22_1.value"  >
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <label>23 {{pregunta23.key}}</label>
                                                            <div class="form-check">
                                                                <label class="form-check-label">
                                                                    <input
                                                                        type="radio"
                                                                        v-model="pregunta23.value"
                                                                        class="form-check-input"
                                                                        name="pregunta23"
                                                                        value="1">Si
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <label class="form-check-label">
                                                                    <input
                                                                        type="radio"
                                                                        v-model="pregunta23.value"
                                                                        class="form-check-input"
                                                                        name="pregunta23"
                                                                        value="0">No
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12"  v-if="pregunta23.value=='1' "  >
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <label>23 {{pregunta23_1.key}}</label>
                                                            <input v-model="pregunta23_1.value" type="text" class="form-control">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12">
                                            <label>24 {{pregunta24.key}}</label>
                                            <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th></th>
                                                    <th>Si</th>
                                                    <th>No</th>
                                                    <th>No sabe</th>
                                                    <th>No responde</th>
                                                 
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td> {{ pregunta24_A.key }}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta24_A.value"
                                                                    class="form-check-input"
                                                                    name="pregunta24_A"
                                                                    value="1">Si
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta24_A.value"
                                                                    class="form-check-input"
                                                                    name="pregunta24_A"
                                                                    value="0">No
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta24_A.value"
                                                                    class="form-check-input"
                                                                    name="pregunta24_A"
                                                                    value="88">No sabe
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta24_A.value"
                                                                    class="form-check-input"
                                                                    name="pregunta24_A"
                                                                    value="99">No responde
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td> {{ pregunta24_B.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta24_B.value"
                                                                    class="form-check-input"
                                                                    name="pregunta24_B"
                                                                    value="1">Si
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta24_B.value"
                                                                    class="form-check-input"
                                                                    name="pregunta24_B"
                                                                    value="0">No
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta24_B.value"
                                                                    class="form-check-input"
                                                                    name="pregunta24_B"
                                                                    value="88">No sabe
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta24_B.value"
                                                                    class="form-check-input"
                                                                    name="pregunta24_B"
                                                                    value="99">No responde
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="col-12" >
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <label>25 {{ pregunta25.key }}</label>
                                                            <div class="form-check">
                                                                <label class="form-check-label">
                                                                    <input
                                                                        type="radio"
                                                                        v-model="pregunta25.value"
                                                                        class="form-check-input"
                                                                        name="pregunta25"
                                                                        value="0">No
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <label class="form-check-label">
                                                                    <input
                                                                        type="radio"
                                                                        v-model="pregunta25.value"
                                                                        class="form-check-input"
                                                                        name="pregunta25"
                                                                        value="1">Si
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <label class="form-check-label">
                                                                    <input
                                                                        type="radio"
                                                                        v-model="pregunta25.value"
                                                                        class="form-check-input"
                                                                        name="pregunta25"
                                                                        value="2">No ha requerido
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <label class="form-check-label">
                                                                    <input
                                                                        type="radio"
                                                                        v-model="pregunta25.value"
                                                                        class="form-check-input"
                                                                        name="pregunta25"
                                                                        value="88">No sabe
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <label class="form-check-label">
                                                                    <input
                                                                        type="radio"
                                                                        v-model="pregunta25.value"
                                                                        class="form-check-input"
                                                                        name="pregunta25"
                                                                        value="99">No responde
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="card" style="width: 100%;" >
                    <div class="card-header" :class="[step1 ? 'bg-success' : 'bg-danger']" >SALUD</div>
                    <div class="card-body" >
                        <div class="col-12" >
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">

                                        <div class="col-12" >
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <label>26 {{pregunta26.key}}</label>
                                                            <div class="form-group">
                                                                <div class="form-check">
                                                                    <label class="form-check-label">
                                                                        <input
                                                                            type="radio"
                                                                            v-model="pregunta26.value"
                                                                            class="form-check-input"
                                                                            name="pregunta26"
                                                                            value="5">Excelente
                                                                    </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <label class="form-check-label">
                                                                        <input
                                                                            type="radio"
                                                                            v-model="pregunta26.value"
                                                                            class="form-check-input"
                                                                            name="pregunta26"
                                                                            value="4">Muy buena
                                                                    </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <label class="form-check-label">
                                                                        <input
                                                                            type="radio"
                                                                            v-model="pregunta26.value"
                                                                            class="form-check-input"
                                                                            name="pregunta26"
                                                                            value="3">Buena
                                                                    </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <label class="form-check-label">
                                                                        <input
                                                                            type="radio"
                                                                            v-model="pregunta26.value"
                                                                            class="form-check-input"
                                                                            name="pregunta26"
                                                                            value="2">Adecuada
                                                                    </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <label class="form-check-label">
                                                                        <input
                                                                            type="radio"
                                                                            v-model="pregunta26.value"
                                                                            class="form-check-input"
                                                                            name="pregunta26"
                                                                            value="1">Mala
                                                                    </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <label class="form-check-label">
                                                                        <input
                                                                            type="radio"
                                                                            v-model="pregunta26.value"
                                                                            class="form-check-input"
                                                                            name="pregunta26"
                                                                            value="88">No responde
                                                                    </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <label class="form-check-label">
                                                                        <input
                                                                            type="radio"
                                                                            v-model="pregunta26.value"
                                                                            class="form-check-input"
                                                                            name="pregunta26"
                                                                            value="99">No sabe
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12"  v-if="pregunta26.value">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <label>27 {{pregunta27.key}}</label>
                                                            <div class="form-group">
                                                                <div class="form-check">
                                                                    <label class="form-check-label">
                                                                        <input
                                                                            type="radio"
                                                                            v-model="pregunta27.value"
                                                                            class="form-check-input"
                                                                            name="pregunta27"
                                                                            value="0">No 
                                                                    </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <label class="form-check-label">
                                                                        <input
                                                                            type="radio"
                                                                            v-model="pregunta27.value"
                                                                            class="form-check-input"
                                                                            name="pregunta27"
                                                                            value="1">Si, en el último mes
                                                                    </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <label class="form-check-label">
                                                                        <input
                                                                            type="radio"
                                                                            v-model="pregunta27.value"
                                                                            class="form-check-input"
                                                                            name="pregunta27"
                                                                            value="2">Si, en el último año
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12"  v-if="pregunta27.value=='0'">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <label>27.1 {{pregunta27_1.key}}</label>
                                                            <div class="form-group">
                                                                <div class="form-check">
                                                                    <label class="form-check-label">
                                                                        <input
                                                                            type="radio"
                                                                            v-model="pregunta27_1.value"
                                                                            class="form-check-input"
                                                                            name="pregunta27_1"
                                                                            value="1">No necesito
                                                                    </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <label class="form-check-label">
                                                                        <input
                                                                            type="radio"
                                                                            v-model="pregunta27_1.value"
                                                                            class="form-check-input"
                                                                            name="pregunta27_1"
                                                                            value="2">Problemas para acceder al tratamiento
                                                                    </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <label class="form-check-label">
                                                                        <input
                                                                            type="radio"
                                                                            v-model="pregunta27_1.value"
                                                                            class="form-check-input"
                                                                            name="pregunta27_1"
                                                                            value="3">Otro motivo (especificar)
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12"  v-if="pregunta27_1.value=='3'">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <label>27.1A {{pregunta27_1A.key}}</label>
                                                            <textarea class="form-control" v-model="pregunta27_1A.value" placeholder="Otro motivo..."></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        
                                        <div class="col-12"  >
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <label>28 {{pregunta28.key}}</label>
                                                            <div class="form-group">
                                                                <div class="form-check">
                                                                    <label class="form-check-label">
                                                                        <input
                                                                            type="radio"
                                                                            v-model="pregunta28.value"
                                                                            class="form-check-input"
                                                                            name="pregunta28"
                                                                            value="0">No 
                                                                    </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <label class="form-check-label">
                                                                        <input
                                                                            type="radio"
                                                                            v-model="pregunta28.value"
                                                                            class="form-check-input"
                                                                            name="pregunta28"
                                                                            value="1">Si, en el último mes
                                                                    </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <label class="form-check-label">
                                                                        <input
                                                                            type="radio"
                                                                            v-model="pregunta28.value"
                                                                            class="form-check-input"
                                                                            name="pregunta28"
                                                                            value="2">Si, en el último año
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12"  v-if="pregunta28.value=='0'">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <label>28.1 {{pregunta28_1.key}}</label>
                                                            <div class="form-group">
                                                                <div class="form-check">
                                                                    <label class="form-check-label">
                                                                        <input
                                                                            type="radio"
                                                                            v-model="pregunta28_1.value"
                                                                            class="form-check-input"
                                                                            name="pregunta28_1"
                                                                            value="1">No necesito
                                                                    </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <label class="form-check-label">
                                                                        <input
                                                                            type="radio"
                                                                            v-model="pregunta28_1.value"
                                                                            class="form-check-input"
                                                                            name="pregunta28_1"
                                                                            value="2">Problemas para acceder al tratamiento
                                                                    </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <label class="form-check-label">
                                                                        <input
                                                                            type="radio"
                                                                            v-model="pregunta28_1.value"
                                                                            class="form-check-input"
                                                                            name="pregunta28_1"
                                                                            value="3">Otro motivo (especificar)
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12"  v-if="pregunta28_1.value=='3'">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <label>28.1A {{pregunta28_1A.key}}</label>
                                                            <textarea class="form-control" v-model="pregunta28_1A.value" placeholder="Otro motivo..."></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12"  v-if="pregunta28.value=='1'|| pregunta28.value=='2' ">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <label>28.1 {{pregunta28_1.key}}</label>
                                                            <div class="form-group">
                                                                <div class="form-check">
                                                                    <label class="form-check-label">
                                                                        <input
                                                                            type="radio"
                                                                            v-model="pregunta28_1.value"
                                                                            class="form-check-input"
                                                                            name="pregunta28_1"
                                                                            value="1">No necesito
                                                                    </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <label class="form-check-label">
                                                                        <input
                                                                            type="radio"
                                                                            v-model="pregunta28_1.value"
                                                                            class="form-check-input"
                                                                            name="pregunta28_1"
                                                                            value="2">Problemas para acceder al tratamiento
                                                                    </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <label class="form-check-label">
                                                                        <input
                                                                            type="radio"
                                                                            v-model="pregunta28_1.value"
                                                                            class="form-check-input"
                                                                            name="pregunta28_1"
                                                                            value="3">Otro motivo (especificar)
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12"  v-if="pregunta28.value=='1'|| pregunta28.value=='2' ">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <label>29{{pregunta29.key}}</label>
                                                            <div class="form-group">
                                                                <div class="form-check">
                                                                    <label class="form-check-label">
                                                                        <input
                                                                            type="radio"
                                                                            v-model="pregunta29.value"
                                                                            class="form-check-input"
                                                                            name="pregunta29"
                                                                            value="0">No
                                                                    </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <label class="form-check-label">
                                                                        <input
                                                                            type="radio"
                                                                            v-model="pregunta29.value"
                                                                            class="form-check-input"
                                                                            name="pregunta29"
                                                                            value="1">Si
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12"  v-if="pregunta28.value=='1'|| pregunta28.value=='2' ">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <label>30 {{pregunta30.key}}</label>
                                                            <div class="form-group">
                                                                <div class="form-check">
                                                                    <label class="form-check-label">
                                                                        <input
                                                                            type="radio"
                                                                            v-model="pregunta30.value"
                                                                            class="form-check-input"
                                                                            name="pregunta30"
                                                                            value="4">Muy satisfecho
                                                                    </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <label class="form-check-label">
                                                                        <input
                                                                            type="radio"
                                                                            v-model="pregunta30.value"
                                                                            class="form-check-input"
                                                                            name="pregunta30"
                                                                            value="3">Satisfecho
                                                                    </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <label class="form-check-label">
                                                                        <input
                                                                            type="radio"
                                                                            v-model="pregunta30.value"
                                                                            class="form-check-input"
                                                                            name="pregunta30"
                                                                            value="2">Algo satisfecho
                                                                    </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <label class="form-check-label">
                                                                        <input
                                                                            type="radio"
                                                                            v-model="pregunta30.value"
                                                                            class="form-check-input"
                                                                            name="pregunta30"
                                                                            value="1">Nada de satisfecho
                                                                    </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <label class="form-check-label">
                                                                        <input
                                                                            type="radio"
                                                                            v-model="pregunta30.value"
                                                                            class="form-check-input"
                                                                            name="pregunta30"
                                                                            value="88">No sabe
                                                                    </label>
                                                                </div>
                                                                <div class="form-check">
                                                                    <label class="form-check-label">
                                                                        <input
                                                                            type="radio"
                                                                            v-model="pregunta30.value"
                                                                            class="form-check-input"
                                                                            name="pregunta30"
                                                                            value="99">No responde
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12">
                                            <label>31 {{pregunta31.key}}</label>
                                            <table class="table">
                                                <thead>
                                                  <tr>
                                                    <th></th>
                                                    <th>Nunca</th>
                                                    <th>Aveces</th>
                                                    <th>Siempre</th>
                                                    <th>NS/NR</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td> {{ pregunta31_A.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_A.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_A"
                                                                    value="0">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_A.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_A"
                                                                    value="1">A veces
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_A.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_A"
                                                                    value="2">Siempre
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_A.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_A"
                                                                    value="99">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td> {{ pregunta31_B.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_B.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_B"
                                                                    value="2">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_B.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_B"
                                                                    value="1">A veces
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_B.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_B"
                                                                    value="0">Siempre
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_B.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_B"
                                                                    value="99">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td> {{ pregunta31_C.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_C.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_C"
                                                                    value="2">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_C.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_C"
                                                                    value="1">A veces
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_C.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_C"
                                                                    value="0">Siempre
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_C.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_C"
                                                                    value="99">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td> {{ pregunta31_D.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_D.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_D"
                                                                    value="2">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_D.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_D"
                                                                    value="1">A veces
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_D.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_D"
                                                                    value="0">Siempre
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_D.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_D"
                                                                    value="99">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td> {{ pregunta31_E.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_E.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_E"
                                                                    value="0">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_E.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_E"
                                                                    value="1">A veces
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_E.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_E"
                                                                    value="2">Siempre
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_E.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_E"
                                                                    value="99">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td> {{ pregunta31_F.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_F.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_F"
                                                                    value="0">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_F.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_F"
                                                                    value="1">A veces
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_F.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_F"
                                                                    value="2">Siempre
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_F.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_F"
                                                                    value="99">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td> {{ pregunta31_G.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_G.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_G"
                                                                    value="0">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_G.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_G"
                                                                    value="1">A veces
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_G.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_G"
                                                                    value="2">Siempre
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_G.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_G"
                                                                    value="99">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td> {{ pregunta31_H.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_H.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_H"
                                                                    value="0">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_H.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_H"
                                                                    value="1">A veces
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_H.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_H"
                                                                    value="2">Siempre
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_H.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_H"
                                                                    value="99">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td> {{ pregunta31_I.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_I.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_I"
                                                                    value="2">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_I.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_I"
                                                                    value="1">A veces
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_I.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_I"
                                                                    value="0">Siempre
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_I.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_I"
                                                                    value="99">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td> {{ pregunta31_J.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_J.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_J"
                                                                    value="0">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_J.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_J"
                                                                    value="1">A veces
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_J.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_J"
                                                                    value="2">Siempre
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_J.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_J"
                                                                    value="99">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td> {{ pregunta31_K.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_K.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_K"
                                                                    value="0">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_K.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_K"
                                                                    value="1">A veces
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_K.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_K"
                                                                    value="2">Siempre
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_K.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_K"
                                                                    value="99">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td> {{ pregunta31_L.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_L.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_L"
                                                                    value="0">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_L.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_L"
                                                                    value="1">A veces
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_L.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_L"
                                                                    value="2">Siempre
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_L.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_L"
                                                                    value="99">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td> {{ pregunta31_M.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_M.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_M"
                                                                    value="2">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_M.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_M"
                                                                    value="1">A veces
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_M.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_M"
                                                                    value="0">Siempre
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_M.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_M"
                                                                    value="99">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td> {{ pregunta31_N.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_N.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_N"
                                                                    value="2">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_N.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_N"
                                                                    value="1">A veces
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_N.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_N"
                                                                    value="0">Siempre
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_N.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_N"
                                                                    value="99">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td> {{ pregunta31_O.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_O.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_O"
                                                                    value="0">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_O.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_O"
                                                                    value="1">A veces
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_O.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_O"
                                                                    value="2">Siempre
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_O.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_O"
                                                                    value="99">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td> {{ pregunta31_P.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_P.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_P"
                                                                    value="2">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_P.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_P"
                                                                    value="1">A veces
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_P.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_P"
                                                                    value="0">Siempre
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_P.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_P"
                                                                    value="99">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td> {{ pregunta31_Q.key}}</td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_Q.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_Q"
                                                                    value="2">Nunca
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_Q.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_Q"
                                                                    value="1">A veces
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_Q.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_Q"
                                                                    value="0">Siempre
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-check">
                                                            <label class="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    v-model="pregunta31_Q.value"
                                                                    class="form-check-input"
                                                                    name="pregunta31_Q"
                                                                    value="99">NS/NR
                                                            </label>
                                                        </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card" style="width: 100%;" >
                    <div class="card-header" :class="[step1 ? 'bg-success' : 'bg-danger']" >BIENESTAR SUBJETIVO</div>
                    <div class="card-body" >
                        <div class="col-12" >
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card" style="width: 100%;" >
                    <div class="card-header" :class="[step1 ? 'bg-success' : 'bg-danger']" >HABILIDADES SOCIOEMOCIONALES</div>
                    <div class="card-body" >
                        <div class="col-12" >
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                

            </div>
</template>
<script>

export default {
  computed: {},
  data(){
       return {
    respuestas: false,
                    step1: false,
                    step2: false,
                    pregunta1: {
                        id: 'pregunta1',
                        key: "¿Cual es el nivel educacional mas alto alcanzo o tu nivel educacional actual?",
                        value: "",
                        answer: "",
                        visible: false
                    },
                    pregunta2: {
                        answer: "",
                        id: 'pregunta2',
                        key: "¿Cual fue el ultimo curso aprobado?",
                        value: ""
                    },
                    pregunta3: {
                        answer: "",
                        id: 'pregunta3',
                        key: "Actualmente¿Te encuentras matriculado en algun establecimiento educacional?",
                        value: ""
                    },
                    pregunta3_1: {
                        answer: "",
                        id: 'pregunta3_1',
                        key: "¿Que tipo de establecimiento?",
                        value: ""
                    },
                    pregunta3_1A: {
                        answer: "",
                        id: 'pregunta3_1A',
                        key: "¿Cual?",
                        value: ""
                    },
                    pregunta4: {
                        answer: "",
                        id: 'pregunta4',
                        key: "La semana pasada ¿Cuantos dias fuiste al colegio/instituo o centro de formacion tecnica?",
                        value: ""
                    },
                    pregunta5: {
                        answer: "",
                        id: 'pregunta5',
                        key: "¿Tienes interés en continuar o retomar tus estudios?",
                        value: ""
                    },
                    pregunta5_1: {
                        answer: "",
                        id: 'pregunta5_1',
                        key: "¿Por qué?",
                        value: ""
                    },
                    pregunta6: {
                        answer: "",
                        id: 'pregunta6',
                        key: "¿Cuál crees que es el nivel educativo más alto que podrías completar?",
                        value: ""
                    },
                    pregunta7: {
                        answer: "",
                        id: 'pregunta7',
                        key: "¿A qué nivel educativo te gustaría llegar?",
                        value: ""
                    },
                    pregunta8: {
                        answer: "",
                        id: 'pregunta8',
                        key: "En las últimas 4 semanas ¿trabajaste o realizaste alguna actividad por lo menos una hora, por la que hayas recibido un ingreso o retribución?",
                        value: ""
                    },
                    pregunta8_1: {
                        answer: "",
                        id: 'pregunta8_1',
                        key: " ¿Desde hace cuántos meses estás sin trabajo?",
                        value: ""
                    },
                    pregunta9: {
                        answer: "",
                        id: 'pregunta9',
                        key: " Durante el último mes ¿has hecho algo para conseguir trabajo o para crear crear tu propio negocio?",
                        value: ""
                    },
                    pregunta9_1: {
                        answer: "",
                        id: 'pregunta9_1',
                        key: " ¿Cuánto meses llevas buscando trabajo o preparando tu negocio?",
                        value: ""
                    },
                    pregunta9_2: {
                        answer: "",
                        id: 'pregunta9_2',
                        key: " ¿Por qué? Especificar principal motivo (estudios, labores de cuidado, otros, etc)",
                        value: ""
                    },
                    pregunta10: {
                        answer: "",
                        id: 'pregunta10',
                        key: " ¿En qué trabajas?",
                        value: ""
                    },
                    pregunta11: {
                        answer: "",
                        id: 'pregunta11',
                        key: " ¿Cuánto meses llevas en tu trabajo actual?",
                        value: ""
                    },
                    pregunta12: {
                        answer: "",
                        id: 'pregunta12',
                        key: " ¿Cuántas horas aproximadas trabajaste la semana pasada?",
                        value: ""
                    },
                    pregunta13: {
                        answer: "",
                        id: 'pregunta13',
                        key: " ¿Cuánto ingreso recibes, en promedio, en un mes de trabajo?",
                        value: ""
                    },
                    pregunta14: {
                        answer: "",
                        id: 'pregunta14',
                        key: " ¿Tus ingresos te permiten cubrir tus necesidades básicas y gastos regulares?",
                        value: ""
                    },
                    pregunta15: {
                        answer: "",
                        id: 'pregunta15',
                        key: " ¿Cuentas con capacidad de ahorro?",
                        value: ""
                    },
                    pregunta16: {
                        answer: "",
                        id: 'pregunta16',
                        key: " Actualmente ¿tu relación de trabajo está formalizada en algún tipo de contrato?",
                        value: ""
                    },
                    pregunta16_A: {
                        answer: "",
                        id: 'pregunta16_A',
                        key: " ¿Bajo qué tipo de contrato?",
                        value: ""
                    },
                    pregunta16_B: {
                        answer: "",
                        id: 'pregunta16_B',
                        key: " Tu régimen de trabajo actual es de tipo...",
                        value: ""
                    },
                    pregunta17: {
                        answer: "",
                        id: 'pregunta17',
                        key: " En términos de tu continuidad en el lugar de trabajo ¿qué tan estable o inestable dirías que es el lugar donde trabajas actualmente?",
                        value: ""
                    },
                    pregunta18: {
                        answer: "",
                        id: 'pregunta18',
                        key: " ¿Te encuentras inscrito en la OMIL u otra organización de apoyo laboral? [Para TODOS]",
                        value: ""
                    },
                    pregunta19: {
                        answer: "",
                        id: 'pregunta19',
                        key: " En las condiciones actuales ¿qué tan difícil creees que te sería encontrar un mejor lugar de trabajo (contrato formal, estable, mayores ingresos, etc) u otra actividad para generar ingresos de manera independiente?",
                        value: ""
                    },     
                    pregunta20: {
                        answer: "",
                        id: 'pregunta20',
                        key: " ¿Qué tan seguido ves o te relacionas (llamados, mensajes, etc) con las siguientes personas? Madre, Padre, Pareja o pololo/a, Hermanos, Abuelos, Tíos, Amigos/as.",
                        value: ""
                    },
                    pregunta20_A: {
                        answer: "",
                        id: 'pregunta20_A',
                        key: " Madre",
                        value: ""
                    },
                    pregunta20_B: {
                        answer: "",
                        id: 'pregunta20_B',
                        key: " Padre",
                        value: ""
                    },
                    pregunta20_C: {
                        answer: "",
                        id: 'pregunta20_C',
                        key: " Pareja o pololo/a",
                        value: ""
                    },
                    pregunta20_D: {
                        answer: "",
                        id: 'pregunta20_D',
                        key: " Hermanos",
                        value: ""
                    },
                    pregunta20_E: {
                        answer: "",
                        id: 'pregunta20_E',
                        key: " Abuelos",
                        value: ""
                    },
                    pregunta20_F: {
                        answer: "",
                        id: 'pregunta20_F',
                        key: " Tíos",
                        value: ""
                    },
                    pregunta20_G: {
                        answer: "",
                        id: 'pregunta20_G',
                        key: " Amigos",
                        value: ""
                    },
                    pregunta20_H: {
                        answer: "",
                        id: 'pregunta20_H',
                        key: " ¿Qué tan seguido ves o te relacionas (llamados, mensajes, etc) con [OTRO]?",
                        value: ""
                    },
                    pregunta21: {
                        answer: "",
                        id: 'pregunta21',
                        key: " ¿Cómo dirías que es tu relación con quienes te ves o relacionas al menos una vez al año? [Sólo con quienes señale relacionarse en la pregunta anterior]",
                        value: ""
                    },
                    pregunta21_A: {
                        answer: "",
                        id: 'pregunta21_A',
                        key: " Madre",
                        value: ""
                    },
                    pregunta21_B: {
                        answer: "",
                        id: 'pregunta21_B',
                        key: " Padre",
                        value: ""
                    },
                    pregunta21_C: {
                        answer: "",
                        id: 'pregunta21_C',
                        key: " Pareja o pololo/a",
                        value: ""
                    },
                    pregunta21_D: {
                        answer: "",
                        id: 'pregunta21_D',
                        key: " Hermanos",
                        value: ""
                    },
                    pregunta21_E: {
                        answer: "",
                        id: 'pregunta21_E',
                        key: " Abuelos",
                        value: ""
                    },
                    pregunta21_F: {
                        answer: "",
                        id: 'pregunta21_F',
                        key: " Tíos",
                        value: ""
                    },
                    pregunta21_G: {
                        answer: "",
                        id: 'pregunta21_G',
                        key: " Amigos",
                        value: ""
                    },
                    pregunta21_H: {
                        answer: "",
                        id: 'pregunta21_H',
                        key: " ¿Cómo dirías que es tu relación con [OTRO]?",
                        value: ""
                    },
                    pregunta22: {
                        answer: "",
                        id: 'pregunta22',
                        key: " Fuera de las personas mencionadas ¿cuentas con otras persona en quien confíes para buscar ayuda, apoyo, consejo o consuelo?",
                        value: ""
                    },
                    pregunta22_1: {
                        answer: "",
                        id: 'pregunta22_1',
                        key: " ¿Quién?",
                        value: ""
                    },
                    pregunta23: {
                        answer: "",
                        id: 'pregunta23',
                        key: " Durante los últimos 3 meses ¿participaste de organizaciones sociales, comunitarias o de actividades culturales, recreativas o espirituales? (ejemplo, JJVV, iglesia, club deportivo, etc)",
                        value: ""
                    },
                    pregunta23_1: {
                        answer: "",
                        id: 'pregunta23_1',
                        key: "   ¿Cuál(es)?",
                        value: ""
                    },
                    pregunta24: {
                        answer: "",
                        id: 'pregunta24',
                        key: "   ¿Tienes regularizados los siguientes trámites civiles?",
                        value: ""
                    },
                    pregunta24_A: {
                        answer: "",
                        id: 'pregunta24_A',
                        key: "   Cédula de identidad",
                        value: ""
                    },
                    pregunta24_B: {
                        answer: "",
                        id: 'pregunta24_B',
                        key: "   Registro Social de Hogares actualizado",
                        value: ""
                    },
                    pregunta25: {
                        answer: "",
                        id: 'pregunta25',
                        key: "   ¿Cuentas con acceso a representación legal en causas judiciales?",
                        value: ""
                    },
                    pregunta26: {
                        answer: "",
                        id: 'pregunta26',
                        key: "   En general, ¿cómo dirías que es tu salud?",
                        value: ""
                    },
                    pregunta27: {
                        answer: "",
                        id: 'pregunta27',
                        key: "   ¿Has recibido tratamiento psiquiátrico en los últimos 12 meses?",
                        value: ""
                    },
                    pregunta27_1: {
                        answer: "",
                        id: 'pregunta27_1',
                        key: "   ¿Por què?",
                        value: ""
                    },
                    pregunta27_1A: {
                        answer: "",
                        id: 'pregunta27_1A',
                        key: "   Otro motivo (especificar)",
                        value: ""
                    },
                    pregunta28: {
                        answer: "",
                        id: 'pregunta28',
                        key: "   ¿Has recibido tratamiento psicológico en los últimos 12 meses?",
                        value: ""
                    },
                    pregunta28_1: {
                        answer: "",
                        id: 'pregunta28_1',
                        key: "  ¿Por qué?",
                        value: ""
                    },
                    pregunta28_1A: {
                        answer: "",
                        id: 'pregunta28_1A',
                        key: "   Otro motivo (especificar)",
                        value: ""
                    },
                    pregunta29: {
                        answer: "",
                        id: 'pregunta29',
                        key: "   ¿Pretendes continuar con tu tratamiento psicológico? [Sólo quienes han recibido tratamiento psicológico en los últimos 12 meses]",
                        value: ""
                    },
                    pregunta30: {
                        answer: "",
                        id: 'pregunta30',
                        key: "   ¿Cuán satisfecho estás con el tratamiento que estás recibes actualmente? [Sólo quienes han recibido tratamiento psicológico en los últimos 12 meses]",
                        value: ""
                    },
                    pregunta31: {
                        answer: "",
                        id: 'pregunta30',
                        key: "   Por favor contesta tan honestamente como puedas; las frases se refieren a como tú te has sentido la semana pasada. Recuerda que no hay respuestas correctas, es importante que cuentes cómo te has sentido.",
                        value: ""
                    },
                    pregunta31_A: {
                        answer: "",
                        id: 'pregunta31_A',
                        key: "   Pienso que los días que vienen serán agradables",
                        value: ""
                    },
                    pregunta31_B: {
                        answer: "",
                        id: 'pregunta31_B',
                        key: "   Siento ganas de llorar",
                        value: ""
                    },
                    pregunta31_C: {
                        answer: "",
                        id: 'pregunta31_C',
                        key: "   Tengo ganas de arrancar o escapar",
                        value: ""
                    },
                    pregunta31_D: {
                        answer: "",
                        id: 'pregunta31_D',
                        key: "   Tengo dolores de estómago",
                        value: ""
                    },
                    pregunta31_E: {
                        answer: "",
                        id: 'pregunta31_E',
                        key: "   Duermo bien",
                        value: ""
                    },
                    pregunta31_F: {
                        answer: "",
                        id: 'pregunta31_F',
                        key: "   Tengo mucha energía",
                        value: ""
                    },
                    pregunta31_G: {
                        answer: "",
                        id: 'pregunta31_G',
                        key: "   Disfruto mis comidas",
                        value: ""
                    },
                    pregunta31_H: {
                        answer: "",
                        id: 'pregunta31_H',
                        key: "   Puedo arreglármelas solo, valerme por mí mismo",
                        value: ""
                    },
                    pregunta31_I: {
                        answer: "",
                        id: 'pregunta31_I',
                        key: "   Creo que no vale la pena vivir",
                        value: ""
                    },
                    pregunta31_J: {
                        answer: "",
                        id: 'pregunta31_J',
                        key: "   Me resultan bien las cosas que hago",
                        value: ""
                    },
                    pregunta31_K: {
                        answer: "",
                        id: 'pregunta31_K',
                        key: "   Disfruto lo que hago",
                        value: ""
                    },
                    pregunta31_L: {
                        answer: "",
                        id: 'pregunta31_L',
                        key: "   Me gusta hablar con mi familia",
                        value: ""
                    },
                    pregunta31_M: {
                        answer: "",
                        id: 'pregunta31_M',
                        key: "   Tengo sueños malos",
                        value: ""
                    },
                    pregunta31_N: {
                        answer: "",
                        id: 'pregunta31_N',
                        key: "   Me siento muy solo",
                        value: ""
                    },
                    pregunta31_O: {
                        answer: "",
                        id: 'pregunta31_O',
                        key: "   Me animo fácilmente o me entusiasmo con mucha facilidad",
                        value: ""
                    },
                    pregunta31_P: {
                        answer: "",
                        id: 'pregunta31_P',
                        key: "  Me siento tan triste que difícilmente lo soporto",
                        value: ""
                    },
                    pregunta31_Q: {
                        answer: "",
                        id: 'pregunta31_Q',
                        key: "  Me siento muy aburrido, lateado o choreado",
                        value: ""
                    },
                   
      }
     
  },
  methods:{
        showQuestion(pregunta){
                        pregunta.visible = !pregunta.visible;
                        console.log("LA DATA ACTUALIZADA ES: ",this.$data );
                    },
                    getRespuesta(pregunta, respuesta){
                        console.log("data : ", this.$data);
                        this.$data[pregunta];
                        this.$data[pregunta.id].answer = respuesta;
                        console.log("la respuesta 1 es: ", this.$data[pregunta.id]);
                    },
                    checkFormacionEmpleo(){
                        if( this.pregunta7.value ){
                            this.step1 = true;
                            this.step2 = false;
                        }
                    },
                    scrollBottom(){
                        console.log("la data sistema es: ", this.$data);
                        console.log("bajnado....");
                        window.scrollTo(0, 1200);
                    }
  }
}
</script>