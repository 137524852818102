<template>
    <div class="row">
        <div v-if="showModal">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title text-info">
                                        Crear Caso
                                    </h4>
                                    <button
                                        type="button"
                                        class="close"
                                        @click="showModal = false"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="form-group">
                                        <label>Usuarios:</label>
                                        <v-select v-model="users" multiple label="name" :options="usuarios"></v-select>
                                    </div>
                                    <div class="form-group">
                                        <label>Ingrese casos:</label>
                                        <div class="form-check">
                                            <label class="form-check-label">
                                                <input type="checkbox" class="form-check-input" value="relato" v-model="showRelato">Relato
                                            </label>
                                        </div>
                                     
                                        <div class="form-check">
                                            <label class="form-check-label">
                                                <input type="checkbox" class="form-check-input" value="hecho" v-model="showHecho">Hechos
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <label class="form-check-label">
                                                <input type="checkbox" class="form-check-input"  value="necesidad" v-model="showNecesidad">Necesidades
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <label class="form-check-label">
                                                <input type="checkbox" class="form-check-input" value="emocion" v-model="showEmocion">Emociones
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <label class="form-check-label">
                                                <input type="checkbox" class="form-check-input" value="posicion" v-model="showPosicion">Posiciones
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <label class="form-check-label">
                                                <input type="checkbox" class="form-check-input" value="interes" v-model="showInteres">Intereses
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <label class="form-check-label">
                                                <input type="checkbox" class="form-check-input" value="actores" v-model="showActores">Actores
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <label class="form-check-label">
                                                <input type="checkbox" class="form-check-input" value="conflicto" v-model="showConflicto">Conflicto
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <label class="form-check-label">
                                                <input type="checkbox" class="form-check-input" value="keyword" v-model="showKeyword">Palabras clave
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <label class="form-check-label">
                                                <input type="checkbox" class="form-check-input" value="pregunta" v-model="showPregunta">Pregunta
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <label class="form-check-label">
                                                <input type="checkbox" class="form-check-input" value="estrategia" v-model="showEstrategia">Estrategia
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Jovenes:</label>
                                        <select
                                            type="text"
                                            class="form-control"
                                            v-model="nnaj_id"
                                        >
                                            <option value="">Seleccione opcion</option>
                                            <option v-for="joven in jovenes" :value="joven.id" :key="joven.id">
                                                {{joven.nombres}} {{joven.apellido_paterno}}
                                            </option>
                                        </select>
                                    </div>
                                    
                                    <div class="form-group">
                                        <label>Fecha</label>
                                        <input
                                            type="date"
                                            class="form-control"
                                            v-model="date"
                                        />
                                    </div>
                                     <div class="form-group" v-if="showRelato">
                                        <label>Relato</label>
                                        <textarea
                                            class="form-control"
                                            v-model="relato"
                                        >
                                        </textarea>
                                    </div>
                                    <div class="form-group" v-if="showHecho">
                                        <label>Hecho</label>
                                        <textarea
                                            class="form-control"
                                            v-model="hecho"
                                        >
                                        </textarea>
                                    </div>
                                    <div class="form-group" v-if="showNecesidad">
                                        <label>Necesidad</label>
                                        <textarea
                                            class="form-control"
                                            v-model="necesidad"
                                        >
                                        </textarea>
                                    </div>
                                    <div class="form-group" v-if="showEmocion">
                                        <label>Emocion</label>
                                        <textarea
                                            class="form-control"
                                            v-model="emocion"
                                        >
                                        </textarea>
                                    </div>
                                    <div class="form-group" v-if="showPosicion">
                                        <label>Posicion</label>
                                        <textarea
                                            class="form-control"
                                            v-model="posicion"
                                        >
                                        </textarea>
                                    </div>
                                    <div class="form-group" v-if="showInteres">
                                        <label>Interes</label>
                                        <textarea
                                            class="form-control"
                                            v-model="interes"
                                        >
                                        </textarea>
                                    </div>
                                    <div class="form-group" v-if="showActores">
                                        <label>Actores</label>
                                        <textarea
                                            class="form-control"
                                            v-model="actores"
                                        >
                                        </textarea>
                                    </div>
                                    <div class="form-group" v-if="showConflicto">
                                        <label>Conflicto</label>
                                        <textarea
                                            class="form-control"
                                            v-model="conflicto"
                                        >
                                        </textarea>
                                    </div>
                                    <div class="form-group" v-if="showKeyword">
                                        <label>Palabras clave</label>
                                        <textarea
                                            class="form-control"
                                            v-model="keyword"
                                        >
                                        </textarea>
                                    </div>
                                    <div class="form-group" v-if="showPregunta">
                                        <label>Palabras clave</label>
                                        <textarea
                                            class="form-control"
                                            v-model="pregunta"
                                        >
                                        </textarea>
                                    </div>
                                    <div class="form-group" v-if="showEstrategia">
                                        <label>Estrategia</label>
                                        <textarea
                                            class="form-control"
                                            v-model="estrategia"
                                        >
                                        </textarea>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button
                                        class="btn btn-danger"
                                        @click="showModal = false"
                                    >
                                        <i class="fa fa-times"></i>
                                        Cancelar
                                    </button>
                                       <button
                                        class="btn btn-success"
                                        @click="createInstituton()"
                                    >
                                        <i class="fa fa-check"></i>
                                        Crear Caso
                                    </button>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>

        <div v-if="showModalView">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title text-info">
                                        Caso {{caso.id}}
                                    </h4>
                                    <button
                                        type="button"
                                        class="close"
                                        @click="cancelarModal()"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="form-group">
                                        <label>Usuarios:</label>
                                         <ul>
                                            <li :key="user.id" v-for="user in caso.users">{{user.name}}</li>
                                        </ul>
                                    </div>
                                   
                                    <div class="form-group">
                                        <label>Joven:</label>
                                       {{ caso.joven.identidad }}
                                    </div>
                                    
                                    <div class="form-group">
                                        <label>Fecha:</label>
                                        {{ caso.date }}
                                    </div>
                                     <div class="form-group" v-if="caso.relato">
                                        <label>Relato</label>
                                         <p>{{ caso.relato }}</p>
                                    </div>
                                    <div class="form-group" v-if="caso.hecho">
                                        <label>Hecho</label>
                                        <p>{{ caso.hecho }}</p>
                                    </div>
                                    <div class="form-group" v-if="caso.necesidad">
                                        <label>Necesidad</label>
                                        <p>{{ caso.necesidad }}</p>
                                    </div>
                                    <div class="form-group" v-if="caso.emocion">
                                        <label>Emocion</label>
                                        <p>{{ caso.emocion }}</p>
                                    </div>
                                    <div class="form-group" v-if="caso.posicion">
                                        <label>Posicion</label>
                                        <p>{{ caso.posicion }}</p>
                                    </div>
                                    <div class="form-group" v-if="caso.interes">
                                        <label>Interes</label>
                                        <p>{{ caso.interes }}</p>
                                    </div>
                                    <div class="form-group" v-if="caso.actores">
                                        <label>Actores</label>
                                        <p>{{ caso.actores }}</p>
                                    </div>
                                    <div class="form-group" v-if="caso.conflicto">
                                        <label>Conflicto</label>
                                        <p>{{ caso.conflicto }}</p>
                                    </div>
                                    <div class="form-group" v-if="caso.keyword">
                                        <label>Palabras clave</label>
                                        <p>{{ caso.keyword }}</p>
                                    </div>
                                    <div class="form-group" v-if="caso.pregunta">
                                        <label>Preguntas</label>
                                        <p>{{ caso.pregunta }}</p>
                                    </div>
                                    <div class="form-group" v-if="caso.estrategia">
                                        <label>Estrategia</label>
                                        <p>{{ caso.estrategia }}</p>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button
                                        class="btn btn-danger"
                                        @click="showModalView = false"
                                    >
                                        <i class="fa fa-times"></i>
                                        Cancelar
                                    </button>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>


        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h1>Notificaciones</h1>
                    <hr>
                    <div class="form-group text-right">
                        <button class="btn btn-rosa" @click="enviarNotificacion()">
                            <i class="fa fa-check"></i>
                            Chequear Cumpleaños
                        </button>
                    </div>
                    <div class="form-group">
                        <vue-good-table
                            compactMode
                            :columns="columns"
                            :rows="casos" 
                            :search-options="{
                                enabled: true,
                                placeholder: 'Buscar en la tabla',
                            }" 
                            :pagination-options="{
                                enabled: true,
                                mode: 'records',
                                perPage: 5,
                                perPageDropdown: [3, 7, 9],
                                dropdownAllowAll: true,
                                setCurrentPage: 1,
                                nextLabel: 'siguiente',
                                prevLabel: 'anterior',
                                rowsPerPageLabel: 'Filas por página',
                                ofLabel: 'de',
                                pageLabel: 'página', // for 'pages' mode
                                allLabel: 'Todos',
                            }">
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import Swal from 'sweetalert2';
import 'vue-select/dist/vue-select.css';
export default {
    props:['propsUsuarios', 'propsDimensiones', 'propsJovenes'],
    computed: {},
    created() {
        this.getNotificaciones();
        this.usuarios = JSON.parse(this.propsUsuarios);
        this.dimensiones = JSON.parse(this.propsDimensiones);
        this.jovenes = JSON.parse(this.propsJovenes);
        console.log("los usuarios son: ", this.usuarios);
        console.log("los dimensiones son: ", this.dimensiones);
        console.log("los jovenes son: ", this.jovenes);
    },
    data() {
        return {
            usuarios: [],
            dimensiones: [],
            jovenes: [],
            user_id: "",
            users: [],
            date: "",
            nnaj_id: "",
            dimension_id: "",
            comment: "",
            showModal: false,
            showModalView: false,
            casos: [],
            respuestas: false,
            relato: "",
            hecho: "",
            necesidad: "",
            interes: "",
            emocion: "",
            posicion: "",
            actores: "",
            conflicto: "",
            keyword: "",
            pregunta: "",
            estrategia: "",
            ////
            showRelato: "",
            showHecho: "",
            showNecesidad: "",
            showInteres: "",
            showEmocion: "",
            showPosicion: "",
            showActores: "",
            showConflicto: "",
            showKeyword: "",
            showPregunta: "",
            showEstrategia: "",
            //vue good table
            columns: [
                {
                    label: 'Identificador',
                    field: 'id',
                    type: 'number'
                },
                {
                    label: 'Joven',
                    field: 'joven.nombres',
                    type: 'string',
                    sortable: true
                },
                {
                    label: 'Tipo notificacion',
                    field: 'typeNotification.name',
                    tdClass: 'text-center',
                    thClass: 'text-center',
                    sortable: true,
                },
                {
                    label: 'Creado en',
                    field: 'created_at',
                    sortable: true,
                    formatFn: this.formatDate,
                }
            ],
        };
    },
    methods: {
        formatDate(date){
            var date = new Date(date);
            return date.toLocaleString();
        },
        enviarNotificacion(){
            axios
                .get("/notify")
                .then(response => {
                    Swal.fire('Notificaciones en proceso!', '', 'success');
                })
                .catch(function(error) {
                    // handle error
                    console.log("ocurrio un error: ", error);
                });
        },
        getNotificaciones() {
            axios
                .get("/getNotificaciones")
                .then(response => {
                    console.log("la response es: ", response.data);
                    this.casos = response.data.notificaciones;
                })
                .catch(function(error) {
                    // handle error
                    console.log("ocurrio un error: ", error);
                });
        },
        createInstituton() {
            let data = {
                user_id: this.user_id,
                date: this.date,
                nnaj_id: this.nnaj_id,
                dimension_id: this.dimension_id,
                comment: this.comment,
                users: this.users,
                relato: this.relato,
                hecho: this.hecho,
                necesidad: this.necesidad,
                interes: this.interes,
                emocion: this.emocion,
                posicion: this.posicion,
                actores: this.actores,
                conflicto: this.conflicto,
                keyword: this.keyword,
                pregunta: this.pregunta,
                estrategia: this.estrategia,
            };
            console.log(
                "la data al agregar el analisis de caso es: ",
                data
            );
            let me = this;
            axios
                .post("/create_caso", data, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then(function(response) {
                    console.log(
                        "la repsuesta al agregar el caso es: ",
                        response
                    );
                    Swal.fire('Caso creado!', '', 'success');
                    me.showModal = false;
                    me.getNotificaciones();
               
                })
                .catch(function(error) {
                    console.log("FAILURE!!", error);
                });
        },
        verCaso(caso){
            console.log("el caso es: ",caso),
            this.caso = caso;
            this.showModalView = true;
        },
        eliminarCaso(id){
              let me = this;
            Swal.fire({
                title: '¿Esta seguro de querer borrar el caso?',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: `Eliminar`,
                cancelButtonText:'Cancelar',
                }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    axios.delete(`/delete_caso/${id}`, {
                            headers: {
                                "Content-Type": "application/json"
                            }
                        })
                        .then(function(response) {
                            console.log(
                                "la repsuesta al eliminar el institucion es: ",
                                response
                            );
                            Swal.fire('Caso eliminado!', '', 'success');
                            me.getNotificaciones();
                    
                        })
                        .catch(function(error) {
                            console.log("FAILURE!!", error);
                        });
                   
                } else if (result.isDenied) {
                    Swal.fire('Changes are not saved', '', 'info')
                }
                })
        },
    }
};
</script>
