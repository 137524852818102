<template>
    <div class="row">
        <div v-if="showModal">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog modal-sm">
                            <div class="modal-content">
                                <div class="modal-header">
                    
                                    <button
                                        type="button"
                                        class="close"
                                        @click="cancelarModal()"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body" id="errorsForm" ref="errorsForm">
                                    <div class="form-group" v-if="errors.length > 0">
                                        <div class="card">
                                            <div class="card-body bg-danger text-white">
                                                Errores al llenar los datos
                                                <ul>
                                                    <li :key="error" v-for="error in errors">
                                                        {{ error }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label>Usuarios:</label>
                                                <v-select
                                                    v-model="users"
                                                    :multiple="true"
                                                    label="name"
                                                    :options="usuarios"></v-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label>Fecha</label>
                                                <input type="date" class="form-control" v-model="date">
                                            </div> 
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label>Joven:</label>
                                                <v-select v-model="nnaj_id" label="nombres" :options="jovenes">
                                                            <template slot="selected-option" slot-scope="option">
                                                                <div class="flex">
                                                                    <div class="col">
                                                                        <span class="fa"></span>
                                                                        <span>{{
                                                                `${option.nombres} ${option.apellido_paterno} ${option.apellido_materno}`
                                                                }}</span>
                                                                     </div>
                                                                </div>
                                                            </template>
                                                            <template slot="option" slot-scope="option">
                                                                <span>{{
                                                        `${option.nombres} ${option.apellido_paterno} ${option.apellido_materno}`
                                                        }}</span>
                                                            </template>
                                                </v-select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <table class="table table-bordered table-hover">
                                            <thead>
                                                <th scope="row" style="width: 5%"></th>
                                                <th style="width: 5%">Dimensión</th>
                                                <th style="width: 5%">Sub Dimensión</th>
                                                <th style="width: 5%">Categoria</th>
                                                <th>Actividad</th>
                                                <th>0 (se niega o desconoce la actividad)</th>
                                                <th>1 (requiere de acompañamiento para aprender a hacer)</th>
                                                <th>2 (requiere de acompañamiento para reflexionar el error)</th>
                                                <th>3 (lo logra adecuadamente y sin ayuda)</th>
                                                <th>No se observa</th>
                                                <th>Puntaje</th>
                                                <th>Observaciones</th>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">1</th>
                                                    <td>Domestico test</td>
                                                    <td>Compras</td>
                                                    <td>Lista de compras</td>
                                                    <td>Elaboración y participación</td>
                                                    <td>
                                                        
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta1, $event)" type="radio" id="pregunta10" :value="0" v-model="monitoreo.pregunta1.score">
                                                        <label for="pregunta10">No participa en la elaboración de la lista de compras</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico' ,monitoreo.pregunta1, $event)"  type="radio" id="pregunta11" :value="1" v-model="monitoreo.pregunta1.score">
                                                        <label for="pregunta11">Requiere acompañamiento para participar en la elaboración de lista de compras</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico' ,monitoreo.pregunta1, $event)"  type="radio" id="pregunta12" :value="2" v-model="monitoreo.pregunta1.score">
                                                        <label for="pregunta12">Participa de manera pasiva en  la elaboración de la lista</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta1, $event)"  type="radio" id="pregunta13" :value="3" v-model="monitoreo.pregunta1.score">
                                                        <label for="pregunta13">Participa de manera activa y colaborativa en la elaboración de la lista</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta1, $event)"  type="radio" id="pregunta14" :value="'No observa'" v-model="monitoreo.pregunta1.score">
                                                        <label for="pregunta14">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta1.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta1.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">2</th>
                                                    <td>Domestico</td>
                                                    <td>Compras</td>
                                                    <td>Lista de compras</td>
                                                    <td>Cantidades</td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico' ,monitoreo.pregunta2, $event)"  type="radio" id="pregunta20" :value="0"  v-model="monitoreo.pregunta2.score">
                                                        <label for="pregunta20">No estima cantidad de productos necesarios</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta2, $event)" type="radio" id="pregunta21" :value="1" v-model="monitoreo.pregunta2.score">
                                                        <label for="pregunta21">Requiere de acompañamiento para estimar la cantidad de productos necesarios</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta2, $event)"  type="radio" id="pregunta22" :value="2" v-model="monitoreo.pregunta2.score">
                                                        <label for="pregunta22">Estima de manera equitativa la cantidad de productos necesarios, pero no planifica de manera eficiente</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta2, $event)" type="radio" id="pregunta23" :value="3" v-model="monitoreo.pregunta2.score">
                                                        <label for="pregunta23">Estima y planifica de manera eficiente y equitativa la cantidad de productos necesarios</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta2, $event)"  type="radio" id="pregunta24" :value="'No observa'" v-model="monitoreo.pregunta2.score">
                                                        <label for="pregunta24">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta2.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta2.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">3</th>
                                                    <td>Domestico</td>
                                                    <td>Compras</td>
                                                    <td>Lista de compras</td>
                                                    <td>Necesidades</td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta3, $event)" type="radio" id="pregunta30" :value="0" v-model="monitoreo.pregunta3.score">
                                                        <label for="pregunta30">No considera las necesidades de la casa, priorizando sus propias necesidades</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta3, $event)" type="radio" id="pregunta31" :value="1" v-model="monitoreo.pregunta3.score">
                                                        <label for="pregunta31">Requiere de acompañamiento para realizar la lista de compras revisando lo que hay en la casa y la planificación semanal</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta3, $event)"  type="radio" id="pregunta32" :value="2" v-model="monitoreo.pregunta3.score">
                                                        <label for="pregunta32">Realiza lista de compras de manera colectiva basándose únicamente en la revisión de lo que hay en la casa o en la planificación semanal </label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta3, $event)"  type="radio" id="pregunta33" :value="3" v-model="monitoreo.pregunta3.score">
                                                        <label for="pregunta33">Realiza lista de compras de manera colectiva, basándose en la revisión de productos que hay en la casa y en la planificación semanal </label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta3, $event)"  type="radio" id="pregunta34" :value="'No observa'" v-model="monitoreo.pregunta3.score">
                                                        <label for="pregunta34">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label >{{this.monitoreo.pregunta3.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta3.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">4</th>
                                                    <td>Domestico</td>
                                                    <td>Compras</td>
                                                    <td>Presupuesto</td>
                                                    <td>Ajuste al presupuesto</td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta4, $event)" type="radio" id="pregunta40" :value="0" v-model="monitoreo.pregunta4.score">
                                                        <label for="pregunta40">No se ajusta al presupuesto de las compras </label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta4, $event)" type="radio" id="pregunta41" :value="1" v-model="monitoreo.pregunta4.score">
                                                        <label for="pregunta41">Requiere acompañamiento para ajustarse al presupuesto</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta4, $event)" type="radio" id="pregunta42" :value="2" v-model="monitoreo.pregunta4.score">
                                                        <label for="pregunta42">Se ajusta al presupuesto con dificultad</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta4, $event)" type="radio" id="pregunta43" :value="3" v-model="monitoreo.pregunta4.score">
                                                        <label for="pregunta43">Se ajusta al presupuesto de las compras</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta4, $event)"  type="radio" id="pregunta44" :value="'No observa'" v-model="monitoreo.pregunta4.score">
                                                        <label for="pregunta44">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label>{{this.monitoreo.pregunta4.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta4.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">5</th>
                                                    <td>Domestico</td>
                                                    <td>Compras</td>
                                                    <td>Presupuesto</td>
                                                    <td>Priorizacion</td>
                                                    <td>
                                                        <input  @change="getRespuesta('domestico', monitoreo.pregunta5, $event)" type="radio" id="pregunta50" :value="0" v-model="monitoreo.pregunta5.score">
                                                        <label for="pregunta50">No prioriza productos de primera necesidad sobre otros productos</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta5, $event)" type="radio" id="pregunta51" :value="1" v-model="monitoreo.pregunta5.score">
                                                        <label for="pregunta51">Requiere de acompañamiento para priorizar los productos</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta5, $event)" type="radio" id="pregunta52" :value="2" v-model="monitoreo.pregunta5.score">
                                                        <label for="pregunta52">Prioriza los productos con dificultad</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta5, $event)"  type="radio" id="pregunta53" :value="3" v-model="monitoreo.pregunta5.score">
                                                        <label for="pregunta53">Prioriza de manera efectiva los productos necesarios.</label>
                                                    </td>
                                                    <td>
                                                       <input @change="getRespuesta('domestico', monitoreo.pregunta5, $event)"  type="radio" id="pregunta54" :value="'No observa'" v-model="monitoreo.pregunta5.score">
                                                        <label for="pregunta54">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label>{{this.monitoreo.pregunta5.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta5.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">6</th>
                                                    <td>Domestico</td>
                                                    <td>Compras</td>
                                                    <td>Presupuesto</td>
                                                    <td>Calculo</td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta6, $event)"  type="radio" id="pregunta60" :value="0" v-model="monitoreo.pregunta6.score">
                                                        <label for="pregunta60">No lleva cálculo de la compra</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta6, $event)" type="radio" id="pregunta61" :value="1" v-model="monitoreo.pregunta6.score">
                                                        <label for="pregunta61">Requiere acompañamiento para llevar el cálculo de la compra</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta6, $event)" type="radio" id="pregunta62" :value="2" v-model="monitoreo.pregunta6.score">
                                                        <label for="pregunta62">Lleva cálculo de la compra con dificultad</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta6, $event)" type="radio" id="pregunta63" :value="3" v-model="monitoreo.pregunta6.score">
                                                        <label for="pregunta63">Lleva cálculo efectivo de la compra</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta6, $event)"  type="radio" id="pregunta64" :value="'No observa'" v-model="monitoreo.pregunta6.score">
                                                        <label for="pregunta64">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta6.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta6.comment"></textarea>
                                                    </td>
                                                </tr>           
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">7</th>
                                                    <td>Domestico</td>
                                                    <td>Cocina</td>
                                                    <td>Elaboración</td>
                                                    <td>Variedad</td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta7, $event)" type="radio" id="pregunta70" :value="0" v-model="monitoreo.pregunta7.score">
                                                        <label for="pregunta70">No cocina</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta7, $event)" type="radio" id="pregunta71" :value="1" v-model="monitoreo.pregunta7.score">
                                                        <label for="pregunta71">Requiere de acompañamiento para preparar recetas variadas</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta7, $event)" type="radio" id="pregunta72" :value="2" v-model="monitoreo.pregunta7.score">
                                                        <label for="pregunta72">Suele preparar las mismas recetas</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta7, $event)" type="radio" id="pregunta73" :value="3" v-model="monitoreo.pregunta7.score">
                                                        <label for="pregunta73">Prepara recetas variadas</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta7, $event)"  type="radio" id="pregunta74" :value="'No observa'" v-model="monitoreo.pregunta7.score">
                                                        <label for="pregunta74">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta7.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta7.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">8</th>
                                                    <td>Domestico</td>
                                                    <td>Cocina</td>
                                                    <td>Elaboración</td>
                                                    <td>Utensilios</td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta8, $event)" type="radio" id="pregunta80" :value="0" v-model="monitoreo.pregunta8.score">
                                                        <label for="pregunta80">No utiliza o desconoce los utensilios para cocinar</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta8, $event)" type="radio" id="pregunta81" :value="1" v-model="monitoreo.pregunta8.score">
                                                        <label for="pregunta81">Requiere acompañamiento para utilizar de manera correcta los utensilios que correspoden</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta8, $event)" type="radio" id="pregunta82" :value="2" v-model="monitoreo.pregunta8.score">
                                                        <label for="pregunta82">Utiliza con dificultad y/o errores los utensilios</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta8, $event)" type="radio" id="pregunta83" :value="3" v-model="monitoreo.pregunta8.score">
                                                        <label for="pregunta83">Utiliza de manera correcta los utensilios que corresponden</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta8, $event)" type="radio" id="pregunta84" :value="'No observa'" v-model="monitoreo.pregunta8.score">
                                                        <label for="pregunta84">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta8.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta8.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">9</th>
                                                    <td>Domestico</td>
                                                    <td>Cocina</td>
                                                    <td>Nutrición</td>
                                                    <td>Porciones</td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta9, $event)" type="radio" id="pregunta90" :value="0" v-model="monitoreo.pregunta9.score">
                                                        <label for="pregunta90">Cocina sin considerar las porciones correspondientes</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta9, $event)" type="radio" id="pregunta91" :value="1" v-model="monitoreo.pregunta9.score">
                                                        <label for="pregunta91">Requiere acompañamiento para calcular las porciones</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta9, $event)" type="radio" id="pregunta92" :value="2" v-model="monitoreo.pregunta9.score">
                                                        <label for="pregunta92">Calcula las porciones con errores</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta9, $event)" type="radio" id="pregunta93" :value="3" v-model="monitoreo.pregunta9.score">
                                                        <label for="pregunta93">Calcula adecuadamente  las porciones correspondientes</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta9, $event)" type="radio" id="pregunta94" :value="'No observa'" v-model="monitoreo.pregunta9.score">
                                                        <label for="pregunta94">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta9.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta9.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">10</th>
                                                    <td>Domestico</td>
                                                    <td>Cocina</td>
                                                    <td>Nutrición</td>
                                                    <td>Plato balanceado</td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta10, $event)" type="radio" id="pregunta100" :value="0" v-model="monitoreo.pregunta10.score">
                                                        <label for="pregunta100">No considera un plato balanceado</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta10, $event)" type="radio" id="pregunta101" :value="1" v-model="monitoreo.pregunta10.score">
                                                        <label for="pregunta101">Requiere acompañamiento para considerar el plato balanceado</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta10, $event)"  type="radio" id="pregunta102" :value="2" v-model="monitoreo.pregunta10.score">
                                                        <label for="pregunta102">Cocina basándose en el plato balanceado, pero con errores</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta10, $event)" type="radio" id="pregunta103" :value="3" v-model="monitoreo.pregunta10.score">
                                                        <label for="pregunta103">Cocina basándose en el plato balanceado</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta10, $event)" type="radio" id="pregunta104" :value="'No observa'" v-model="monitoreo.pregunta10.score">
                                                        <label for="pregunta104">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta10.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta10.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">11</th>
                                                    <td>Domestico</td>
                                                    <td>Aseo</td>
                                                    <td>Productos</td>
                                                    <td>Diferenciación de productos</td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta11, $event)" type="radio" id="pregunta110" :value="0" v-model="monitoreo.pregunta11.score">
                                                        <label for="pregunta110">No logra diferenciar los tipos de productos ni sus funciones al momento de hacer el aseo</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta11, $event)" type="radio" id="pregunta111" :value="1" v-model="monitoreo.pregunta11.score">
                                                        <label for="pregunta111">Requiere acompañamiento para difererenciar los tipos de productos y sus funciones</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta11, $event)" type="radio" id="pregunta112" :value="2" v-model="monitoreo.pregunta11.score">
                                                        <label for="pregunta112">Logra diferenciar con dificultad los tipos de productos y sus funciones</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta11, $event)" type="radio" id="pregunta113" :value="3" v-model="monitoreo.pregunta11.score">
                                                        <label for="pregunta113">Logra diferenciar los tipos de productos y la función de cada uno</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta11, $event)" type="radio" id="pregunta114" :value="'No observa'" v-model="monitoreo.pregunta11.score">
                                                        <label for="pregunta114">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta11.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta11.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">12</th>
                                                    <td>Domestico</td>
                                                    <td>Aseo</td>
                                                    <td>Productos</td>
                                                    <td>Optimización de productos</td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta12, $event)" type="radio" id="pregunta120" :value="0" v-model="monitoreo.pregunta12.score">
                                                        <label for="pregunta120">No logra optimizar de manera eficiente los productos</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta12, $event)"  type="radio" id="pregunta121" :value="1" v-model="monitoreo.pregunta12.score">
                                                        <label for="pregunta121">Requiere acompañamiento para optimizar los productos</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta12, $event)"  type="radio" id="pregunta122" :value="2" v-model="monitoreo.pregunta12.score">
                                                        <label for="pregunta122">Optimiza los productos con errores</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta12, $event)" type="radio" id="pregunta123" :value="3" v-model="monitoreo.pregunta12.score">
                                                        <label for="pregunta123">Optimiza de manera correcta y eficiente los productos</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta12, $event)" type="radio" id="pregunta124" :value="'No observa'" v-model="monitoreo.pregunta12.score">
                                                        <label for="pregunta124">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta12.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta12.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">13</th>
                                                    <td>Domestico</td>
                                                    <td>Aseo</td>
                                                    <td>Orden</td>
                                                    <td>Orden espacio personal</td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta13, $event)" type="radio" id="pregunta130" :value="0" v-model="monitoreo.pregunta13.score">
                                                        <label for="pregunta130">
                                                            No mantiene el orden en su espacio personal ni muestra interés en hacerlo 
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta13, $event)" type="radio" id="pregunta131" :value="1" v-model="monitoreo.pregunta13.score">
                                                        <label for="pregunta131">Requiere acompañamiento para mantener el orden en su espacio personal</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta13, $event)" type="radio" id="pregunta132" :value="2" v-model="monitoreo.pregunta13.score">
                                                        <label for="pregunta132">Mantiene y se ocupa del orden en su espacio personal con errores</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta13, $event)" type="radio" id="pregunta133" :value="3" v-model="monitoreo.pregunta13.score">
                                                        <label for="pregunta133">Mantiene y se ocupa del orden de su espacio personal periódicamente</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta13, $event)" type="radio" id="pregunta134" :value="'No observa'" v-model="monitoreo.pregunta13.score">
                                                        <label for="pregunta134">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta13.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta13.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">14</th>
                                                    <td>Domestico</td>
                                                    <td>Aseo</td>
                                                    <td>Orden</td>
                                                    <td>Orden espacio comun</td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta14, $event)" type="radio" id="pregunta140" :value="0" v-model="monitoreo.pregunta14.score">
                                                        <label for="pregunta140">No mantiene el orden en espacios comunes ni muestra interés en hacerlo</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta14, $event)" type="radio" id="pregunta141" :value="1" v-model="monitoreo.pregunta14.score">
                                                        <label for="pregunta141">Requiere acompañamiento para mantener el orden de los espacios comunes</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta14, $event)" type="radio" id="pregunta142" :value="2" v-model="monitoreo.pregunta14.score">
                                                        <label for="pregunta142">Mantiene y se ocupa del orden del espacio común con errores</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta14, $event)" type="radio" id="pregunta143" :value="3" v-model="monitoreo.pregunta14.score">
                                                        <label for="pregunta143">Mantiene y se ocupa del orden del espacio común periódicamente</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta14, $event)" type="radio" id="pregunta144" :value="'No observa'" v-model="monitoreo.pregunta14.score">
                                                        <label for="pregunta144">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta14.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta14.comment"></textarea>
                                                    </td>
                                                </tr>
                                                 <tr>
                                                     <th scope="row"  style="min-width:5px">15</th>
                                                    <td>Domestico</td>
                                                    <td>Aseo</td>
                                                    <td>Limpieza</td>
                                                    <td>Limpieza espacio personal</td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta15, $event)" type="radio" id="pregunta150" :value="0" v-model="monitoreo.pregunta15.score">
                                                        <label for="pregunta150">Mantiene su espacio personal sucio y no muestra interés en limpiar</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta15, $event)" type="radio" id="pregunta151" :value="1" v-model="monitoreo.pregunta15.score">
                                                        <label for="pregunta151">Requiere acompañamiento para mantener la limpieza del espacio personal</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta15, $event)" type="radio" id="pregunta152" :value="2" v-model="monitoreo.pregunta15.score">
                                                        <label for="pregunta152">Mantiene y se ocupa de la limpieza del espacio personal con errores</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta15, $event)" type="radio" id="pregunta153" :value="3" v-model="monitoreo.pregunta15.score">
                                                        <label for="pregunta153">Mantiene y se ocupa periódicamente de la limpieza del espacio personal</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta15, $event)" type="radio" id="pregunta154" :value="'No observa'" v-model="monitoreo.pregunta15.score">
                                                        <label for="pregunta154">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta15.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta15.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">16</th>
                                                    <td>Domestico</td>
                                                    <td>Aseo</td>
                                                    <td>Limpieza</td>
                                                    <td>Limpieza espacios comunes</td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta16, $event)" type="radio" id="pregunta160" :value="0" v-model="monitoreo.pregunta16.score">
                                                        <label for="pregunta160">Mantiene los espacios comunes sucio y no muestra interés en limpiar</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta16, $event)" type="radio" id="pregunta161" :value="1" v-model="monitoreo.pregunta16.score">
                                                        <label for="pregunta161">Requiere acompañamimento para mantener la limpieza de los espacios comunes</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta16, $event)" type="radio" id="pregunta162" :value="2" v-model="monitoreo.pregunta16.score">
                                                        <label for="pregunta162">Mantiene y se ocupa de la limpieza de los espacios comunes con errores</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta16, $event)" type="radio" id="pregunta163" :value="3" v-model="monitoreo.pregunta16.score">
                                                        <label for="pregunta163">Mantiene y se ocupa periódicamente de la limpieza de los espacios comunes</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('domestico', monitoreo.pregunta16, $event)" type="radio" id="pregunta164" :value="'No observa'" v-model="monitoreo.pregunta16.score">
                                                        <label for="pregunta164">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta16.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta16.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">17</th>
                                                    <td>Movilizacion</td>
                                                    <td>Traslado</td>
                                                    <td>Ruta</td>
                                                    <td>Planificación</td>
                                                    <td>
                                                        <input @change="getRespuesta('movilizacion', monitoreo.pregunta17, $event)" type="radio" id="pregunta170" :value="0" v-model="monitoreo.pregunta17.score">
                                                        <label for="pregunta170">No calcula ni planifica la ruta con anticipación</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('movilizacion', monitoreo.pregunta17, $event)" type="radio" id="pregunta171" :value="1" v-model="monitoreo.pregunta17.score">
                                                        <label for="pregunta171">Requiere acompañamiento para planificar ruta</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('movilizacion', monitoreo.pregunta17, $event)" type="radio" id="pregunta172" :value="2" v-model="monitoreo.pregunta17.score">
                                                        <label for="pregunta172">Calcula y estima tiempo de traslado eligiendo la ruta con errores</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('movilizacion', monitoreo.pregunta17, $event)" type="radio" id="pregunta173" :value="3" v-model="monitoreo.pregunta17.score">
                                                        <label for="pregunta173">Cálcula y estima tiempo de traslado eligiendo la mejor ruta</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('movilizacion', monitoreo.pregunta17, $event)" type="radio" id="pregunta174" :value="'No observa'" v-model="monitoreo.pregunta17.score">
                                                        <label for="pregunta174">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta17.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta17.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">18</th>
                                                    <td>Monilizacion</td>
                                                    <td>Traslado</td>
                                                    <td>Ruta</td>
                                                    <td>Ejecuta</td>
                                                    <td>
                                                        <input @change="getRespuesta('movilizacion', monitoreo.pregunta18, $event)" type="radio" id="pregunta180" :value="0" v-model="monitoreo.pregunta18.score">
                                                        <label for="pregunta180">No logra llegar a destino</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('movilizacion', monitoreo.pregunta18, $event)" type="radio" id="pregunta181" :value="1" v-model="monitoreo.pregunta18.score">
                                                        <label for="pregunta181">Requiere de acompañamiento para ejecutar la ruta</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('movilizacion', monitoreo.pregunta18, $event)" type="radio" id="pregunta182" :value="2" v-model="monitoreo.pregunta18.score">
                                                        <label for="pregunta182">Ejecuta de manera autónoma la ruta pero no de manera eficiente</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('movilizacion', monitoreo.pregunta18, $event)" type="radio" id="pregunta183" :value="3" v-model="monitoreo.pregunta18.score">
                                                        <label for="pregunta183">Ejecuta de manera autónoma la ruta, realizando trasbordos y combinaciones eficientes</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('movilizacion', monitoreo.pregunta18, $event)" type="radio" id="pregunta184" :value="'No observa'" v-model="monitoreo.pregunta18.score">
                                                        <label for="pregunta184">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta18.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta18.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">19</th>
                                                    <td>MANEJO DE DINERO</td>
                                                    <td>Administración de recursos</td>
                                                    <td>Ingresos</td>
                                                    <td>Ahorro</td>
                                                    <td>
                                                        <input @change="getRespuesta('manejo_dinero', monitoreo.pregunta19, $event)" type="radio" id="pregunta190" :value="0" v-model="monitoreo.pregunta19.score">
                                                        <label for="pregunta190">No ahorra su dinero ni muestra iniciativa en hacerlo</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('manejo_dinero', monitoreo.pregunta19, $event)" type="radio" id="pregunta191" :value="1" v-model="monitoreo.pregunta19.score">
                                                        <label for="pregunta191">Muestra iniciativa en ahorrar, pero no lo hace</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('manejo_dinero', monitoreo.pregunta19, $event)" type="radio" id="pregunta192" :value="2" v-model="monitoreo.pregunta19.score">
                                                        <label for="pregunta192">Logra ahorrar, pero no logra mantener el dinero ahorrado en el tiempo</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('manejo_dinero', monitoreo.pregunta19, $event)" type="radio" id="pregunta193" :value="3" v-model="monitoreo.pregunta19.score">
                                                        <label for="pregunta193">Logra ahorrar su dinero exitosamente</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('manejo_dinero', monitoreo.pregunta19, $event)" type="radio" id="pregunta194" :value="'No observa'" v-model="monitoreo.pregunta19.score">
                                                        <label for="pregunta194">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta19.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta19.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">20</th>
                                                    <td>MANEJO DE DINERO</td>
                                                    <td>Administración de recursos</td>
                                                    <td>Gastos</td>
                                                    <td>Pagos</td>
                                                    <td>
                                                        <input @change="getRespuesta('manejo_dinero', monitoreo.pregunta20, $event)" type="radio" id="pregunta200" :value="0" v-model="monitoreo.pregunta20.score">
                                                        <label for="pregunta200">No logra realizar los pagos</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('manejo_dinero', monitoreo.pregunta20, $event)" type="radio" id="pregunta201" :value="1" v-model="monitoreo.pregunta20.score">
                                                        <label for="pregunta201">Requiere acompañamiento para realizar los pagos</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('manejo_dinero', monitoreo.pregunta20, $event)" type="radio" id="pregunta202" :value="2" v-model="monitoreo.pregunta20.score">
                                                        <label for="pregunta202">Realiza pagos con errores</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('manejo_dinero', monitoreo.pregunta20, $event)" type="radio" id="pregunta203" :value="3" v-model="monitoreo.pregunta20.score">
                                                        <label for="pregunta203">Realiza el pagos de forma autónoma y correcta</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('manejo_dinero', monitoreo.pregunta20, $event)" type="radio" id="pregunta204" :value="'No observa'" v-model="monitoreo.pregunta20.score">
                                                        <label for="pregunta204">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta20.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta20.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">21</th>
                                                    <td>MANEJO DE DINERO</td>
                                                    <td>Administración de recursos</td>
                                                    <td>Gastos</td>
                                                    <td>Vuelto</td>
                                                    <td>
                                                        <input @change="getRespuesta('manejo_dinero', monitoreo.pregunta21, $event)" type="radio" id="pregunta210" :value="0" v-model="monitoreo.pregunta21.score">
                                                        <label for="pregunta210">No comprueba el vuelto</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('manejo_dinero', monitoreo.pregunta21, $event)" type="radio" id="pregunta211" :value="1" v-model="monitoreo.pregunta21.score">
                                                        <label for="pregunta211">Requiere acompañamiento para comprobar el vuelto</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('manejo_dinero', monitoreo.pregunta21, $event)" type="radio" id="pregunta212" :value="2" v-model="monitoreo.pregunta21.score">
                                                        <label for="pregunta212">Comprueba el vuelto con errores</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('manejo_dinero', monitoreo.pregunta21, $event)" type="radio" id="pregunta213" :value="3" v-model="monitoreo.pregunta21.score">
                                                        <label for="pregunta213">Comprueba el vuelto de manera correcta y autónoma</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('manejo_dinero', monitoreo.pregunta21, $event)" type="radio" id="pregunta214" :value="'No observa'" v-model="monitoreo.pregunta21.score">
                                                        <label for="pregunta214">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta21.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta21.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">22</th>
                                                    <td>MANEJO DE DINERO</td>
                                                    <td>Administración de recursos</td>
                                                    <td>Gastos</td>
                                                    <td>Rendición</td>
                                                    <td>
                                                        <input @change="getRespuesta('manejo_dinero', monitoreo.pregunta22, $event)" type="radio" id="pregunta220" :value="0" v-model="monitoreo.pregunta22.score">
                                                        <label for="pregunta220">No realiza rendición</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('manejo_dinero', monitoreo.pregunta22, $event)" type="radio" id="pregunta221" :value="1" v-model="monitoreo.pregunta22.score">
                                                        <label for="pregunta221">Requiere acompañamiento para realizar la rendición</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('manejo_dinero', monitoreo.pregunta22, $event)" type="radio" id="pregunta222" :value="2" v-model="monitoreo.pregunta22.score">
                                                        <label for="pregunta222">Realizar rendición con errores o dificultad</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('manejo_dinero', monitoreo.pregunta22, $event)" type="radio" id="pregunta223" :value="3" v-model="monitoreo.pregunta22.score">
                                                        <label for="pregunta223">Realiza rendición de manera correcta cuando corresponde</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('manejo_dinero', monitoreo.pregunta22, $event)" type="radio" id="pregunta224" :value="'No observa'" v-model="monitoreo.pregunta22.score">
                                                        <label for="pregunta224">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta22.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta22.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">23</th>
                                                    <td>REDES Y SERVICIOS</td>
                                                    <td>Identificación</td>
                                                    <td>Información</td>
                                                    <td>Conocimiento</td>
                                                    <td>
                                                        <input @change="getRespuesta('redes_servicios', monitoreo.pregunta23, $event)" type="radio" id="pregunta230" :value="0" v-model="monitoreo.pregunta23.score">
                                                        <label for="pregunta230">No reconoce redes y servicios</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('redes_servicios', monitoreo.pregunta23, $event)" type="radio" id="pregunta231" :value="1" v-model="monitoreo.pregunta23.score">
                                                        <label for="pregunta231">Requiere acompañamiento para reconocer redes y servicios</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('redes_servicios', monitoreo.pregunta23, $event)" type="radio" id="pregunta232" :value="2" v-model="monitoreo.pregunta23.score">
                                                        <label for="pregunta232">Reconoce con dificultad redes y servicios relacionados con su necesidad *</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('redes_servicios', monitoreo.pregunta23, $event)" type="radio" id="pregunta233" :value="3" v-model="monitoreo.pregunta23.score">
                                                        <label for="pregunta233">Reconoce redes y servicios relacionados con su necesidad*</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('redes_servicios', monitoreo.pregunta23, $event)" type="radio" id="pregunta234" :value="'No observa'" v-model="monitoreo.pregunta23.score">
                                                        <label for="pregunta234">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta23.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta23.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">24</th>
                                                    <td>REDES Y SERVICIOS</td>
                                                    <td>Identificación</td>
                                                    <td>Información</td>
                                                    <td>Búsqueda y contacto</td>
                                                    <td>
                                                        <input @change="getRespuesta('redes_servicios', monitoreo.pregunta24, $event)" type="radio" id="pregunta240" :value="0" v-model="monitoreo.pregunta24.score">
                                                        <label for="pregunta240">No busca ni contacta servicio/red requerido</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('redes_servicios', monitoreo.pregunta24, $event)" type="radio" id="pregunta241" :value="1" v-model="monitoreo.pregunta24.score">
                                                        <label for="pregunta241">Requiere acompañamiento para la búsqueda y/o contacto del servicio/red requerido</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('redes_servicios', monitoreo.pregunta24, $event)" type="radio" id="pregunta242" :value="2" v-model="monitoreo.pregunta24.score">
                                                        <label for="pregunta242">Busca y/o contacta al servicio/red requerido con errores</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('redes_servicios', monitoreo.pregunta24, $event)" type="radio" id="pregunta243" :value="3" v-model="monitoreo.pregunta24.score">
                                                        <label for="pregunta243">Busca y se contacta de manera autónoma con el servicio/red requerido</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('redes_servicios', monitoreo.pregunta24, $event)" type="radio" id="pregunta244" :value="'No observa'" v-model="monitoreo.pregunta24.score">
                                                        <label for="pregunta244">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta24.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta24.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">25</th>
                                                    <td>REDES Y SERVICIOS</td>
                                                    <td>Uso</td>
                                                    <td>Adherencia</td>
                                                    <td>Participación</td>
                                                    <td>
                                                        <input @change="getRespuesta('redes_servicios', monitoreo.pregunta25, $event)" type="radio" id="pregunta250" :value="0" v-model="monitoreo.pregunta25.score">
                                                        <label for="pregunta250">No busca ni contacta servicio/red requerido</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('redes_servicios', monitoreo.pregunta25, $event)" type="radio" id="pregunta251" :value="1" v-model="monitoreo.pregunta25.score">
                                                        <label for="pregunta251">Requiere acompañamiento para la búsqueda y/o contacto del servicio/red requerido</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('redes_servicios', monitoreo.pregunta25, $event)" type="radio" id="pregunta252" :value="2" v-model="monitoreo.pregunta25.score">
                                                        <label for="pregunta252">Busca y/o contacta al servicio/red requerido con errores</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('redes_servicios', monitoreo.pregunta25, $event)" type="radio" id="pregunta253" :value="3" v-model="monitoreo.pregunta25.score">
                                                        <label for="pregunta253">Busca y se contacta de manera autónoma con el servicio/red requerido</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('redes_servicios', monitoreo.pregunta25, $event)" type="radio" id="pregunta254" :value="'No observa'" v-model="monitoreo.pregunta25.score">
                                                        <label for="pregunta254">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta25.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta25.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">26</th>
                                                    <td>EMPLEO</td>
                                                    <td>Participacion</td>
                                                    <td>Responsabilidad</td>
                                                    <td>Busqueda</td>
                                                    <td>
                                                        <input @change="getRespuesta('empleo', monitoreo.pregunta26, $event)" type="radio" id="pregunta260" :value="0" v-model="monitoreo.pregunta26.score">
                                                        <label for="pregunta260">No busca empleo</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('empleo', monitoreo.pregunta26, $event)" type="radio" id="pregunta261" :value="1" v-model="monitoreo.pregunta26.score">
                                                        <label for="pregunta261">Requiere acompañamiento en la búsqueda de empleo </label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('empleo', monitoreo.pregunta26, $event)" type="radio" id="pregunta262" :value="2" v-model="monitoreo.pregunta26.score">
                                                        <label for="pregunta262">Busca empleo con dificultad y/o de manera pasiva</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('empleo', monitoreo.pregunta26, $event)" type="radio" id="pregunta263" :value="3" v-model="monitoreo.pregunta26.score">
                                                        <label for="pregunta263">Busca empleo de manera efectiva y eficiente</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('empleo', monitoreo.pregunta26, $event)" type="radio" id="pregunta264" :value="'No observa'" v-model="monitoreo.pregunta26.score">
                                                        <label for="pregunta264">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta26.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta26.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">27</th>
                                                    <td>EMPLEO</td>
                                                    <td>Participacion</td>
                                                    <td>Responsabilidad</td>
                                                    <td>Permanencia</td>
                                                    <td>
                                                        <input @change="getRespuesta('empleo', monitoreo.pregunta27, $event)" type="radio" id="pregunta270" :value="0" v-model="monitoreo.pregunta27.score">
                                                        <label for="pregunta270">No se mantiene en el empleo</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('empleo', monitoreo.pregunta27, $event)" type="radio" id="pregunta271" :value="1" v-model="monitoreo.pregunta27.score">
                                                        <label for="pregunta271">Requiere acompañamiento en la mantención del empleo </label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('empleo', monitoreo.pregunta27, $event)" type="radio" id="pregunta272" :value="2" v-model="monitoreo.pregunta27.score">
                                                        <label for="pregunta272">Se mantiene con dificultad en el empleo</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('empleo', monitoreo.pregunta27, $event)" type="radio" id="pregunta273" :value="3" v-model="monitoreo.pregunta27.score">
                                                        <label for="pregunta273">Se mantiene en el empleo (Agregar observación de tiempo)</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('empleo', monitoreo.pregunta27, $event)" type="radio" id="pregunta274" :value="'No observa'" v-model="monitoreo.pregunta27.score">
                                                        <label for="pregunta274">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta27.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta27.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">28</th>
                                                    <td>EMPLEO</td>
                                                    <td>Participacion</td>
                                                    <td>Responsabilidad</td>
                                                    <td>Asistencia</td>
                                                    <td>
                                                        <input @change="getRespuesta('empleo', monitoreo.pregunta28, $event)" type="radio" id="pregunta280" :value="0" v-model="monitoreo.pregunta28.score">
                                                        <label for="pregunta280">No asiste al empleo</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('empleo', monitoreo.pregunta28, $event)" type="radio" id="pregunta281" :value="1" v-model="monitoreo.pregunta28.score">
                                                        <label for="pregunta281">Requiere acompañamiento para asistir al empleo</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('empleo', monitoreo.pregunta28, $event)" type="radio" id="pregunta282" :value="2" v-model="monitoreo.pregunta28.score">
                                                        <label for="pregunta282">Asiste la mayoría del tiempo al empleo</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('empleo', monitoreo.pregunta28, $event)" type="radio" id="pregunta283" :value="3" v-model="monitoreo.pregunta28.score">
                                                        <label for="pregunta283">Asiste al empleo</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('empleo', monitoreo.pregunta28, $event)" type="radio" id="pregunta284" :value="'No observa'" v-model="monitoreo.pregunta28.score">
                                                        <label for="pregunta284">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta28.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta28.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">29</th>
                                                    <td>EDUCACIÓN</td>
                                                    <td>Participación</td>
                                                    <td>Responsabilidad</td>
                                                    <td>Inscripción</td>
                                                    <td>
                                                        <input @change="getRespuesta('educacion', monitoreo.pregunta29, $event)" type="radio" id="pregunta290" :value="0" v-model="monitoreo.pregunta29.score">
                                                        <label for="pregunta290">No muestra interés en su proceso de inscripción</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('educacion', monitoreo.pregunta29, $event)" type="radio" id="pregunta291" :value="1" v-model="monitoreo.pregunta29.score">
                                                        <label for="pregunta291">Requiere acompañamiento para inscribirse</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('educacion', monitoreo.pregunta29, $event)" type="radio" id="pregunta292" :value="2" v-model="monitoreo.pregunta29.score">
                                                        <label for="pregunta292">Tiene agencia, pero requiere de ayuda para finalizar el proceso de inscripción</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('educacion', monitoreo.pregunta29, $event)" type="radio" id="pregunta293" :value="3" v-model="monitoreo.pregunta29.score">
                                                        <label for="pregunta293">Se inscribe de manera autónoma y eficiente</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('educacion', monitoreo.pregunta29, $event)" type="radio" id="pregunta294" :value="'No observa'" v-model="monitoreo.pregunta29.score">
                                                        <label for="pregunta294">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta29.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta29.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">30</th>
                                                    <td>EDUCACIÓN</td>
                                                    <td>Participación</td>
                                                    <td>Responsabilidad</td>
                                                    <td>Gestión</td>
                                                    <td>
                                                        <input @change="getRespuesta('educacion', monitoreo.pregunta30, $event)" type="radio" id="pregunta300" :value="0" v-model="monitoreo.pregunta30.score">
                                                        <label for="pregunta300">No gestiona su aprendizaje ni realiza sus deberes</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('educacion', monitoreo.pregunta30, $event)" type="radio" id="pregunta301" :value="1" v-model="monitoreo.pregunta30.score">
                                                        <label for="pregunta301">Requiere acompañamiento para la gestión de su aprendizaje y/o realización de sus deberes</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('educacion', monitoreo.pregunta30, $event)" type="radio" id="pregunta302" :value="2" v-model="monitoreo.pregunta30.score">
                                                        <label for="pregunta302">Gestiona su aprendizaje y realiza sus deberes, pero no pide ayuda cuando la necesita</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('educacion', monitoreo.pregunta30, $event)" type="radio" id="pregunta303" :value="3" v-model="monitoreo.pregunta30.score">
                                                        <label for="pregunta303">Gestiona su aprendizaje y realiza sus deberes autónomamente y pide ayuda cuando la necesita</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('educacion', monitoreo.pregunta30, $event)" type="radio" id="pregunta304" :value="'No observa'" v-model="monitoreo.pregunta30.score">
                                                        <label for="pregunta304">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta30.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta30.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">31</th>
                                                    <td>EDUCACIÓN</td>
                                                    <td>Participación</td>
                                                    <td>Responsabilidad</td>
                                                    <td>Adherencia</td>
                                                    <td>
                                                        <input @change="getRespuesta('educacion', monitoreo.pregunta31, $event)" type="radio" id="pregunta310" :value="0" v-model="monitoreo.pregunta31.score">
                                                        <label for="pregunta310">No permanece en el centro de estudios</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('educacion', monitoreo.pregunta31, $event)" type="radio" id="pregunta311" :value="1" v-model="monitoreo.pregunta31.score">
                                                        <label for="pregunta311">Requiere acompañamiento para permanecer en el centro de estudios</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('educacion', monitoreo.pregunta31, $event)" type="radio" id="pregunta312" :value="2" v-model="monitoreo.pregunta31.score">
                                                        <label for="pregunta312">Permanece de manera inestable en el centro de estudios</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('educacion', monitoreo.pregunta31, $event)" type="radio" id="pregunta313" :value="3" v-model="monitoreo.pregunta31.score">
                                                        <label for="pregunta313">Permanece y finaliza sus estudios</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('educacion', monitoreo.pregunta31, $event)" type="radio" id="pregunta314" :value="'No observa'" v-model="monitoreo.pregunta31.score">
                                                        <label for="pregunta314">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta31.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta31.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">32</th>
                                                    <td>EDUCACIÓN</td>
                                                    <td>Participación</td>
                                                    <td>Responsabilidad</td>
                                                    <td>Asistencia</td>
                                                    <td>
                                                        <input @change="getRespuesta('educacion', monitoreo.pregunta32, $event)" type="radio" id="pregunta320" :value="0" v-model="monitoreo.pregunta32.score">
                                                        <label for="pregunta320">No asiste al centro de estudios</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('educacion', monitoreo.pregunta32, $event)" type="radio" id="pregunta321" :value="1" v-model="monitoreo.pregunta32.score">
                                                        <label for="pregunta321">Requiere acompañamiento para asistir al centro de estudios</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('educacion', monitoreo.pregunta32, $event)" type="radio" id="pregunta322" :value="2" v-model="monitoreo.pregunta32.score">
                                                        <label for="pregunta322">Asiste la mayoría del tiempo al centro de estudios</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('educacion', monitoreo.pregunta32, $event)" type="radio" id="pregunta323" :value="3" v-model="monitoreo.pregunta32.score">
                                                        <label for="pregunta323">Asiste al centro de estudios</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('educacion', monitoreo.pregunta32, $event)" type="radio" id="pregunta324" :value="'No observa'" v-model="monitoreo.pregunta32.score">
                                                        <label for="pregunta324">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta32.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta32.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">33</th>
                                                    <td>SALUD</td>
                                                    <td>Autocuidado</td>
                                                    <td>Hábitos y Prevención</td>
                                                    <td>Salud Física</td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta33, $event)" type="radio" id="pregunta330" :value="0" v-model="monitoreo.pregunta33.score">
                                                        <label for="pregunta330">No se preocupa ni se hace cargo de su salud física</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta33, $event)" type="radio" id="pregunta331" :value="1" v-model="monitoreo.pregunta33.score">
                                                        <label for="pregunta331">Requiere de acompañamiento para hacerse cargo efectivamente de su salud física</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta33, $event)" type="radio" id="pregunta332" :value="2" v-model="monitoreo.pregunta33.score">
                                                        <label for="pregunta332">Muestra preocupación, pero no realiza actividades para cuidar de su salud física</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta33, $event)" type="radio" id="pregunta333" :value="3" v-model="monitoreo.pregunta33.score">
                                                        <label for="pregunta333">Muestra preocupación y realiza actividades para cuidar de su salud física</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta33, $event)" type="radio" id="pregunta334" :value="'No observa'" v-model="monitoreo.pregunta33.score">
                                                        <label for="pregunta334">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta33.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta33.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">34</th>
                                                    <td>SALUD</td>
                                                    <td>Autocuidado</td>
                                                    <td>Hábitos y Prevención</td>
                                                    <td>Salud Mental</td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta34, $event)" type="radio" id="pregunta340" :value="0" v-model="monitoreo.pregunta34.score">
                                                        <label for="pregunta340">No se preocupa ni se hace cargo de su salud mental</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta34, $event)" type="radio" id="pregunta341" :value="1" v-model="monitoreo.pregunta34.score">
                                                        <label for="pregunta341">Requiere de acompañamiento para hacerse cargo efectivamente de su salud mental</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta34, $event)" type="radio" id="pregunta342" :value="2" v-model="monitoreo.pregunta34.score">
                                                        <label for="pregunta342">Muestra preocupación, pero no realiza actividades para cuidar de su salud mental</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta34, $event)" type="radio" id="pregunta343" :value="3" v-model="monitoreo.pregunta34.score">
                                                        <label for="pregunta343">Muestra preocupación y realiza actividades para cuidar de su salud mental</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta34, $event)" type="radio" id="pregunta344" :value="'No observa'" v-model="monitoreo.pregunta34.score">
                                                        <label for="pregunta344">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta34.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta34.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">35</th>
                                                    <td>SALUD</td>
                                                    <td>Autocuidado</td>
                                                    <td>Hábitos y Prevención</td>
                                                    <td>Ocio-Tiempo libre</td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta35, $event)" type="radio" id="pregunta350" :value="0" v-model="monitoreo.pregunta35.score">
                                                        <label for="pregunta350">No busca ni realiza  actividades recreativas</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta35, $event)" type="radio" id="pregunta351" :value="1" v-model="monitoreo.pregunta35.score">
                                                        <label for="pregunta351">Requiere de acompañamiento para buscar y/o realizar actividades recreativas</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta35, $event)" type="radio" id="pregunta352" :value="2" v-model="monitoreo.pregunta35.score">
                                                        <label for="pregunta352">Busca, pero no realiza actividades recreativas (viceversa)</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta35, $event)" type="radio" id="pregunta353" :value="3" v-model="monitoreo.pregunta35.score">
                                                        <label for="pregunta353">Busca y realiza actividades recreativas</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta35, $event)" type="radio" id="pregunta354" :value="'No observa'" v-model="monitoreo.pregunta35.score">
                                                        <label for="pregunta354">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta35.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta35.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">36</th>
                                                    <td>SALUD</td>
                                                    <td>Autocuidado</td>
                                                    <td>Hábitos y Prevención</td>
                                                    <td>Nutrición</td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta36, $event)" type="radio" id="pregunta360" :value="0" v-model="monitoreo.pregunta36.score">
                                                        <label for="pregunta360">Se rehusa a mejorar sus hábitos alimenticios</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta36, $event)" type="radio" id="pregunta361" :value="1" v-model="monitoreo.pregunta36.score">
                                                        <label for="pregunta361">Requiere de acompañamiento para mejorar sus hábitos alimenticios</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta36, $event)" type="radio" id="pregunta362" :value="2" v-model="monitoreo.pregunta36.score">
                                                        <label for="pregunta362">Es errático con sus hábitos alimenticios </label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta36, $event)" type="radio" id="pregunta363" :value="3" v-model="monitoreo.pregunta36.score">
                                                        <label for="pregunta363">Tiene buenos hábitos alimenticios</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta36, $event)" type="radio" id="pregunta364" :value="'No observa'" v-model="monitoreo.pregunta36.score">
                                                        <label for="pregunta364">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta36.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta36.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">37</th>
                                                    <td>SALUD</td>
                                                    <td>Autocuidado</td>
                                                    <td>Hábitos y Prevención</td>
                                                    <td>Descanso o sueño</td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta37, $event)" type="radio" id="pregunta370" :value="0" v-model="monitoreo.pregunta37.score">
                                                        <label for="pregunta370">Se rehusa a mejorar su ciclo sueño</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta37, $event)" type="radio" id="pregunta371" :value="1" v-model="monitoreo.pregunta37.score">
                                                        <label for="pregunta371">Requiere de acompañamiento para ordenar su ciclo de sueño</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta37, $event)" type="radio" id="pregunta372" :value="2" v-model="monitoreo.pregunta37.score">
                                                        <label for="pregunta372">Es errático con su ciclo de sueño</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta37, $event)" type="radio" id="pregunta373" :value="3" v-model="monitoreo.pregunta37.score">
                                                        <label for="pregunta373">Cuida su ciclo de sueño </label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta37, $event)" type="radio" id="pregunta374" :value="'No observa'" v-model="monitoreo.pregunta37.score">
                                                        <label for="pregunta374">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta37.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta37.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">38</th>
                                                    <td>SALUD</td>
                                                    <td>Autocuidado</td>
                                                    <td>Atención y Tratamientos</td>
                                                    <td>Detección</td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta38, $event)" type="radio" id="pregunta380" :value="0" v-model="monitoreo.pregunta38.score">
                                                        <label for="pregunta380">No reconoce cuando necesita atender su salud</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta38, $event)" type="radio" id="pregunta381" :value="1" v-model="monitoreo.pregunta38.score">
                                                        <label for="pregunta381">Requiere de acompañamiento para reconocer y atender su salud</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta38, $event)" type="radio" id="pregunta382" :value="2" v-model="monitoreo.pregunta38.score">
                                                        <label for="pregunta382">Reconoce cuando es necesario atender su salud, pero no se atiende</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta38, $event)" type="radio" id="pregunta383" :value="3" v-model="monitoreo.pregunta38.score">
                                                        <label for="pregunta383">Reconoce y se atiende en centro asistencial cuando es necesario</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta38, $event)" type="radio" id="pregunta384" :value="'No observa'" v-model="monitoreo.pregunta38.score">
                                                        <label for="pregunta384">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta38.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta38.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">39</th>
                                                    <td>SALUD</td>
                                                    <td>Autocuidado</td>
                                                    <td>Atención y Tratamientos</td>
                                                    <td>Controles de salud</td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta39, $event)" type="radio" id="pregunta390" :value="0" v-model="monitoreo.pregunta39.score">
                                                        <label for="pregunta390">No asiste a sus controles de salud</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta39, $event)" type="radio" id="pregunta391" :value="1" v-model="monitoreo.pregunta39.score">
                                                        <label for="pregunta391">Requiere de acompañamiento para asistir a sus controles de salud</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta39, $event)" type="radio" id="pregunta392" :value="2" v-model="monitoreo.pregunta39.score">
                                                        <label for="pregunta392">Asiste intermitentemente a sus controles de salud</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta39, $event)" type="radio" id="pregunta393" :value="3" v-model="monitoreo.pregunta39.score">
                                                        <label for="pregunta393">Asiste de manera autónoma y efectiva a sus controles de salud</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta39, $event)" type="radio" id="pregunta394" :value="'No observa'" v-model="monitoreo.pregunta39.score">
                                                        <label for="pregunta394">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta39.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta39.comment"></textarea>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"  style="min-width:5px">40</th>
                                                    <td>SALUD</td>
                                                    <td>Autocuidado</td>
                                                    <td>Atención y Tratamientos</td>
                                                    <td>Tratamientos y/o Medicamentos</td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta40, $event)" type="radio" id="pregunta400" :value="0" v-model="monitoreo.pregunta40.score">
                                                        <label for="pregunta400">Se rehúsa a administrar adecuadamente su tratamiento/medicación</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta40, $event)" type="radio" id="pregunta401" :value="1" v-model="monitoreo.pregunta40.score">
                                                        <label for="pregunta401">Requiere de acompañamiento para administrar su tratamiento/medicación</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta40, $event)" type="radio" id="pregunta402" :value="2" v-model="monitoreo.pregunta40.score">
                                                        <label for="pregunta402">Es errático con su tratamiento/medicación</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta40, $event)" type="radio" id="pregunta403" :value="3" v-model="monitoreo.pregunta40.score">
                                                        <label for="pregunta403">Administra de manera responsable y efectiva su tratamiento/medicación.</label>
                                                    </td>
                                                    <td>
                                                        <input @change="getRespuesta('salud', monitoreo.pregunta40, $event)" type="radio" id="pregunta404" :value="'No observa'" v-model="monitoreo.pregunta40.score">
                                                        <label for="pregunta404">No se observa</label>
                                                    </td>
                                                    <td>
                                                        <label for="4" >{{this.monitoreo.pregunta40.score}}</label>
                                                    </td>
                                                    <td>
                                                        <label>Observaciones</label>
                                                        <textarea class="form-control" v-model="monitoreo.pregunta40.comment"></textarea>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                </div>
                                <div class="modal-footer">
                                   
                                    <div class="card">
                                        <div class="card-body bg-primary text-white">
                                            Promedios:
                                            <ul>
                                                <li> Domestico: {{this.promDomestico}}</li>
                                                <li> Movilización: {{this.promMovilizacion}}</li>
                                                <li> Manejo de Dinero: {{this.promManejoDinero}}</li>
                                                <li> Redes y servicios: {{this.promRedesServicios}}</li>
                                                <li> Empleo: {{this.promEmpleo}}</li>
                                                <li> Educación: {{this.promEducacion}}</li>
                                                <li> Salud: {{this.promSalud}}</li>
                                            </ul>
                                            <label>Promedio Total: {{this.promTotal}}</label>
                                        </div>
                                    </div>
                                    <button
                                        class="btn btn-danger"
                                        @click="cancelarModal()"
                                    >
                                        <i class="fa fa-times"></i>
                                        Cancelar
                                    </button>
                                    <button v-if="!editing"
                                        class="btn btn-success"
                                        @click="createMonitoreo"
                                    >
                                        <i class="fa fa-check"></i>
                                        Crear
                                    </button>
                                    <button
                                        class="btn btn-warning text-white"
                                        @click="editMonitoreo"
                                        v-if="editing"
                                    >
                                        <i class="fa fa-pencil"></i>
                                        Editar
                                    </button>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h1>Monitoreo</h1>
                    <hr>
                    <div class="form-group text-right">
                        <button
                            class="btn btn-rosa"
                            @click="showModal = true"
                        >
                            <i class="fa fa-plus"></i>
                            Crear Monitoreo
                        </button>
                    </div>
                    <div class="form-group">
                        <vue-good-table
                            compactMode
                            :columns="columns"
                            :rows="instituciones" 
                            :search-options="{
                                enabled: true,
                                placeholder: 'Buscar en la tabla',
                            }" 
                            :pagination-options="{
                                enabled: true,
                                mode: 'records',
                                perPage: 5,
                                perPageDropdown: [3, 7, 9],
                                dropdownAllowAll: true,
                                setCurrentPage: 1,
                                nextLabel: 'siguiente',
                                prevLabel: 'anterior',
                                rowsPerPageLabel: 'Filas por página',
                                ofLabel: 'de',
                                pageLabel: 'página', // for 'pages' mode
                                allLabel: 'Todos',
                            }">
                              <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field == 'participantes'">
                                    <ul>
                                        <li :key="item.id" v-for="item in props.row.users">
                                            {{ item.name }}
                                        </li>
                                    </ul>
                                </span>
                                <span v-if="props.column.field == 'actions'">
                                    <div class="btn-group">
                                        <button class="btn btn-warning" @click="editarMonitoreo(props.row)">
                                            <i class="fa fa-pencil"></i>
                                        </button>
                                        <button class="btn btn-danger" @click="eliminarInstitucion(props.row.id)">
                                            <i class="fa fa-trash"></i>
                                        </button>
                                    </div>
                                </span>
                                <span v-else>
                                {{props.formattedRow[props.column.field]}}
                                </span>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import Swal from 'sweetalert2';
export default {
    computed: {},
    props: [
        "propsUsuarios", "propsDimensiones", "propsJovenes"
    ],
    created() {
        this.jovenes = JSON.parse(this.propsJovenes);
        this.usuarios = JSON.parse(this.propsUsuarios);
        this.getMonitoreos();
    },
    data() {
        return {
            //variables para calcular los promedios por cada dimension
            promDomestico: "",
            promMovilizacion: "",
            promManejoDinero: "",
            promRedesServicios: "",
            promEmpleo: "",
            promEducacion: "",
            promSalud: "",
            promTotal: "",
            //estados para los formularios y tablas
            jovenes: [],
            usuarios: [],
            users: [],
            id: null,
            date: "",
            nnaj_id: "",
            monitoreo:{
                pregunta1: {
                    id: null,
                    order: 1,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta2: {
                    id: null,
                    order: 2,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta3: {
                    id: null,
                    order: 3,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta4: {
                    id: null,
                    order: 4,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta5: {
                    id: null,
                    order: 5,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta6: {
                    id: null,
                    order: 6,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta7: {
                    id: null,
                    order: 7,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta8: {
                    id: null,
                    order: 8,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta9: {
                    id: null,
                    order: 9,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta10: {
                    id: null,
                    order: 10,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta11: {
                    id: null,
                    order: 11,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta12: {
                    id: null,
                    order: 12,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta13: {
                    id: null,
                    order: 13,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta14: {
                    id: null,
                    order: 14,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta15: {
                    id: null,
                    order: 15,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta16: {
                    id: null,
                    order: 16,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta17: {
                    id: null,
                    order: 17,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta18: {
                    id: null,
                    order: 18,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta19: {
                    id: null,
                    order: 19,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta20: {
                    id: null,
                    order: 20,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta21: {
                    id: null,
                    order: 21,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta22: {
                    id: null,
                    order: 22,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta23: {
                    id: null,
                    order: 23,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta24: {
                    id: null,
                    order: 24,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta25: {
                    id: null,
                    order: 25,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta26: {
                    id: null,
                    order: 26,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta27: {
                    id: null,
                    order: 27,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta28: {
                    id: null,
                    order: 28,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta29: {
                    id: null,
                    order: 29,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta30: {
                    id: null,
                    order: 30,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta31: {
                    id: null,
                    order: 31,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta32: {
                    id: null,
                    order: 32,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta33: {
                    id: null,
                    order: 33,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta34: {
                    id: null,
                    order: 34,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta35: {
                    id: null,
                    order: 35,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta36: {
                    id: null,
                    order: 36,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta37: {
                    id: null,
                    order: 37,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta38: {
                    id: null,
                    order: 38,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta39: {
                    id: null,
                    order: 39,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
                pregunta40: {
                    id: null,
                    order: 40,
                    score: "",
                    label: "",
                    comment: "",
                    categoria: "",
                    dimension: "",
                    subdimension: "",
                    noobserva: false
                },
            },
            errors: [],
            editing: false,
            institucion: {
                id: "",
                full_name: "",
                short_name: "",
            },
            showModal: false,
            instituciones: [],
            //vue good table
            columns: [
                {
                    label: 'Fecha',
                    field: 'date',
                    type: 'string'
                },
                {
                    label: 'Joven',
                    field: 'joven.full_name',
                    type: 'string',
                },
                {
                    label: "Actores",
                    field: "participantes",
                    tdClass: "text-center",
                    thClass: "text-center",
                    sortable: false
                },
                {
                    label: 'Acciones',
                    field: 'actions',
                    tdClass: 'text-center text-nowrap',
                    thClass: 'text-center text-nowrap',
                    sortable: false,
                }
            ],
        };
    },
    methods: {
        //funcion que cada vez que se responde recalcula el promedio y va seteando la pregunta clickeada
        getRespuesta(dimension ,pregunta, respuesta){
            this.promDomestico = "No observa";
            this.promMovilizacion = 'No observa';
            this.promManejoDinero = 'No observa';
            this.promRedesServicios = 'No observa';
            this.promEmpleo = 'No observa';
            this.promEducacion = 'No observa';
            this.promSalud = 'No observa';
            this.promTotal = 'No observa';
            //obtiene el label del html para no pasarlo por parametro ya que seria mucho codigo.
            const labelPregunta = respuesta.target.labels[0].innerText;
            if(pregunta.score == 'No observa'){
                pregunta.noobserva = true;
            }else{
                pregunta.noobserva = false;
            }
            pregunta.label = labelPregunta;
            pregunta.dimension = dimension;
            //domestico
            let sumDomestico = 0;
            let countDomestico = 0;
            //movilizacion
            let sumMovilizacion = 0;
            let countMovilizacion = 0;
            //dinero
            let sumManejoDinero = 0;
            let countManejoDinero = 0;
            //redes servicios
            let sumRedesServicios = 0;
            let countRedesServicios = 0;
            //empleo
            let sumEmpleo = 0;
            let countEmpleo = 0;
            //educacion
            let sumEducacion = 0;
            let countEducacion = 0;
            //salud
            let sumSalud = 0;
            let countSalud = 0;
            Object.keys(this.monitoreo).map( key => {
                
                if(this.monitoreo[key].dimension == 'domestico' && this.monitoreo[key].score != 'No observa'){
                    console.log("en domestico:...", this.monitoreo[key].score);
                    countDomestico++;
                    sumDomestico = sumDomestico + this.monitoreo[key].score;
                }
                if(this.monitoreo[key].dimension == 'movilizacion' && this.monitoreo[key].score != 'No observa'){
                    countMovilizacion++;
                    sumMovilizacion = sumMovilizacion + this.monitoreo[key].score;
                }
                if(this.monitoreo[key].dimension == 'manejo_dinero' && this.monitoreo[key].score != 'No observa'){
                    countManejoDinero++;
                    sumManejoDinero = sumManejoDinero + this.monitoreo[key].score;
                }
                if(this.monitoreo[key].dimension == 'redes_servicios' && this.monitoreo[key].score != 'No observa'){
                    countRedesServicios++;
                    sumRedesServicios = sumRedesServicios + this.monitoreo[key].score;
                }
                if(this.monitoreo[key].dimension == 'empleo' && this.monitoreo[key].score != 'No observa'){
                    countEmpleo++;
                    sumEmpleo = sumEmpleo + this.monitoreo[key].score;
                }
                if(this.monitoreo[key].dimension == 'educacion' && this.monitoreo[key].score != 'No observa'){
                    countEducacion++;
                    sumEducacion = sumEducacion + this.monitoreo[key].score;
                }
                if(this.monitoreo[key].dimension == 'salud' && this.monitoreo[key].score != 'No observa'){
                    console.log("en salud:...", this.monitoreo[key].score);
                    countSalud++;
                    sumSalud = sumSalud + this.monitoreo[key].score;
                }
            });
            this.promDomestico = isNaN(sumDomestico/countDomestico)? 'No observa': sumDomestico/countDomestico;
            this.promMovilizacion = isNaN(sumMovilizacion/countMovilizacion)? 'No observa': sumMovilizacion/countMovilizacion;
            this.promManejoDinero = isNaN(sumManejoDinero/countManejoDinero)? 'No observa': sumManejoDinero/countManejoDinero;
            this.promRedesServicios = isNaN(sumRedesServicios/countRedesServicios)? 'No observa': sumRedesServicios/countRedesServicios;
            this.promEmpleo = isNaN(sumEmpleo/countEmpleo)? 'No observa' : sumEmpleo/countEmpleo;
            this.promEducacion = isNaN(sumEducacion/countEducacion)? 'No observa': sumEducacion/countEducacion;
            this.promSalud = isNaN(sumSalud/countSalud)? 'No observa' : sumSalud/countSalud;
            console.log("el prom salud es: ", this.promSalud);
            console.log("el sumSalud salud es: ", countSalud);
            console.log("el countSalud salud es: ", sumSalud);
            this.calculaPromTotal(); 
        },
        calculaPromTotal(){
            let countValidProm = 0;
            let sumaPromediosTotales= 0;
            if(this.promDomestico != 'No observa'){
                countValidProm = countValidProm + 1;
                sumaPromediosTotales = sumaPromediosTotales +  this.promDomestico;
            }
            if(this.promMovilizacion != 'No observa'){
                countValidProm = countValidProm + 1;
                sumaPromediosTotales = sumaPromediosTotales +  this.promMovilizacion;
            }
            if(this.promManejoDinero != 'No observa'){
                countValidProm = countValidProm + 1;
                sumaPromediosTotales = sumaPromediosTotales +  this.promManejoDinero;
            }
            if(this.promRedesServicios != 'No observa'){
                countValidProm = countValidProm + 1;
                sumaPromediosTotales = sumaPromediosTotales +  this.promRedesServicios;
            }
            if(this.promEmpleo != 'No observa'){
                countValidProm = countValidProm + 1;
                sumaPromediosTotales = sumaPromediosTotales +  this.promEmpleo;
            }
            if(this.promEducacion != 'No observa'){
                countValidProm = countValidProm + 1;
                sumaPromediosTotales = sumaPromediosTotales +  this.promEducacion;
            }
            if(this.promSalud != 'No observa'){
                countValidProm = countValidProm + 1;
                sumaPromediosTotales = sumaPromediosTotales +  this.promSalud;
            }
            if(countValidProm == 0){
                this.promTotal = 'No observa';
            }else{
                this.promTotal = sumaPromediosTotales/countValidProm;
            }
            

        },
        cancelarModal(){
            this.limpiarFormulario();
            this.showModal = false;
            this.editing = false;
        },
        limpiarFormulario(){
            Object.keys(this.monitoreo).map( key => {
                this.monitoreo[key].id = null;
                this.monitoreo[key].label = "";
                this.monitoreo[key].score = "";
                this.monitoreo[key].dimension = "";
                this.monitoreo[key].comment = "";
            });
            this.errors = [];
            this.id = null;
            this.users = [];
            this.nnaj_id = "";
            this.date = "";
            this.promDomestico = "No observa";
            this.promMovilizacion = 'No observa';
            this.promManejoDinero = 'No observa';
            this.promRedesServicios = 'No observa';
            this.promEmpleo = 'No observa';
            this.promEducacion = 'No observa';
            this.promSalud = 'No observa';
            this.promTotal = 'No observa';
        },
        getMonitoreos() {
            axios
                .get("/getMonitoreos")
                .then(response => {
                    console.log("la response es: ", response.data);
                    this.instituciones = response.data.monitoreos;
                })
                .catch(function(error) {
                    // handle error
                    console.log("ocurrio un error: ", error);
                });
        },
        checkForm: function (e) {
            console.log("el evento a validar es: ", e);
            e.preventDefault();
            let errors = [];
            let countMissingAnswers = 0;
            Object.keys(this.monitoreo).map( key => {
                if(this.monitoreo[key].score === ""){
                    countMissingAnswers++;
                    errors.push(`Debe completar la pregunta ${key}`);
                }
            });
            console.log("el contador total es: ", countMissingAnswers);
            if(countMissingAnswers > 0 ){
                errors.push("Debe completar todas las preguntas.");
            }
            if(!this.date){
                errors.push("Debe ingresar una fecha.");
            }
            if(!this.nnaj_id){
                errors.push("Debe seleccionar un joven.");
            }
            if(this.users.length === 0){
                errors.push("Debe al menos un acompañante.");
            }
            if(errors.length > 0 ){
                this.errors = errors;
                return false;
            }else{
                return true;
            }
        },
        createMonitoreo(e) {
            const validatedForm = this.checkForm(e);
            if(validatedForm){
                let data = {
                    date: this.date,
                    nnaj_id: this.nnaj_id.id,
                    monitoreo: this.monitoreo,
                    users: this.users,
                    prom_domestico: this.promDomestico,
                    prom_movilizacion: this.promMovilizacion,
                    prom_manejo_dinero: this.promManejoDinero,
                    prom_redes_servicios: this.promRedesServicios,
                    prom_empleo: this.promEmpleo,
                    prom_educacion: this.promEducacion,
                    prom_salud: this.promSalud,
                    prom_total: this.promTotal
                };
                console.log(
                    "la data al agregar el monitoreo es: ",
                    data
                );
                let me = this;
                axios
                    .post("/create_monitoreo", data, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                    .then(function(response) {
                        console.log(
                            "la repsuesta al agregar el monitoreo es: ",
                            response
                        );
                        Swal.fire('Monitoreo creado!', '', 'success');
                        me.cancelarModal();
                        me.getMonitoreos();
                
                    })
                    .catch(function(error) {
                        console.log("ocurrio un error es: ", error);
                        console.log("el error al agregar monitoreo es: ", error.response);
                    });
            }else{
                //sube el scroll hasta donde se ven los errores, notar la referencia en el html
                this.$nextTick(() => {
                    this.$refs.errorsForm.scrollTop = 0;
                });
            }
        },
        editMonitoreo(e) {
            const validatedForm = this.checkForm(e);
            if(validatedForm){
                let data = {
                    id: this.id,
                    date: this.date,
                    nnaj_id: this.nnaj_id.id,
                    monitoreo: this.monitoreo,
                    users: this.users,
                    prom_domestico: this.promDomestico,
                    prom_movilizacion: this.promMovilizacion,
                    prom_manejo_dinero: this.promManejoDinero,
                    prom_redes_servicios: this.promRedesServicios,
                    prom_empleo: this.promEmpleo,
                    prom_educacion: this.promEducacion,
                    prom_salud: this.promSalud,
                    prom_total: this.promTotal
                };
                console.log(
                    "la data al editar el monitoreo es: ",
                    data
                );
                let me = this;
                axios
                    .post("/edit_monitoreo", data, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                    .then(function(response) {
                        console.log(
                            "la repsuesta al editar el monitoreo es: ",
                            response
                        );
                        Swal.fire('Monitoreo editado!', '', 'success');
                        me.cancelarModal();
                        me.getMonitoreos();
                
                    })
                    .catch(function(error) {
                        console.log("ocurrio un error es: ", error);
                        console.log("el error al agregar monitoreo es: ", error.response);
                    });
            }else{
                //sube el scroll hasta donde se ven los errores, notar la referencia en el html
                this.$nextTick(() => {
                    this.$refs.errorsForm.scrollTop = 0;
                });
            }
        },
        editarMonitoreo(monitoreo){
            let respuestas = monitoreo.preguntas;
            let preguntas = this.monitoreo;
            this.date = monitoreo.date;
            this.nnaj_id = monitoreo.joven;
            this.users = monitoreo.users;
            this.id = monitoreo.id;
            this.dimension = monitoreo.dimension;
            this.promDomestico = monitoreo.prom_domestico;
            this.promMovilizacion = monitoreo.prom_movilizacion;
            this.promManejoDinero = monitoreo.prom_manejo_dinero;
            this.promRedesServicios = monitoreo.prom_redes_servicios;
            this.promEmpleo = monitoreo.prom_empleo;
            this.promEducacion = monitoreo.prom_educacion;
            this.promSalud = monitoreo.prom_salud;
            this.promTotal = monitoreo.prom_total;
            
            for (let index = 0; index < 40; index++) {
                let respuesta = respuestas[index];
                let key = `pregunta${respuesta.question_id}`
                let pregunta = preguntas[key];
                pregunta.id = respuesta.id;
                pregunta.score = respuesta.score == null ? "No observa" : respuesta.score;
                pregunta.comment = respuesta.comment;
                pregunta.dimension = respuesta.dimension;
                console.log("LA DIMENSION ES: ", pregunta.dimension);
            }
            this.editing = true;
            this.showModal = true;
        },
        eliminarInstitucion(id){
            let me = this;
            Swal.fire({
                title: '¿Esta seguro de querer borrar el monitoreo?',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: `Eliminar`,
                cancelButtonText:'Cancelar',
                }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    axios.delete(`/delete_monitoreo/${id}`, {
                            headers: {
                                "Content-Type": "application/json"
                            }
                        })
                        .then(function(response) {
                            console.log(
                                "la repsuesta al eliminar el monitoreo es: ",
                                response
                            );
                             Swal.fire('Monitoreo eliminado!', '', 'success')
                            me.getMonitoreos();
                    
                        })
                        .catch(function(error) {
                            console.log("FAILURE!!", error);
                        });
                   
                } else if (result.isDenied) {
                    Swal.fire('Changes are not saved', '', 'info')
                }
            })
        },
    }
};
</script>
