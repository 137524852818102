<template>
    <div class="row justify-content-center">
        <div class="col-10">
            <div class="form-group text-center">
                <button class="btn btn-success" title="Agregar accion al objetivo" 
                    @click="crear=!crear">
                    <i :class="crear==false? 'fa fa-plus': 'fa fa-arrow-up'"></i>
                </button>
            </div>
            <div class="form-group"  v-if="acciones.length==0">
                <div class="card">
                    <div class="card-body bg-danger text-white">
                        Objetivo sin acciones
                    </div>
                </div>
            </div>
            <div class="form-group"  v-if="acciones.length>0">
                <div class="card">
                    <div class="card-body">
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <td>Accion</td>
                                    <td>Acompañamiento</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr :key="item.id" v-for="item in acciones">
                                    <td>{{ item.action }}</td>
                                    <td>{{ item.accompainment }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="form-group" v-if="crear">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group" v-if="errors.length>0" >
                            <div class="card">
                                <div class="card-body bg-danger text-white">
                                    Errores al llenar los datos
                                    <ul>
                                        <li :key="error" v-for="error in errors">{{ error }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Accion:</label>
                            <input type="text" class="form-control" v-model="accion.accion">
                        </div>
                         <div class="form-group">
                            <label>Acompañamiento:</label>
                            <input type="text" class="form-control" v-model="accion.acompanamiento">
                        </div>
                        <div class="form-group">
                            <button class="btn btn-success" @click="createAccion">
                                <i class="fa fa-check"></i>
                                Crear accion
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import Swal from 'sweetalert2';
import 'vue-select/dist/vue-select.css';
export default {
    props:['propsUsuarios', 'propsDimensiones', 'propsObjetivo'],
    computed: {},
    created() {
        this.objetivo = this.propsObjetivo;
        this.acciones = this.objetivo.acciones;
        console.log("el objetivo heredado es: ", this.objetivo);
    },
    mounted(){
        
    },
    data() {
        return {
            crear: false,
            accion:{
                objetivo_id: "",
                accion:"",
                acompanamiento: ""
            },
            errors: [],
            acciones: [],
            //estados globales
            editing: false,
            //props
            usuarios: [],
            dimensiones: [],
            jovenes: [],
            //array para selects y mostrar datos
            users: [],
            casos: [],
            objetivo: "",

            //modelo de caso para crear/editar/ver
            caso: {
                id: "",
                relato: "",
                hecho: "",
                necesidad: "",
                interes: "",
                emocion: "",
                posicion: "",
                actores: "",
                conflicto: "",
                keyword: "",
                pregunta: "",
                estrategia: "",
                nnaj_id: "",
                users: [],
                errors: []
            },
            //boleanos
            showRelato: false,
            showHecho: false,
            showNecesidad: false,
            showInteres: false,
            showEmocion: false,
            showPosicion: false,
            showActores: false,
            showConflicto: false,
            showKeyword: false,
            showPregunta: false,
            showEstrategia: false,
            showModal: false,
            showModalView: false,
            //vue good table
            columns: [
                {
                    label: 'Fecha',
                    field: 'date',
                    dateInputFormat: 'yyyy-MM-dd',
                    dateOutputFormat: 'MMM do yy',
                },
                {
                    label: 'Joven',
                    field: 'joven.nombres',
                    type: 'string',
                },
                {
                    label: 'Actores',
                    field: 'participantes',
                    tdClass: 'text-center',
                    thClass: 'text-center',
                    sortable: false,
                },
                {
                    label: 'Creado en',
                    field: 'created_at',
                    formatFn: this.formatDate,
                },
                {
                    label: 'Acciones',
                    field: 'actions',
                    tdClass: 'text-center text-nowrap',
                    thClass: 'text-center text-nowrap',
                    sortable: false,
                }
            ],
        };
    },
    methods: {
        formatDate(date){
            var date = new Date(date);
            return date.toLocaleString();
        },
        checkForm: function (e) {
            console.log("el evento a validar es: ", e);
            e.preventDefault();
            let errors = [];
            console.log("el caso es: ", this.accion);
            if (!this.accion.accion) {
                errors.push('Debe ingresar una accion.');
            }
            if (!this.accion.acompanamiento) {
                errors.push('Debe ingresar el acompañamiento.');
            }
            if(errors.length > 0 ){
                this.errors = errors;
                return false;
            }else{
                return true;
            }
        },
        async getCasos(id) {
            console.log("elID DEL OBJETIVO ES: ", id);
            axios
                .get(`/getAcciones/${id}`)
                .then(response => {
                    console.log("la response es: ", response.data);
                    this.acciones = response.data.acciones;
                })
                .catch(function(error) {
                    // handle error
                    console.log("ocurrio un error: ", error);
                });
        },
        async createAccion(e) {
            const validatedForm = this.checkForm(e);
            if (validatedForm){
            let data = {
                accion: this.accion.accion,
                acompanamiento: this.accion.acompanamiento,
                objetivo_id: this.objetivo.id
            };
            console.log(
                "la data al agregar la accion de objetivo es: ",
                data
            );
            let me = this;
            axios
                .post("/create_accion", data, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then(function(response) {
                    console.log(
                        "la repsuesta al agregar el caso es: ",
                        response
                    );
                    Swal.fire('Caso creado!', '', 'success')
                    me.crear = false;
                    //me.limpiarFormulario();
                    me.getCasos(me.objetivo.id);
               
                })
                .catch(function(error) {
                    console.log("FAILURE!!", error);
                });
            }else{
            
                this.$nextTick(() => {
                    this.$refs.errorsForm.scrollTop = 0;
                });
             
            }
        },
        async editarAnalisis(e) {
            const validatedForm = this.checkForm(e);
            if(validatedForm){
                let data = {
                    id : this.caso.id,
                    date: this.caso.date,
                    nnaj_id: this.caso.nnaj_id.id,
                    users: this.caso.users,
                    relato: this.showRelato ? this.caso.relato : null,
                    hecho:  this.showHecho ? this.caso.hecho : null,
                    necesidad: this.showNecesidad ? this.caso.necesidad : null,
                    interes: this.showInteres ? this.caso.interes : null,
                    emocion: this.showEmocion ? this.caso.emocion : null,
                    posicion: this.showPosicion ? this.caso.posicion : null,
                    actores: this.showActores ? this.caso.actores : null,
                    conflicto: this.showConflicto ? this.caso.conflicto : null,
                    keyword: this.showKeyword ? this.caso.keyword : null,
                    pregunta: this.showPregunta ? this.caso.pregunta : null,
                    estrategia: this.showEstrategia ? this.caso.estrategia : null,
                };
                console.log(
                    "la data al editar el analisis de caso es: ",
                    data
                );
                let me = this;
                axios
                    .post("/edit_caso", data, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                    .then(function(response) {
                        console.log(
                            "la repsuesta al editar el caso es: ",
                            response
                        );
                        Swal.fire('Caso editado!', '', 'success')
                        me.cancelarModal();
                        me.getCasos();
                        
                
                    })
                    .catch(function(error) {
                        console.log("FAILURE!!", error);
                    });
            }else{
                this.$nextTick(() => {
                    this.$refs.errorsForm.scrollTop = 0;
                });
            }
       
        },
        verCaso(caso){
            console.log("el caso es: ",caso),
            this.caso = caso;
            this.showModalView = true;
        },
        editarCaso(caso){
            console.log("el caso a editar es: xf ",caso);
            this.caso = caso;
            this.caso.nnaj_id = caso.joven;
            this.editing = true;
            this.checkearEdicion(caso);
            this.showModal = true;
        },
        checkearEdicion(caso){
            this.showRelato = caso.relato ? true : false;
            this.showHecho = caso.hecho ? true : false;
            this.showNecesidad = caso.necesidad ? true : false;
            this.showInteres = caso.interes ? true : false;
            this.showEmocion = caso.emocion ? true : false;
            this.showPosicion = caso.posicion ? true : false;
            this.showActores = caso.actores ? true : false;
            this.showConflicto = caso.conflicto ? true : false;
            this.showKeyword = caso.keyword ? true : false;
            this.showPregunta = caso.pregunta ? true : false;
            this.showEstrategia = caso.estrategia ? true : false;
        },
        cancelarModal(){
            this.limpiarFormulario();
            this.showModal = false;
            this.showModalView = false;
            this.editing = false;
        },
        limpiarFormulario(){
            this.errors = [];
            this.showEstrategia = false;
            this.showPosicion = false;
            this.showActores = false;
            this.showPregunta = false;
            this.showNecesidad = false;
            this.showHecho = false;
            this.showEmocion = false;
            this.showConflicto = false;
            this.showKeyword = false;
            this.showInteres = false;
            this.showRelato = false;
            this.caso = {
                user_id: "",
                date: "",
                nnaj_id: "",
                dimension_id: "",
                comment: "",
                users: "",
                relato: "",
                hecho: "",
                necesidad: "",
                interes: "",
                emocion: "",
                posicion: "",
                actores: "",
                conflicto: "",
                keyword: "",
                pregunta: "",
                estrategia: "",
                users: [],
                errors: []
            };
        },
        async eliminarCaso(id){
            let me = this;
            Swal.fire({
                title: '¿Esta seguro de querer borrar el caso?',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: `Eliminar`,
                cancelButtonText:'Cancelar',
                }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    axios.delete(`/delete_caso/${id}`, {
                            headers: {
                                "Content-Type": "application/json"
                            }
                        })
                        .then(function(response) {
                            console.log(
                                "la repsuesta al eliminar el institucion es: ",
                                response
                            );
                             Swal.fire('Caso eliminado!', '', 'success');
                            me.getCasos();
                        })
                        .catch(function(error) {
                            console.log("FAILURE!!", error);
                        });
                   
                } else if (result.isDenied) {
                    Swal.fire('Changes are not saved', '', 'info');
                }
            });
        },
        async generarPdf(id){
            
            Swal.fire({
                title: 'Estamos generando tu reporte, espera unos segundos',
                text: 'El reporte PDF simplemente se desrgara en su equipo...',
                iconHtml: "<i class='fas fa-circle-notch fa-spin'></i>",
                timer: 5000,
                timerProgressBar: true,
                showConfirmButton: false,
                didOpen: () => {
                    window.location.href = `/generate-pdf-from-view/${id}`;
                }
            }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                    console.log('I was closed by the timer')
                }
            });
        },
    }
};
</script>
