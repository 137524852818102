<template>
    <div class="row">
        <div v-if="showModal">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title text-info">
                                        Crear Acompañamiento
                                    </h4>
                                    <button
                                        type="button"
                                        class="close"
                                        @click="showModal = false"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div
                                    class="modal-body"
                                    id="errorsForm"
                                    ref="errorsForm"
                                >
                                    <div
                                        class="form-group"
                                        v-if="errors.length > 0"
                                    >
                                        <div class="card">
                                            <div
                                                class="card-body bg-danger text-white"
                                            >
                                                Errores al llenar los datos
                                                <ul>
                                                    <li
                                                        :key="error"
                                                        v-for="error in errors"
                                                    >
                                                        {{ error }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Usuarios:</label>
                                        <v-select
                                            v-model="acompanamiento.users"
                                            multiple
                                            label="name"
                                            :options="usuarios"
                                        ></v-select>
                                    </div>
                                    <div class="form-group">
                                        <label>Dimension:</label>
                                        <v-select
                                            v-model="
                                                acompanamiento.dimension
                                            "
                                            @input="getSubDimensions($event)"
                                            label="name"
                                            :options="dimensiones"
                                        ></v-select>
                                    </div>

                                    <div
                                        class="form-group"
                                        v-if="subdimensiones.length > 0"
                                    >
                                        <label>Subdimension:</label>
                                        <v-select
                                            v-model="
                                                acompanamiento.sub_dimension
                                            "
                                            @input="getSubSubDimensions($event)"
                                            label="name"
                                            :options="subdimensiones"
                                        ></v-select>
                                    </div>

                                    <div
                                        class="form-group"
                                        v-if="subsubdimensiones.length > 0"
                                    >
                                        <label>Subsubdimension:</label>
                                        <v-select
                                            v-model="
                                                acompanamiento.sub_sub_dimension
                                            "
                                            label="name"
                                            :options="subsubdimensiones"
                                        ></v-select>
                                    </div>

                                    <div class="form-group">
                                        <label>Estado:</label>
                                        <select
                                            type="text"
                                            class="form-control"
                                            v-model="
                                                acompanamiento.state_progress
                                            "
                                        >
                                            <option value=""
                                                >Seleccione opcion</option
                                            >
                                            <option value="En proceso"
                                                >En proceso</option
                                            >
                                            <option value="Logrado"
                                                >Logrado</option
                                            >
                                            <option value="Logrado"
                                                >No aplica</option
                                            >
                                        </select>
                                    </div>

                                    <div class="form-group">
                                        <label>Fecha</label>
                                        <input
                                            type="date"
                                            class="form-control"
                                            v-model="acompanamiento.date"
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label>Relato</label>
                                        <vue-editor v-model="acompanamiento.comment"></vue-editor>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button
                                        class="btn btn-danger"
                                        @click="cancelarModal()"
                                    >
                                        <i class="fa fa-times"></i>
                                        Cancelar
                                    </button>
                                    <button
                                        class="btn btn-success"
                                        @click="createAcompanamiento"
                                        v-if="editing==false"
                                    >
                                        <i class="fa fa-check"></i>
                                        Crear Acompanamiento
                                    </button>
          
                                    <button
                                        class="btn btn-warning"
                                        @click="editAcompanamiento"
                                        v-if="editing==true"
                                    >
                                        <i class="fa fa-pencil"></i>
                                        Editar Acompanamiento
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <div v-if="showModalReporte">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title text-info">
                                        Generar Reporte
                                    </h4>
                                    <button
                                        type="button"
                                        class="close"
                                        @click="cancelarModal()"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div
                                    class="modal-body"
                                    id="errorsForm"
                                    ref="errorsForm"
                                >
                                    <div
                                        class="form-group"
                                        v-if="errorsReporte.length > 0"
                                    >
                                        <div class="card">
                                            <div
                                                class="card-body bg-danger text-white"
                                            >
                                                Errores al llenar los datos
                                                <ul>
                                                    <li
                                                        :key="error"
                                                        v-for="error in errorsReporte"
                                                    >
                                                        {{ error }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="card">
                                            <div
                                                class="card-body bg-primary text-white"
                                            >
                                                Debes seleccionar un rango de
                                                fechas donde la fecha "Desde"
                                                debe ser mayor a la fecha
                                                "Hasta".
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Desde:</label>
                                        <input
                                            type="date"
                                            class="form-control"
                                            v-model="reporte.desde"
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label>Hasta:</label>
                                        <input
                                            type="date"
                                            class="form-control"
                                            v-model="reporte.hasta"
                                        />
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button
                                        class="btn btn-danger"
                                        @click="cancelarModal()"
                                    >
                                        <i class="fa fa-times"></i>
                                        Cancelar
                                    </button>
                                    <button
                                        class="btn btn-success"
                                        @click="generarReporte"
                                    >
                                        <i class="fa fa-check"></i>
                                        Generar reporte
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <div v-if="showModalView">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title text-info">
                                        Acompañamiento {{ caso.id }}
                                    </h4>
                                    <button
                                        type="button"
                                        class="close"
                                        @click="showModalView = false"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="form-group">
                                        <label class="text-primary">Usuarios:</label>
                                        <ul>
                                            <li
                                                :key="user.id"
                                                v-for="user in caso.users"
                                            >
                                                {{ user.name }}
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="form-group">
                                        <label class="text-primary">Joven:</label>
                                        {{ caso.joven.nombres }}
                                        {{ caso.joven.apellido_paterno }}
                                        {{ caso.joven.apellido_materno }}
                                    </div>

                                    <div class="form-group">
                                        <label class="text-primary">Fecha:</label>
                                        {{ caso.date }}
                                    </div>

                                    <div class="form-group">
                                        <label class="text-primary">Estado:</label>
                                        {{ caso.state_progress }}
                                    </div>

                                    <div
                                        class="form-group"
                                        v-if="caso.dimension"
                                    >
                                        <label class="text-primary">Dimension:</label>
                                        {{ caso.dimension.name }}
                                    </div>

                                    <div
                                        class="form-group"
                                        v-if="caso.sub_dimension"
                                    >
                                        <label class="text-primary">Subdimension:</label>
                                        {{ caso.sub_dimension.name }}
                                    </div>

                                    <div
                                        class="form-group"
                                        v-if="caso.sub_sub_dimension"
                                    >
                                        <label class="text-primary">SubSubDimension:</label>
                                        {{ caso.sub_sub_dimension.name }}
                                    </div>

                                    <div class="form-group">
                                        <label class="text-primary">Relato:</label>
                                        <p v-html="caso.comment"></p>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button
                                        class="btn btn-danger"
                                        @click="cancelarModal()"
                                    >
                                        <i class="fa fa-times"></i>
                                        Cancelar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>

        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h1>Acompañamiento</h1>
                    <hr />
                    <div class="form-group text-right">
                        <button
                            class="btn btn-rosa"
                            @click="showModalReporte = true"
                        >
                            <i class="fa fa-file"></i>
                            Generar Reporte
                        </button>
                        <button class="btn btn-rosa" @click="showModal = true">
                            <i class="fa fa-plus"></i>
                            Crear Acompanamiento
                        </button>
                    </div>
                    <div class="form-group">
                        <vue-good-table
                            compactMode
                            :columns="columns"
                            :rows="casos"
                            :search-options="{
                                enabled: true,
                                placeholder: 'Buscar en la tabla'
                            }"
                            :pagination-options="{
                                enabled: true,
                                mode: 'records',
                                perPage: 5,
                                perPageDropdown: [3, 7, 9],
                                dropdownAllowAll: true,
                                setCurrentPage: 1,
                                nextLabel: 'siguiente',
                                prevLabel: 'anterior',
                                rowsPerPageLabel: 'Filas por página',
                                ofLabel: 'de',
                                pageLabel: 'página', // for 'pages' mode
                                allLabel: 'Todos'
                            }"
                        >
                            <template slot="table-row" slot-scope="props">
                                <span
                                    v-if="props.column.field == 'participantes'"
                                >
                                    <ul>
                                        <li
                                            :key="item.id"
                                            v-for="item in props.row.users"
                                        >
                                            {{ item.name }}
                                        </li>
                                    </ul>
                                </span>
                                <span v-if="props.column.field == 'actions'">
                                    <div class="btn-group">
                                        <button
                                            class="btn btn-info text-white"
                                            @click="verCaso(props.row)"
                                        >
                                            <i class="fa fa-eye"></i>
                                        </button>
                                        <button
                                            class="btn btn-warning text-white"
                                            @click="editarAcompanamiento(props.row)"
                                        >
                                            <i class="fa fa-pencil"></i>
                                        </button>
                                        
                                        <button
                                            class="btn btn-danger"
                                            @click="eliminarCaso(props.row.id)"
                                        >
                                            <i class="fa fa-trash"></i>
                                        </button>
                                    </div>
                                </span>
                                <span v-else>
                                    {{ props.formattedRow[props.column.field] }}
                                </span>
                            </template>
                        </vue-good-table>
                    </div>
                    <div class="form-group">
                        <template> </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
import "vue-select/dist/vue-select.css";
export default {
    props: ["propsUsuarios", "propsDimensiones", "propsJoven"],
    computed: {},
    created() {
        this.usuarios = JSON.parse(this.propsUsuarios);
        this.dimensiones = JSON.parse(this.propsDimensiones);
        this.joven = JSON.parse(this.propsJoven);
        console.log("los usuarios son: ", this.usuarios);
        console.log("los dimensiones son: ", this.dimensiones);
        console.log("el joven es: ", this.joven);
    },
    mounted(){
        this.getAcompanamiento();
    },
    data() {
        return {
            editing: false,
            errorsReporte: [],
            errors: [],
            usuarios: [],
            dimensiones: [],
            subdimensiones: [],
            subsubdimensiones: [],
            jovenes: [],
            dimension_id: "",
            showModalReporte: false,
            reporte: {
                desde: null,
                hasta: null
            },
            acompanamiento: {
                id: null,
                users: [],
                date: "",
                nnaj_id: "",
                dimension: "",
                sub_dimension: "",
                sub_sub_dimension: "",
                state_progress: "",
                comment: ""
            },
            user_id: "",
            showModal: false,
            showModalView: false,
            casos: [],
            columns: [
                {
                    label: "Fecha",
                    field: "date",
                    dateInputFormat: "yyyy-MM-dd",
                    dateOutputFormat: "MMM do yy"
                },
                {
                    label: "Joven",
                    field: "joven.nombres",
                    type: "string"
                },
                {
                    label: "Actores",
                    field: "participantes",
                    tdClass: "text-center",
                    thClass: "text-center",
                    sortable: false
                },
                {
                    label: "Creado en",
                    field: "created_at",
                    formatFn: this.formatDate
                },
                {
                    label: "Acciones",
                    field: "actions",
                    tdClass: "text-center text-nowrap",
                    thClass: "text-center text-nowrap",
                    sortable: false
                }
            ]
        };
    },
    methods: {
        cancelarModal(){
            this.showModal = false;
            this.showModalReporte = false;
            this.showModalView = false;
            this.limpiarFormulario();
        },
        async editarAcompanamiento(caso){
            console.log("el caso a editar es: xf ",caso);
            await this.getSubDimensions(caso.dimension);
            await this.getSubSubDimensions(caso.sub_dimension);
            console.log("las subdimensiones son: ", this.subdimensiones);
            this.acompanamiento = caso;
            this.acompanamiento.id = caso.id;
            this.acompanamiento.nnaj_id = caso.joven;
            this.acompanamiento.users = caso.users;
            this.editing = true;
            this.showModal = true;
        },
        async generarReporte(e) {
            let validateReporte = this.checkFormReporte(e);
            console.log("al generar el pdf asincrono es :", validateReporte);
            if(validateReporte){
                this.showModalReporte = false;
                let me = this;
                Swal.fire({
                    title: "Estamos generando tu reporte, espera unos segundos",
                    text: "El reporte PDF simplemente se desrgara en su equipo...",
                    iconHtml: "<i class='fas fa-circle-notch fa-spin'></i>",
                    timer: 5000,
                    timerProgressBar: true,
                    showConfirmButton: false,
                    didOpen: () => {
                        window.location.href = `/generate-pdf-from-view/${me.joven.id}/${me.reporte.desde}/${me.reporte.hasta}`;
                    }
                }).then(result => {
                    this.limpiarFormulario();
                    //Read more about handling dismissals below 
                    if (result.dismiss === Swal.DismissReason.timer) {
                        console.log("I was closed by the timer");
                    }
                });
            }else{

            }

        },
        limpiarFormulario() {
            this.editing = false;
            this.errors = [];
            this.errorsReporte = [];
            this.reporte = {
                desde: "",
                hasta: "",
            };
            this.acompanamiento = {
                id: "",
                users: [],
                date: "",
                nnaj_id: "",
                dimension_id: "",
                sub_dimension: "",
                sub_sub_dimension: "",
                state_progress: "",
                comment: ""
            };
            this.subdimensiones = [];
            this.subsubdimensiones = [];
        },
        formatDate(date) {
            var date = new Date(date);
            return date.toLocaleString();
        },
        checkForm: function(e) {
            console.log("el evento a validar es: ", e);
            e.preventDefault();
            let errors = [];
            console.log("el acompañamiento es: ", this.acompanamiento);
            if (this.acompanamiento.users.length == 0) {
                errors.push("Debe ingresar al menos un usuario.");
            }
            if (!this.acompanamiento.date) {
                errors.push("Debe ingresar una fecha.");
            }
            if (
                this.dimensiones.length > 0 &&
                this.acompanamiento.dimension == ""
            ) {
                errors.push("Debe seleccionar una dimension.");
            }
            if (
                this.subdimensiones.length > 0 &&
                this.acompanamiento.sub_dimension == ""
            ) {
                errors.push("Debe seleccionar una subdimension.");
            }
            if (
                this.subsubdimensiones.length > 0 &&
                this.acompanamiento.sub_sub_dimension == ""
            ) {
                errors.push("Debe seleccionar una subsubdimension.");
            }
            if (!this.acompanamiento.state_progress) {
                errors.push("Debe seleccionar un estado.");
            }
            if (!this.acompanamiento.comment) {
                errors.push("Debe escribir una observación.");
            }
            if (errors.length > 0) {
                this.errors = errors;
                return false;
            } else {
                this.errors = [];
                return true;
            }
        },
        checkFormReporte: function(e) {
            console.log("el evento a validar es: ", e);
            e.preventDefault();
            let errors = [];
            console.log("el reporte es: ", this.reporte);
            if (!this.reporte.desde) {
                errors.push("Debe ingresar la fecha 'desde' para generar el reporte.");
            }
            if (!this.reporte.hasta) {
                errors.push("Debe ingresar la fecha 'hasta' para generar el reporte.");
            }
            if (this.reporte.desde > this.reporte.hasta) {
                errors.push("La fecha 'desde' no puede ser mayor a la fecha 'hasta'.");
            }
            if (errors.length > 0) {
                console.log("los errores son: ", errors);
                this.errorsReporte = errors;
                return false;
            } else {
                this.errorsReporte = [];
                return true;
            }
        },
        getAcompanamiento() {
            axios
                .get(`/getAcompanamiento/${this.joven.id}`)
                .then(response => {
                    console.log("la response es: ", response.data);
                    this.casos = response.data.accompanamiento;
                })
                .catch(function(error) {
                    // handle error
                    console.log("ocurrio un error: ", error);
                });
        },
        onChange(event) {
            console.log(event.target.value);
        },
        async getSubDimensions(dimension) {
            console.log("la dimension_id seleccionada es es: ", dimension);
            this.acompanamiento.sub_dimension = "";
            let id = dimension.id;
            this.subdimensiones = [];
            this.subsubdimensiones = [];
            axios
                .get(`/getSubdimensions/${id}`)
                .then(response => {
                    console.log("la response es: ", response.data);
                    this.subdimensiones = response.data.subdimensiones;
                    console.log("la subdimensiones es: ", this.subdimensiones);
                })
                .catch(function(error) {
                    // handle error
                    console.log("ocurrio un error: ", error);
                });
        },
        getSubSubDimensions(subdimension) {
            console.log("la dimension_id es: ", subdimension.id);
            let id = subdimension.id;
            this.subsubdimensiones = [];
            axios
                .get(`/getSubSubdimensions/${id}`)
                .then(response => {
                    console.log("la response es: ", response.data);
                    this.subsubdimensiones = response.data.subsubdimensiones;
                    console.log(
                        "la subsubdimensiones es: ",
                        this.subsubdimensiones
                    );
                })
                .catch(function(error) {
                    // handle error
                    console.log("ocurrio un error: ", error);
                });
        },
        createAcompanamiento(e) {
            const validatedForm = this.checkForm(e);
            //const validatedForm = true;
            if (validatedForm) {
                let data = {
                    date: this.acompanamiento.date,
                    nnaj_id: this.joven.id,
                    dimension_id: this.acompanamiento.dimension.id,
                    sub_dimension_id: this.acompanamiento.sub_dimension.id,
                    sub_sub_dimension_id: this.acompanamiento.sub_sub_dimension.id,
                    comment: this.acompanamiento.comment,
                    state_progress: this.acompanamiento.state_progress,
                    users: this.acompanamiento.users
                };
                console.log(
                    "la data al agregar el analisis de caso es: ",
                    data
                );
                let me = this;
                axios
                    .post("/create_acompanamiento", data, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                    .then(function(response) {
                        console.log(
                            "la repsuesta al agregar el caso es: ",
                            response
                        );
                        Swal.fire("Acompañamiento creado!", "", "success");
                        me.limpiarFormulario();
                        me.showModal = false;
                        me.getAcompanamiento();
                    })
                    .catch(function(error) {
                        console.log("FAILURE!!", error);
                    });
            } else {
                this.$nextTick(() => {
                    this.$refs.errorsForm.scrollTop = 0;
                });
            }
        },
        editAcompanamiento(e) {
            const validatedForm = this.checkForm(e);
            //const validatedForm = true;
            if (validatedForm) {
                let data = {
                    id: this.acompanamiento.id,
                    date: this.acompanamiento.date,
                    nnaj_id: this.joven.id,
                    dimension_id: this.acompanamiento.dimension.id,
                    sub_dimension_id: this.acompanamiento.sub_dimension ? this.acompanamiento.sub_dimension.id : null,
                    sub_sub_dimension_id: this.acompanamiento.sub_sub_dimension ? this.acompanamiento.sub_sub_dimension.id: null,
                    comment: this.acompanamiento.comment,
                    state_progress: this.acompanamiento.state_progress,
                    users: this.acompanamiento.users
                };
                console.log(
                    "la data al agregar el analisis de caso es: ",
                    data
                );
                let me = this;
                axios
                    .post("/edit_accompanamiento", data, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                    .then(function(response) {
                        console.log(
                            "la repsuesta al ediar el acopañamiento es: ",
                            response
                        );
                        Swal.fire("Acompañamiento editado!", "", "success");
                        me.limpiarFormulario();
                        me.showModal = false;
                        me.getAcompanamiento();
                    })
                    .catch(function(error) {
                        console.log("FAILURE!!", error);
                    });
            } else {
                this.$nextTick(() => {
                    this.$refs.errorsForm.scrollTop = 0;
                });
            }
        },
        verCaso(caso) {
            console.log("el acompañamiento a ver es: ", caso);
            this.caso = caso;
            this.showModalView = true;
        },
        eliminarCaso(id) {
            let me = this;
            Swal.fire({
                title: "¿Esta seguro de querer borrar el acompañamiento?",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: `Eliminar`,
                cancelButtonText: "Cancelar"
            }).then(result => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    axios
                        .delete(`/delete_acompanamiento/${id}`, {
                            headers: {
                                "Content-Type": "application/json"
                            }
                        })
                        .then(function(response) {
                            console.log(
                                "la repsuesta al eliminar el acompañamiento es: ",
                                response
                            );
                            Swal.fire(
                                "Acompañamiento eliminado!",
                                "",
                                "success"
                            );
                            me.getAcompanamiento();
                        })
                        .catch(function(error) {
                            console.log("FAILURE!!", error);
                        });
                }
            });
        }
    }
};
</script>
