<style>

    .modal-dialog {
        max-width: 100% !important;
        max-height: 100% !important;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
    }
    .modal-content {
        max-width: 100% !important;
        max-height: 100% !important;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
    }
</style>
<template>
    <div class="row">
        <div v-if="showModal">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog modal-xl" style="width: 100%">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title text-info">
                                        {{ this.editing == true ? "Editar" : "Crear" }}
                                        Caso
                                    </h4>
                                    <button type="button" class="close" @click="cancelarModal()">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body" id="errorsForm" ref="errorsForm">
                                    <div class="form-group" v-if="errors.length > 0">
                                        <div class="card">
                                            <div class="card-body bg-danger text-white">
                                                Errores al llenar los datos
                                                <ul>
                                                    <li :key="error" v-for="error in errors">
                                                        {{ error }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label>Usuarios:</label>
                                                <v-select
                                                    v-model="caso.users"
                                                    multiple="multiple"
                                                    label="name"
                                                    :options="usuarios"></v-select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label>Fecha</label>
                                                <input type="date" class="form-control" v-model="caso.date"/>
                                            </div>

                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label>Casa (direccion):</label>
                                                <v-select v-model="caso.house_id" label="direccion" :options="houses">
                                                </v-select>
                                                
                                            </div>
                                        </div>
                                    
                                    </div>
                                    <div class="row justify-content-md-center">
                                        <div class="col-4 align-self-start">
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        value="tema"
                                                        v-model="showTema"/>Tema
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        value="hecho"
                                                        v-model="showHecho"/>Hechos
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        value="actores"
                                                        v-model="showActores"/>Actores involucrados
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        value="relacion_buena"
                                                        v-model="showRelacionBuena"/>Relación buena, bastante cercana (una línea)
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-4 align-self-start">

                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        value="alianza"
                                                        v-model="showAlianza"/>Alianza
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        value="relacion_fragil"
                                                        v-model="showRelacionFragil"/>Relación frágil (puntuada)
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        value="keyword"
                                                        v-model="showRelacionRota"/>Relación rota o destruida (línea cortada)
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        value="pregunta"
                                                        v-model="showInfluencia"/>Influencia predominante (flecha)
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-4 align-self-start">
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        value="actores"
                                                        v-model="showConflicto"/>Conflicto (zig-zag)
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        value="invisible"
                                                        v-model="showInvisible"/>Invisibles
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        value="posicion"
                                                        v-model="showEstrategiaAcciones"/>Estrategias y Acciones
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group" v-if="showTema">
                                                    <div class="card border-primary">
                                                        <div class="card-body">
                                                            <h5 class="card-title">Tema</h5>
                                                            <vue-editor v-model="caso.tema"></vue-editor>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group" v-if="showHecho">
                                                    <div class="card border-primary">
                                                        <div class="card-body">
                                                            <h5 class="card-title">Hecho</h5>
                                                            <vue-editor v-model="caso.hecho"></vue-editor>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group" v-if="showActores">
                                                    <div class="card border-primary">
                                                        <div class="card-body">
                                                            <h5 class="card-title">Actores</h5>
                                                            <vue-editor v-model="caso.actores"></vue-editor>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group" v-if="showRelacionBuena">
                                                    <div class="card border-primary">
                                                        <div class="card-body">
                                                            <h5 class="card-title">Relacion buena</h5>
                                                            <vue-editor v-model="caso.relacion_buena"></vue-editor>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group" v-if="showAlianza">
                                                    <div class="card border-primary">
                                                        <div class="card-body">
                                                            <h5 class="card-title">Alianza</h5>
                                                            <vue-editor v-model="caso.alianza"></vue-editor>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group" v-if="showRelacionFragil">
                                                    <div class="card border-primary">
                                                        <div class="card-body">
                                                            <h5 class="card-title">Relacion fragil</h5>
                                                            <vue-editor v-model="caso.relacion_fragil"></vue-editor>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group" v-if="showRelacionRota">
                                                    <div class="card border-primary">
                                                        <div class="card-body">
                                                            <h5 class="card-title">Relacion rota</h5>
                                                            <vue-editor v-model="caso.relacion_rota"></vue-editor>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group" v-if="showInfluencia">
                                                    <div class="card border-primary">
                                                        <div class="card-body">
                                                            <h5 class="card-title">Influencia</h5>
                                                            <vue-editor v-model="caso.influencia"></vue-editor>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group" v-if="showConflicto">
                                                    <div class="card border-primary">
                                                        <div class="card-body">
                                                            <h5 class="card-title">Conflicto</h5>
                                                            <vue-editor v-model="caso.conflicto"></vue-editor>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group" v-if="showInvisible">
                                                    <div class="card border-primary">
                                                        <div class="card-body">
                                                            <h5 class="card-title">Invisibles</h5>
                                                            <vue-editor v-model="caso.invisible"></vue-editor>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group" v-if="showEstrategiaAcciones">
                                                    <div class="card border-primary">
                                                        <div class="card-body">
                                                            <h5 class="card-title">Estrategia y acciones</h5>
                                                            <vue-editor v-model="caso.estrategia_acciones"></vue-editor>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="modal-footer">
                                        <button class="btn btn-danger" @click="cancelarModal()">
                                            <i class="fa fa-times"></i>
                                            Cancelar
                                        </button>
                                        <button class="btn btn-success" @click="crearAnalisis" v-if="editing == false">
                                            <i class="fa fa-check"></i>
                                            Crear Caso
                                        </button>
                                        <button class="btn btn-warning" @click="editarAnalisis" v-if="editing == true">
                                            <i class="fa fa-pencil"></i>
                                            Editar Caso
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>

            <div v-if="showModalView">
                <transition name="modal">
                    <div class="modal-mask">
                        <div class="modal-wrapper">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h4 class="modal-title text-primary">Caso Grupal
                                            {{ caso.id }}</h4>
                                        <button type="button" class="close" @click="cancelarModal()">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="form-group">
                                            <label class="text-primary">Usuarios:</label>
                                            <ul>
                                                <li :key="user.id" v-for="user in caso.users">
                                                    {{ user.name }}
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="form-group">
                                            <label class="text-primary">Dirección:</label>
                                            {{ caso.joven.direccion }}
                                        </div>
                                        <div class="form-group">
                                            <label class="text-primary">Fecha:</label>
                                            {{ caso.date }}
                                        </div>
                                        <div class="form-group" v-if="caso.tema">
                                            <div class="card border-primary">
                                                <div class="card-body">
                                                    <h5 class="card-title">Tema</h5>
                                                    <blockquote class="blockquote">
                                                        <p v-html="caso.tema"></p>
                                                    </blockquote>    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" v-if="caso.hecho">
                                            <div class="card border-primary">
                                                <div class="card-body">
                                                    <h5 class="card-title">Hecho</h5>
                                                    <blockquote class="blockquote">
                                                        <p v-html="caso.hecho"></p>
                                                    </blockquote>    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" v-if="caso.actores">
                                            <div class="card border-primary">
                                                <div class="card-body">
                                                    <h5 class="card-title">Actores</h5>
                                                    <blockquote class="blockquote">
                                                        <p v-html="caso.actores"></p>
                                                    </blockquote>    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" v-if="caso.relacion_buena">
                                            <div class="card border-primary">
                                                <div class="card-body">
                                                    <h5 class="card-title">Relación buena, bastante cercana (una línea)</h5>
                                                    <blockquote class="blockquote">
                                                        <p v-html="caso.relacion_buena"></p>
                                                    </blockquote>    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" v-if="caso.alianza">
                                            <div class="card border-primary">
                                                <div class="card-body">
                                                    <h5 class="card-title">Alianza</h5>
                                                    <blockquote class="blockquote">
                                                        <p v-html="caso.alianza"></p>
                                                    </blockquote>    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" v-if="caso.relacion_fragil">
                                            <div class="card border-primary">
                                                <div class="card-body">
                                                    <h5 class="card-title">Relación frágil (puntuada)</h5>
                                                    <blockquote class="blockquote">
                                                        <p v-html="caso.relacion_fragil"></p>
                                                    </blockquote>    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" v-if="caso.relacion_rota">
                                            <div class="card border-primary">
                                                <div class="card-body">
                                                    <h5 class="card-title">Relación rota o destruida (línea cortada)</h5>
                                                    <blockquote class="blockquote">
                                                        <p v-html="caso.relacion_rota"></p>
                                                    </blockquote>    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" v-if="caso.influencia">
                                            <div class="card border-primary">
                                                <div class="card-body">
                                                    <h5 class="card-title">Influencia</h5>
                                                    <blockquote class="blockquote">
                                                        <p v-html="caso.influencia"></p>
                                                    </blockquote>    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" v-if="caso.conflicto">
                                            <div class="card border-primary">
                                                <div class="card-body">
                                                    <h5 class="card-title">Conflicto</h5>
                                                    <blockquote class="blockquote">
                                                        <p v-html="caso.conflicto"></p>
                                                    </blockquote>    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" v-if="caso.invisible">
                                            <div class="card border-primary">
                                                <div class="card-body">
                                                    <h5 class="card-title">Invisibles</h5>
                                                    <blockquote class="blockquote">
                                                        <p v-html="caso.invisible"></p>
                                                    </blockquote>    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" v-if="caso.estrategia_acciones">
                                            <div class="card border-primary">
                                                <div class="card-body">
                                                    <h5 class="card-title">Estrategias y Acciones</h5>
                                                    <blockquote class="blockquote">
                                                        <p v-html="caso.estrategia_acciones"></p>
                                                    </blockquote>    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button class="btn btn-danger" @click="cancelarModal()">
                                            <i class="fa fa-times"></i>
                                            Cancelar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>

            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h1>Analisis grupal</h1>
                        <hr/>
                        <div class="form-group text-right">
                            <button class="btn btn-rosa" @click="showModal = true">
                                <i class="fa fa-plus"></i>
                                Crear Caso
                            </button>
                        </div>
                        <div class="form-group">
                            <vue-good-table
                                :compactMode="true"
                                :columns="columns"
                                :rows="casos"
                                :search-options="{
                                enabled: true,
                                placeholder: 'Buscar en la tabla',
                            }"
                                :pagination-options="{
                                enabled: true,
                                mode: 'records',
                                perPage: 5,
                                perPageDropdown: [3, 7, 9],
                                dropdownAllowAll: true,
                                setCurrentPage: 1,
                                nextLabel: 'siguiente',
                                prevLabel: 'anterior',
                                rowsPerPageLabel: 'Filas por página',
                                ofLabel: 'de',
                                pageLabel: 'página', // for 'pages' mode
                                allLabel: 'Todos',
                            }">
                                <template slot="table-row" slot-scope="props">
                                    <span v-if="props.column.field == 'participantes'">
                                        <ul>
                                            <li :key="item.id" v-for="item in props.row.users">
                                                {{ item.name }}
                                            </li>
                                        </ul>
                                    </span>
                                    <span v-if="props.column.field == 'actions'">
                                        <div class="btn-group">
                                            <button class="btn btn-info text-white" @click="verCaso(props.row)">
                                                <i class="fa fa-eye"></i>
                                            </button>
                                            <button class="btn btn-warning text-white" @click="editarCaso(props.row)">
                                                <i class="fa fa-pencil"></i>
                                            </button>
                                            <button class="btn btn-danger" @click="eliminarCaso(props.row.id)">
                                                <i class="fa fa-trash"></i>
                                            </button>
                                        </div>
                                    </span>
                                    <span v-else>
                                        {{ props.formattedRow[props.column.field] }}
                                    </span>
                                </template>
                            </vue-good-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
    <script>
        import VueEditor from "vue2-editor";
        import axios from "axios";
        import Swal from "sweetalert2";
        import "vue-select/dist/vue-select.css";
        export default {
            props: [
                "propsHouses",
                "propsUsuarios",
            ],
            components: {},
            computed: {},
            created() {
                console.log("componente creado con props: ", this.propsHouses);
                this.interval = setInterval(() => this.getCasos(), 5000);
                this.houses = JSON.parse(this.propsHouses);
                this.usuarios = JSON.parse(this.propsUsuarios);
                console.log("los casas son: ", this.houses);
            },
            data() {
                return {
                    content: '<h1>Initial Content</h1>', errors: [],
                    houses: [],
                    //estados globales
                    editing: false,
                    //props
                    usuarios: [],
                    dimensiones: [],
                    jovenes: [],
                    //array para selects y mostrar datos
                    users: [],
                    casos: [],
                    //modelo de caso para crear/editar/ver
                    caso: {
                        id: "",
                        tema: "",
                        hecho: "",
                        actores: "",
                        relacion_buena: "",
                        alianza: "",
                        relacion_fragil: "",
                        relacion_rota: "",
                        influencia: "",
                        conflicto: "",
                        zigzag: "",
                        estrategia_acciones: "",
                        nnaj_id: "",
                        users: [],
                        errors: []
                    },
                    //boleanos
                    showTema: false,
                    showHecho: false,
                    showActores: false,
                    showRelacionBuena: false,
                    showAlianza: false,
                    showRelacionFragil: false,
                    showRelacionRota: false,
                    showInfluencia: false,
                    showConflicto: false,
                    showInvisible: false,
                    showEstrategiaAcciones: false,
                    //estados modales
                    showModal: false,
                    showModalView: false,
                    //vue good table
                    columns: [
                        {
                            label: "Fecha",
                            field: "date",
                            dateInputFormat: "yyyy-MM-dd",
                            dateOutputFormat: "MMM do yy"
                        }, {
                            label: "Casa",
                            field: "joven.direccion",
                            type: "string"
                        }, {
                            label: "Actores",
                            field: "participantes",
                            tdClass: "text-center",
                            thClass: "text-center",
                            sortable: false
                        }, {
                            label: "Creado en",
                            field: "created_at",
                            formatFn: this.formatDate
                        }, {
                            label: "Acciones",
                            field: "actions",
                            tdClass: "text-center text-nowrap",
                            thClass: "text-center text-nowrap",
                            sortable: false
                        }
                    ]
                };
            },
            methods: {
                formatDate(date) {
                    var date = new Date(date);
                    return date.toLocaleString();
                },
                checkForm: function (e) {
                    console.log("el evento a validar es: ", e);
                    e.preventDefault();
                    let errors = [];
                    console.log("el caso es: ", this.caso);
                    if (this.caso.users.length == 0) {
                        errors.push("Debe seleccionar al menos un usuario.");
                    }
                    if (!this.caso.date) {
                        errors.push("Debe seleccionar un fecha.");
                    }
                    if (!this.caso.house_id) {
                        errors.push("Debe seleccionar una casa.");
                    }
                    if (this.showTema && !this.caso.tema) {
                        errors.push("Debe ingresar un tema.");
                    }
                    if (this.showHecho && !this.caso.hecho) {
                        errors.push("Debe ingresar un hecho.");
                    }
                    if (this.showActores && !this.caso.actores) {
                        errors.push("Debe ingresar actores involucrados.");
                    }
                    if (this.showRelacionBuena && !this.caso.relacion_buena) {
                        errors.push("Debe ingresar una relacion buena.");
                    }
                    if (this.showAlianza && !this.caso.alianza) {
                        errors.push("Debe ingresar una alianza.");
                    }
                    if (this.showRelacionFragil && !this.caso.relacion_fragil) {
                        errors.push("Debe ingresar una relacion fragil.");
                    }
                    if (this.showRelacionRota && !this.caso.relacion_rota) {
                        errors.push("Debe ingresar una relacion fragil.");
                    }
                    if (this.showInfluencia && !this.caso.influencia) {
                        errors.push("Debe ingresar influencia predominante.");
                    }
                    if (this.showConflicto && !this.caso.conflicto) {
                        errors.push("Debe ingresar conflicto.");
                    }
                    if (this.showInvisible && !this.caso.invisible) {
                        errors.push("Debe ingresar invisibles.");
                    }
                    if (this.showEstrategiaAcciones && !this.caso.estrategia_acciones) {
                        errors.push("Debe ingresar pregunta.");
                    }

                    if (!this.caso.tema && !this.caso.hecho && !this.caso.actores && !this.caso.relacion_buena && !this.caso.alianza && !this.caso.relacion_fragil && !this.caso.relacion_rota && !this.caso.influencia && !this.caso.conflicto && !this.caso.invisible && !this.caso.estrategia_acciones) {
                        errors.push(
                            "Debe ingresar al menos un tema, hecho, actores, relacion buena, alianza....etc."
                        );
                    }
                    if (errors.length > 0) {
                        this.errors = errors;
                        return false;
                    } else {
                        return true;
                    }
                },
                async getCasos() {
                    axios
                        .get("/getCasosGrupales")
                        .then((response) => {
                            console.log("la response es: ", response.data);
                            this.casos = response.data.casos;
                        })
                        .catch(function (error) {
                            // handle error
                            console.log("ocurrio un error: ", error);
                        });
                },
                async crearAnalisis(e) {
                    const validatedForm = this.checkForm(e);
                    if (validatedForm) {
                        let data = {
                            date: this.caso.date,
                            house_id: this.caso.house_id.id,
                            comment: this.caso.comment,
                            users: this.caso.users,
                            tema: this.caso.tema,
                            hecho: this.caso.hecho,
                            actores: this.caso.actores,
                            relacion_buena: this.caso.relacion_buena,
                            alianza: this.caso.alianza,
                            relacion_fragil: this.caso.relacion_fragil,
                            relacion_rota: this.caso.relacion_rota,
                            conflicto: this.caso.conflicto,
                            influencia: this.caso.influencia,
                            conflicto: this.caso.conflicto,
                            invisible: this.caso.invisible,
                            estrategia_acciones: this.caso.estrategia_acciones,
                        };
                        console.log("la data al agregar el analisis de caso es: ", data);
                        let me = this;
                        axios
                            .post("/create_caso_grupal", data, {
                                headers: {
                                    "Content-Type": "application/json"
                                }
                            })
                            .then(function (response) {
                                console.log("la repsuesta al agregar el caso es: ", response);
                                Swal.fire("Caso creado!", "", "success");
                                me.showModal = false;
                                me.limpiarFormulario();
                                me.getCasos();
                            })
                            .catch(function (error) {
                                console.log("FAILURE!!", error);
                            });
                    } else {
                        this.$nextTick(() => {
                            this.$refs.errorsForm.scrollTop = 0;
                        });
                    }
                },
                async editarAnalisis(e) {
                    const validatedForm = this.checkForm(e);
                    if (validatedForm) {
                        let data = {
                            id: this.caso.id,
                            date: this.caso.date,
                            house_id: this.caso.house_id.id,
                            users: this.caso.users,
                            tema: this.showTema
                                ? this.caso.tema
                                : null,
                            hecho: this.showHecho
                                ? this.caso.hecho
                                : null,
                            actores: this.showActores
                                ? this.caso.actores
                                : null,
                            relacion_buena: this.showRelacionBuena
                                ? this.caso.relacion_buena
                                : null,
                            alianza: this.showAlianza
                                ? this.caso.alianza
                                : null,
                            relacion_fragil: this.showRelacionFragil
                                ? this.caso.relacion_fragil
                                : null,
                            relacion_rota: this.showRelacionRota
                                ? this.caso.relacion_rota
                                : null,
                            influencia: this.showInfluencia
                                ? this.caso.influencia
                                : null,
                            conflicto: this.showConflicto
                                ? this.caso.conflicto
                                : null,
                            invisible: this.showInvisible
                                ? this.caso.invisible
                                : null,
                            estrategia_acciones: this.showEstrategiaAcciones
                                ? this.caso.estrategia_acciones
                                : null
                        };
                        console.log("la data al editar el analisis de caso es: ", data);
                        let me = this;
                        axios
                            .post("/edit_caso_grupal", data, {
                                headers: {
                                    "Content-Type": "application/json"
                                }
                            })
                            .then(function (response) {
                                console.log("la repsuesta al editar el caso grupal es: ", response);
                                Swal.fire("Caso editado!", "", "success");
                                me.cancelarModal();
                                me.getCasos();
                            })
                            .catch(function (error) {
                                console.log("FAILURE!!", error);
                            });
                    } else {
                        this.$nextTick(() => {
                            this.$refs.errorsForm.scrollTop = 0;
                        });
                    }
                },
                verCaso(caso) {
                    console.log("el caso es: ", caso),
                    (this.caso = caso);
                    this.showModalView = true;
                },
                editarCaso(caso) {
                    console.log("el caso a editar es: xf ", caso);
                    this.caso = caso;
                    this.caso.house_id = caso.joven;
                    this.caso.users = caso.users;
                    this.editing = true;
                    this.checkearEdicion(caso);
                    this.showModal = true;
                },
                checkearEdicion(caso) {
                    this.showTema = caso.tema
                        ? true
                        : false;
                    this.showHecho = caso.hecho
                        ? true
                        : false;
                    this.showActores = caso.actores
                        ? true
                        : false;
                    this.showRelacionBuena = caso.relacion_buena
                        ? true
                        : false;
                    this.showAlianza = caso.alianza
                        ? true
                        : false;
                    this.showRelacionFragil = caso.relacion_fragil
                        ? true
                        : false;
                    this.showRelacionRota = caso.relacion_rota
                        ? true
                        : false;
                    this.showInfluencia = caso.influencia
                        ? true
                        : false;
                    this.showConflicto = caso.conflicto
                        ? true
                        : false;
                    this.showInvisible = caso.invisible
                        ? true
                        : false;
                    this.showEstrategiaAcciones = caso.estrategia_acciones
                        ? true
                        : false;
                },
                cancelarModal() {
                    this.limpiarFormulario();
                    this.showModal = false;
                    this.showModalView = false;
                    this.editing = false;
                },
                limpiarFormulario() {
                    this.errors = [];
                    this.sho = false;
                    this.showTema = false;
                    this.showHecho = false;
                    this.showRelacionBuena = false;
                    this.showAlianza = false;
                    this.showRelacionFragil = false;
                    this.showRelacionRota = false;
                    this.showInfluencia = false;
                    this.showConflicto = false;
                    this.showInvisible = false;
                    this.showEstrategiaAcciones = false;
                    this.caso = {
                        user_id: "",
                        date: "",
                        nnaj_id: "",
                        dimension_id: "",
                        comment: "",
                        users: "",
                        relato: "",
                        hecho: "",
                        necesidad: "",
                        interes: "",
                        emocion: "",
                        posicion: "",
                        actores: "",
                        conflicto: "",
                        keyword: "",
                        pregunta: "",
                        estrategia: "",
                        users: [],
                        errors: []
                    };
                },
                async eliminarCaso(id) {
                    let me = this;
                    Swal
                        .fire(
                            {title: "¿Esta seguro de querer borrar el caso?", showDenyButton: false, showCancelButton: true, confirmButtonText: `Eliminar`, cancelButtonText: "Cancelar"}
                        )
                        .then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                axios
                                    .delete(`/delete_caso_grupal/${id}`, {
                                        headers: {
                                            "Content-Type": "application/json"
                                        }
                                    })
                                    .then(function (response) {
                                        console.log("la repsuesta al eliminar el institucion es: ", response);
                                        Swal.fire("Caso eliminado!", "", "success");
                                        me.getCasos();
                                    })
                                    .catch(function (error) {
                                        console.log("FAILURE!!", error);
                                    });
                            } else if (result.isDenied) {
                                Swal.fire("Changes are not saved", "", "info");
                            }
                        });
                },
                async generarPdf(id) {
                    Swal
                        .fire({
                            title: "Estamos generando tu reporte, espera unos segundos",
                            text: "El reporte PDF simplemente se desrgara en su equipo...",
                            iconHtml: "<i class='fas fa-circle-notch fa-spin'></i>",
                            timer: 5000,
                            timerProgressBar: true,
                            showConfirmButton: false,
                            didOpen: () => {
                                window.location.href = `/generate-pdf-from-view/${id}`;
                            }
                        })
                        .then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                console.log("I was closed by the timer");
                            }
                        });
                }
            }
        };
    </script>