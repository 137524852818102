<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                             <div class="form-group" v-if="errors.length > 0">
                                        <div class="card">
                                            <div class="card-body bg-danger text-white">
                                                Errores al llenar los datos
                                                <ul>
                                                    <li :key="error" v-for="error in errors">
                                                        {{ error }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                    <div class="card" :key="documentType.id" v-for="documentType in documentTypes">
                        <div class="card-body">
                            <label class="text-info">{{documentType.name}}</label>
                            <div class="form-group" v-if="documentType.documents.length>0">
                                <label>Documentos</label>
                                <div class="form-group" :key="document.id" v-for="document in documentType.documents">
                                    {{document.name}}
                                    <div class="btn-group">                       
                                        <a :href="`/uploadedimages/${document.uri}`" class="btn btn-info text-white" title="Ver adjunto" target="_blank">
                                            <i class="fa fa-eye">
                                            </i>
                                        </a>
                                        <a :href="`/uploadedimages/${document.uri}`" :download="document.name" title="Descargar adjunto" class="btn btn-success" target="_blank">
                                            <i class="fa fa-download">
                                            </i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group bg-error" v-else>
                                <button class="card bg-danger text-white" disabled>
                                    Sin documentos
                                </button>
                            </div>
                            <div class="form-group">
                                <label>Adjuntar archivo</label>
                                <input type="file" class="form-control" :ref="`files`" name="filesArray" @change="onChangeFile">
                            </div>
                            <div class="form-group">
                                <button class="btn btn-success" @click="createDocumentable(documentType)">
                                    <i class="fa fa-file"></i> Cargar archivo
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import Swal from 'sweetalert2';
export default {
    computed: {},
    props: [
        "propsDocumentTypes", "propsJoven", 
    ],
    created() {
        //this.documentTypes = JSON.parse(this.propsDocumentTypes);
        this.joven = JSON.parse(this.propsJoven);
        console.log("los documennts types son: ", this.documentTypes);
        console.log("el joven es: ", this.joven);
        this.getInstituciones();
    },
    data() {
        return {
            attachment: [],
            filesArray: null,
            documentTypes: [],
            errors: [],
            editing: false,
            institucion: {
                id: "",
                name: "",
                documentType: "",
            },
            files:{
                name: ""
            },
            joven: "",
            showModal: false,
            instituciones: [],
            //vue good table
            columns: [
                {
                    label: 'Nombre Completo',
                    field: 'full_name',
                    type: 'string'
                },
                {
                    label: 'Nombre Corto',
                    field: 'short_name',
                    type: 'string',
                },
                {
                    label: 'Acciones',
                    field: 'actions',
                    tdClass: 'text-center text-nowrap',
                    thClass: 'text-center text-nowrap',
                    sortable: false,
                }
            ],
        };
    },
    methods: {
        clear () {
            console.log("se ejecuto clear..");
            const input = this.$refs.files;
            input.map(element => {
                element.type = 'text';
                element.type = 'file';
            });
        },
        onChangeFile(event){
            let selectedFiles = event.target.files;
            if(!selectedFiles.length) {
                return false;
            }
            console.log("los selectedFiles son: ", selectedFiles);
            for (let i = 0; i < selectedFiles.length; i++){
                this.attachment.push(selectedFiles[i]);
            }
            console.log("los archivos son: ", this.filesArray);
        },
        cancelarModal(){
            this.limpiarFormulario();
            this.showModal = false;
            this.editing = false;
        },
        limpiarFormulario(){
            this.errors = [];
            this.institucion =  {
                id: "",
                full_name: "",
                short_name: "",
            };
        },
        getInstituciones() {
            axios
                .get(`/get_files/${this.joven.id}`)
                .then(response => {
                    console.log("la response es: ", response.data);
                    this.documentTypes = response.data.jovenes;
                })
                .catch(function(error) {
                    // handle error
                    console.log("ocurrio un error: ", error);
                });
        },
        checkForm: function (e) {
            console.log("el evento a validar es: ", e);
            e.preventDefault();
            let errors = [];
            console.log("el institucion es: ", this.institucion);
            if (!this.institucion.full_name) {
                errors.push('Debe ingresar el nombre completo de la institucion.');
            }
            if (!this.institucion.short_name) {
                errors.push('Debe ingresar el nombre corto de la institucion.');
            }
            if(errors.length > 0 ){
                this.errors = errors;
                return false;
            }else{
                return true;
            }
        },
        createDocumentable(documentType) {       
            let validForm = this.attachment.length>0 ? true : false;
            if(validForm){
                let formData = new FormData();
                console.log("enviando al joven: ", this.joven.id);
                console.log("enviando el documento: ", documentType.id);
                formData.append('name', this.institucion.name);
                formData.append('document_type', documentType.id);
                formData.append('nnaj_id', this.joven.id);
                for (let index = 0; index < this.attachment.length; index++) {
                    formData.append('files[]', this.attachment[index]);
                }
                console.log(
                    "la data al agregar el documento es: ",
                    formData
                );
                let me = this;
                axios
                    .post("/upload_file",
                    formData, {
                        headers: {
                            'Content-Type': "multipart/form-data" 
                        }       
                    })
                    .then(function(response) {
                        console.log(
                            "la repsuesta al agregar el documentable  es: ",
                            response
                        );
                        Swal.fire('Documento creado!', '', 'success');
                        me.filesArray = null;
                        me.files = [];
                        me.attachment = [];
                        me.clear();
                        me.cancelarModal();
                        me.getInstituciones();
                
                    })
                    .catch(function(error) {
                        console.log("FAILURE!!", error);
                    });
            }else{
                this.errors.push("Debe ingresar al menos un archivo.");
            }

        },
        editInstitucion(e) {
            const validatedForm = this.checkForm(e);
            if(validatedForm){
                let data = {
                    id: this.institucion.id,
                    full_name: this.institucion.full_name,
                    short_name: this.institucion.short_name
                };
                console.log(
                    "la data al editar el institucion es: ",
                    data
                );
                let me = this;
                axios
                    .post("/editInstitucion", data, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                    .then(function(response) {
                        console.log(
                            "la repsuesta al agregar el usuario es: ",
                            response
                        );
                        Swal.fire('Institucion editada!', '', 'success')
                        me.cancelarModal();
                        me.getInstituciones();
                
                    })
                    .catch(function(error) {
                        console.log("FAILURE!!", error);
                    });
            }else{
                this.$nextTick(() => {
                    this.$refs.errorsForm.scrollTop = 0;
                });
            }
        },
        editarInstitucion(institucion){
            this.editing = true;
            this.institucion = institucion;
            this.showModal = true;
        },
        eliminarInstitucion(id){
              let me = this;
            Swal.fire({
                title: '¿Esta seguro de querer borrar la institucion?',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: `Eliminar`,
                cancelButtonText:'Cancelar',
                }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    axios.delete(`/delete_institution/${id}`, {
                            headers: {
                                "Content-Type": "application/json"
                            }
                        })
                        .then(function(response) {
                            console.log(
                                "la repsuesta al eliminar el institucion es: ",
                                response
                            );
                             Swal.fire('Institucion eliminada!', '', 'success')
                            me.getInstituciones();
                    
                        })
                        .catch(function(error) {
                            console.log("FAILURE!!", error);
                        });
                   
                } else if (result.isDenied) {
                    Swal.fire('Changes are not saved', '', 'info')
                }
                })
        },
    }
};
</script>
