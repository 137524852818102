<style>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
    overflow-y: auto;
}
.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}
.pac-container {
    z-index: 10000 !important;
}
</style>
<template>
    <div class="row">
      
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    Jovenes
                </div>
                <div class="card-body">
                    <div class="form-group">
                        <label>Nombres</label>
                        <input
                            type="text"
                            class="form-control"
                            v-model="nombres"
                        />
                    </div>
                    <div class="form-group">
                        <label>Apellido materno</label>
                        <input
                            type="text"
                            class="form-control"
                            v-model="apellido_materno"
                        />
                    </div>
                    <div class="form-group">
                        <label>Apellido paterno</label>
                        <input
                            type="text"
                            class="form-control"
                            v-model="apellido_paterno"
                        />
                    </div>
                    <div class="form-group">
                        <label>Fecha de nacimiento</label>
                        <input
                            type="date"
                            class="form-control"
                            v-model="fecha_nacimiento"
                        />
                    </div>
                    <div class="form-group">
                        <label>identidad</label>
                        <input
                            type="text"
                            class="form-control"
                            v-model="identidad"
                        />
                    </div>
                    <div class="form-group">
                        <label>Sexo</label>
                        <select type="text" class="form-control" v-model="sexo">
                            <option value="">Seleccione opcion</option>
                            <option value="Hombre">Hombre</option>
                            <option value="Mujer">Mujer</option>
                            <option value="Otro">Otro</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label>Genero</label>
                        <select
                            type="text"
                            class="form-control"
                            v-model="genre_id"
                        >
                            <option value="">Seleccione opcion</option>
                            <option :value="1">Femenino</option>
                            <option :value="2">Masculino</option>
                            <option :value="3">Trans</option>
                            <option :value="4">No binario</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label>Nacionalidad</label>
                        <select
                            type="text"
                            class="form-control"
                            v-model="nationality_id"
                        >
                            <option value="">Seleccione opcion</option>
                            <option v-for="nacionalidad in nacionalidades" :value="nacionalidad.id" :key="nacionalidad.id">
                                {{nacionalidad.name}}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label>Etnia</label>
                        <select
                            type="text"
                            class="form-control"
                            v-model="ethnicity_id"
                        >
                            <option value="">Seleccione opcion</option>
                            <option v-for="etnia in etnias" :value="etnia.id" :key="etnia.id">
                                {{etnia.name}}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label>Institucion</label>
                        <select
                            type="text"
                            class="form-control"
                            v-model="institution_id"
                        >
                            <option value="">Seleccione opcion</option>
                            <option v-for="institucion in instituciones" :value="institucion.id" :key="institucion.id">
                                {{institucion.full_name}}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label>¿Posee discapacidad?</label>
                        <select
                            type="text"
                            class="form-control"
                            v-model="disability_id"
                        >
                            <option value="">Seleccione opcion</option>
                            <option v-for="discapacidad in discapacidades" :value="discapacidad.id" :key="discapacidad.id">
                                {{discapacidad.name}}
                            </option>
                        </select>
                    </div>
                    <div v-if="disability_id && disability_id != '6'" class="form-group">
                        <label>¿tiene carnet?</label>
                        <select
                            type="text"
                            class="form-control"
                            v-model="green_carnet"
                        >
                            <option value="true">Si</option>
                            <option value="false">no</option>
                        </select>
                    </div>
                    <div v-if="disability_id && disability_id != '6'" class="form-group">
                        <label>¿Porcentje discapacidad?</label>
                        <input
                            type="number"
                            class="form-control"
                            v-model="percent_disability"
                        />
                    </div>
                    <div class="form-group">
                        <button
                            class="btn btn-rosa"
                            @click="createJoven()"
                        >
                        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" class="bi bi-person-plus-fill"><path fill-rule="evenodd" d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm7.5-3a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"></path></svg>
                            Crear
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import Swal from 'sweetalert2';
export default {
    props:['propsNacionalidades', 'propsEtnias', 'propsInstituciones', 'propsDiscapacidades'],
    computed: {},
    created() {
        //this.getInstituciones();
        this.nacionalidades = JSON.parse(this.propsNacionalidades);
        this.etnias = JSON.parse(this.propsEtnias);
        this.instituciones = JSON.parse(this.propsInstituciones);
        this.discapacidades = JSON.parse(this.propsDiscapacidades);
    },
    mounted(){
        console.log("las etnias son: ", this.etnias);
        console.log("las this  nacionalidades son: ", this.nacionalidades);
        console.log("las this  instituciones son: ", this.instituciones);
        console.log("las this  discapacidades son: ", this.discapacidades);
    },
    data() {
        return {
            nombres: "",
            apellido_materno: "",
            apellido_paterno: "",
            identidad: "",
            disability_id: "",
            genre_id: "",
            sexo: "",
            green_carnet: false,
            percent_disability: "",
            fecha_nacimiento: "",
            ethnicity_id: "",
            nationality_id: "",
            institution_id: "",
            nacionalidades: [],
            etnias:[],
            /////
            full_name: "",
            short_name: "",
            showModal: false,
            instituciones: [],
            discapacidades: [],
            respuestas: false,
            step1: false,
            step2: false,
            pregunta1: {
                id: "pregunta1",
                key:
                    "¿Cual es el nivel educacional mas alto alcanzo o tu nivel educacional actual?",
                value: "",
                answer: "",
                visible: false
            }
        };
    },
    methods: {
        getInstituciones() {
            axios
                .get("/getInstituciones")
                .then(response => {
                    console.log("las instituciones son: ", response.data);
                    this.instituciones = response.data.instituciones;
                })
                .catch(function(error) {
                    // handle error
                    console.log("ocurrio un error: ", error);
                });
        },
        createJoven() {
            let data = {
                nombres: this.nombres,
                apellido_materno: this.apellido_materno,
                apellido_paterno: this.apellido_paterno,
                identidad: this.identidad,
                disability_id: this.disability_id,
                genre_id: this.genre_id,
                sexo: this.sexo,
                green_carnet: this.green_carnet,
                percent_disability: this.percent_disability,
                nationality_id: this.nationality_id,
                fecha_nacimiento: this.fecha_nacimiento,
                ethnicity_id: this.ethnicity_id,
                institution_id: this.institution_id
            };
            console.log("la data al agregar el createJoven es: ", data);
            let me = this;
            axios
                .post("/create_joven", data, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then(function(response) {
                    console.log(
                        "la repsuesta al agregar el usuario es: ",
                        response
                    );
                     Swal.fire({
                title: 'Joven creado con exito',
                showDenyButton: false,
                showCancelButton: true,
                timer: 3000,
                confirmButtonText: `Ok`,
                showCancelButton:false,
                }).then((result) => {
                    window.location.replace("/jovenes");
                })
                    console.log("usuario creado: ");
                })
                .catch(function(error) {
                    console.log("FAILURE!!", error);
                });
        },
        showQuestion(pregunta) {
            pregunta.visible = !pregunta.visible;
            console.log("LA DATA ACTUALIZADA ES: ", this.$data);
        },
        getRespuesta(pregunta, respuesta) {
            console.log("data : ", this.$data);
            this.$data[pregunta];
            this.$data[pregunta.id].answer = respuesta;
            console.log("la respuesta 1 es: ", this.$data[pregunta.id]);
        },
        checkFormacionEmpleo() {
            if (this.pregunta7.value) {
                this.step1 = true;
                this.step2 = false;
            }
        },
        scrollBottom() {
            console.log("la data sistema es: ", this.$data);
            console.log("bajnado....");
            window.scrollTo(0, 1200);
        }
    }
};
</script>
