require('./bootstrap');
window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));
import vSelect from 'vue-select';
import VueGoodTablePlugin from 'vue-good-table';
// import the styles
import 'vue-good-table/dist/vue-good-table.css';
import JsonExcel from "vue-json-excel";

Vue.use(VueGoodTablePlugin);
Vue.component('v-select', vSelect);
Vue.component("downloadExcel", JsonExcel);
Vue.component('task-component', require('./views/app.vue').default);
Vue.component('monitoreo-component', require('./views/monitoreo.vue').default);
Vue.component('instituciones-component', require('./views/instituciones.vue').default);
Vue.component('jovenes-component', require('./views/jovenes.vue').default);
Vue.component('analisis-component', require('./views/analisis.vue').default);
Vue.component('analisisgrupal-component', require('./views/analisisgrupal.vue').default);
Vue.component('nnaj-component', require('./views/nnaj.vue').default);
Vue.component('mis-casos-component', require('./views/mis-casos.vue').default);
Vue.component('acompanamiento-component', require('./views/acompanamiento.vue').default);
Vue.component('notificaciones-component', require('./views/notificaciones.vue').default);
Vue.component('documentable-component', require('./views/documentable.vue').default);
Vue.component('pvi-component', require('./views/pvi.vue').default);
Vue.component('tablejoven-component', require('./views/tablejoven.vue').default);
Vue.component('acciones-component', require('./views/acciones.vue').default);
Vue.component('experiencia-component', require('./views/experiencia.vue').default);
Vue.component('mi-acompanamiento-component', require('./views/mi-acompanamiento.vue').default);
Vue.component('casa-component', require('./views/casa.vue').default);
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


const app = new Vue({
    el: '#app',
});
