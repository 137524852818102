<template>
    <div class="row">
        <div v-if="showModal">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog modal-sm">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title text-info">
                                        {{ this.editing ? 'Editar' : 'Crear' }} Joven
                                    </h4>
                                    <button
                                        type="button"
                                        class="close"
                                        @click="cancelarModal()"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body" id="errorsForm" ref="errorsForm">
                                    <div class="form-group" v-if="errors.length>0" >
                                        <div class="card">
                                            <div class="card-body bg-danger text-white">
                                                Errores al llenar los datos
                                                <ul>
                                                    <li :key="error" v-for="error in errors">{{ error }}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label class="text-primary">Nombres:</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="nnaj.nombres"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label class="text-primary">Apellido paterno:</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="nnaj.apellido_paterno"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label class="text-primary">Apellido materno:</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="nnaj.apellido_materno"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label class="text-primary">Tarjeta ID:</label>
                                                <v-select
                                                    v-model="nnaj.card_id"
                                                    label="name"
                                                    :options="cards"></v-select>
                                            </div>
                                        </div>
                                    </div>
                                     <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label class="text-primary">Genero:</label>
                                                <v-select
                                                    v-model="nnaj.genre_id"
                                                    label="name"
                                                    :options="genres"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label class="text-primary">Institución:</label>
                                                <v-select
                                                    v-model="nnaj.institution_id"
                                                    label="short_name"
                                                    :options="instituciones"></v-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" >
                                        <div class="col-6" v-if="nnaj.card_id">
                                            <div class="form-group">
                                                <label class="text-primary">Identidad: (tipo){{ this.nnaj.card_id.name}}</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="nnaj.identidad"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label class="text-primary">Fecha de nacimiento:</label>
                                                <input
                                                    type="date"
                                                    class="form-control"
                                                    v-model="nnaj.fecha_nacimiento"
                                                />              
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" >
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label class="text-primary">Sexo: </label>
                                                 <select type="text" class="form-control" v-model="nnaj.sexo">
                                                    <option value="">Seleccione opcion</option>
                                                    <option value="Hombre">Hombre</option>
                                                    <option value="Mujer">Mujer</option>
                                                    <option value="Otro">Otro</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label class="text-primary">Discapacidad:</label>
                                                <v-select
                                                    v-model="nnaj.disability_id"
                                                    label="name"
                                                    :options="discapacidades"></v-select>            
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" >
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label class="text-primary">Etnia: </label>
                                                <v-select
                                                    v-model="nnaj.ethnicity_id"
                                                    label="name"
                                                    :options="etnias"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-6" v-if="nnaj.disability_id && nnaj.disability_id.id != 6">
                                            <div class="form-group">
                                                <label class="text-primary">Porcentaje discapacidad:</label>
                                                <input type="number" v-model="nnaj.percent_disability" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" >
                                        <div class="col-6" >
                                             <div class="form-group">
                                                <label class="text-primary">Nacionalidad: </label>
                                                <v-select
                                                    v-model="nnaj.nationality_id"
                                                    label="name"
                                                    :options="nacionalidades"></v-select>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div v-if="nnaj.disability_id && nnaj.disability_id.id != 6" class="form-group">
                                                <label class="text-info">¿Tiene carnet?</label>
                                                <select
                                                    type="text"
                                                    class="form-control"
                                                    v-model="nnaj.green_carnet"
                                                >
                                                    <option value="true">Si</option>
                                                    <option value="false">no</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="modal-footer">
                                    <button
                                        class="btn btn-danger"
                                        @click="cancelarModal()"
                                    >
                                        <i class="fa fa-times"></i>
                                        Cancelar
                                    </button>
                                    <button v-if="!editing"
                                        class="btn btn-success"
                                        @click="createJoven"
                                    >
                                        <i class="fa fa-check"></i>
                                        Crear
                                    </button>
                                    <button
                                        class="btn btn-warning text-white"
                                        @click="editJoven"
                                        v-if="editing"
                                    >
                                        <i class="fa fa-pencil"></i>
                                        Editar
                                    </button>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h1>Jovenes</h1>
                    <hr>
                    <div class="form-group text-right">
                        <button
                            class="btn btn-rosa"
                            @click="showModal = true"
                        >
                            <i class="fa fa-plus"></i>
                            Crear Joven
                        </button>
                    </div>
                    <div class="form-group">
                        <download-excel :data="jovenes">
                            <i class="fa fa-download"></i>
                            Descargar Excel
                        </download-excel>
                    </div>
                    <div class="form-group">
                        <vue-good-table
                            compactMode
                            :columns="columns"
                            :rows="jovenes" 
                            :search-options="{
                                enabled: true,
                                placeholder: 'Buscar en la tabla',
                            }" 
                            :pagination-options="{
                                enabled: true,
                                mode: 'records',
                                perPage: 5,
                                perPageDropdown: [3, 7, 9],
                                dropdownAllowAll: true,
                                setCurrentPage: 1,
                                nextLabel: 'siguiente',
                                prevLabel: 'anterior',
                                rowsPerPageLabel: 'Filas por página',
                                ofLabel: 'de',
                                pageLabel: 'página', // for 'pages' mode
                                allLabel: 'Todos',
                            }">
                              <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field == 'actions'">
                                    <div class="btn-group">
                                        <a class="btn btn-info" :href="`/jovenes/show/${props.row.id}`">
                                            <i class="fa fa-user"></i>
                                        </a>
                                        <button class="btn btn-warning" @click="editarJoven(props.row)">
                                            <i class="fa fa-pencil"></i> 
                                        </button>
                                        <button class="btn btn-danger" @click="eliminarJoven(props.row.id)">
                                            <i class="fa fa-trash"></i>
                                        </button>
                                    </div>
                                </span>
                                <span v-else>
                                    {{props.formattedRow[props.column.field]}}
                                </span>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import Swal from 'sweetalert2';
export default {
   props:['propsNacionalidades', 'propsEtnias', 'propsInstituciones', 'propsDiscapacidades', 'propsCards', 'propsGenres'],
    computed: {},
    created() {
        this.nacionalidades = JSON.parse(this.propsNacionalidades);
        this.etnias = JSON.parse(this.propsEtnias);
        this.instituciones = JSON.parse(this.propsInstituciones);
        this.discapacidades = JSON.parse(this.propsDiscapacidades);
        this.cards = JSON.parse(this.propsCards);
        this.genres = JSON.parse(this.propsGenres);
        console.log("nacionalidades" , this.nacionalidades);
        console.log("etnias", this.etnias);
        console.log("instituciones", this.instituciones);
        console.log("discapacidades", this.discapacidades);
        console.log("cards", this.cards);
        console.log("genres", this.genres);
    },
    mounted(){
        this.getJovenes();
    },
    data() {
        return {
            errors: [],
            editing: false,
            nnaj: {
                id: null,
                nombres: "",
                apellido_materno: "",
                apellido_paterno: "",
                identidad: "",
                disability_id: "",
                genre_id: "",
                sexo: "",
                green_carnet: false,
                percent_disability: "",
                fecha_nacimiento: "",
                ethnicity_id: "",
                nationality_id: "",
            },
            showModal: false,
            jovenes: [],
            cards: [],
            instituciones: [],
            nacionalidades: [],
            genres: [],
            //vue good table
            columns: [
                {
                    label: 'Nombre Completo',
                    field: 'full_name',
                    type: 'string'
                },
                {
                    label: 'Identificador',
                    field: 'identidad',
                    type: 'string',
                },
                {
                    label: "Creado en",
                    field: "created_at",
                    formatFn: this.formatDate,
                    sortable: true
                },
                {
                    label: 'Acciones',
                    field: 'actions',
                    tdClass: 'text-center text-nowrap',
                    thClass: 'text-center text-nowrap',
                    sortable: false,
                }
            ],
        };
    },
    methods: {
        formatDate(date) {
            var newDate = new Date(date);
            return newDate.toLocaleString();
        },
        cancelarModal(){
            this.showModal = false;
            this.editing = false;
            this.limpiarFormulario();
        },
        limpiarFormulario(){
            this.errors = [];
            this.nnaj = {
                id: null,
                nombres: "",
                apellido_materno: "",
                apellido_paterno: "",
                identidad: "",
                disability_id: "",
                genre_id: "",
                sexo: "",
                card_id: "",
                green_carnet: false,
                percent_disability: "",
                fecha_nacimiento: "",
                ethnicity_id: "",
                nationality_id: "",
            };
        },
        getJovenes() {
            axios
                .get("/getJovenes")
                .then(response => {
                    console.log("la response es: ", response.data);
                    this.jovenes = response.data.jovenes;
                })
                .catch(function(error) {
                    // handle error
                    console.log("ocurrio un error: ", error);
                });
        },
        checkForm: function (e) {
            console.log("el evento a validar es: ", e);
            e.preventDefault();
            let errors = [];
            console.log("el institucion es: ", this.institucion);
            if (!this.nnaj.nombres) {
                errors.push('Debe ingresar los nombres del joven.');
            }
            if (!this.nnaj.apellido_paterno) {
                errors.push('Debe ingresar el apellido paterno.');
            }
            if (!this.nnaj.apellido_materno) {
                errors.push('Debe ingresar el apellido materno.');
            }
            if (!this.nnaj.identidad) {
                errors.push('Debe ingresar identidad del joven.');
            }
            if (!this.nnaj.card_id) {
                errors.push('Debe ingresar un tipo de identificador (rut, pasaporte...).');
            }
            if (!this.nnaj.genre_id) {
                errors.push('Debe ingresar un genero.');
            }
            if (!this.nnaj.disability_id) {
                errors.push('Debe ingresar una discapacidad, si no la posee ingrese "No posee discapacidad".');
            }
            if (!this.nnaj.ethnicity_id) {
                errors.push('Debe ingresar una etnia, si no la posee ingrese "No pertenece a etnia".');
            }
            if (!this.nnaj.institution_id) {
                errors.push('Debe ingresar una institución.');
            }
            if (!this.nnaj.sexo) {
                errors.push('Debe ingresar sexo del joven.');
            }
            if (!this.nnaj.nationality_id) {
                errors.push('Debe ingresar una nacionalidad.');
            }
            if (this.nnaj.disability_id && this.nnaj.disability_id.id != 6 && !this.nnaj.percent_disability) {
                errors.push('Debe ingresar un porcentaje aproximado de su discapacidad si es que la posee.');
            }
            if(errors.length > 0 ){
                this.errors = errors;
                return false;
            }else{
                return true;
            }
        },
        createJoven(e) {
            const validatedForm = this.checkForm(e);
            if(validatedForm){
                let data = {
                    nombres: this.nnaj.nombres,
                    apellido_materno: this.nnaj.apellido_materno,
                    apellido_paterno: this.nnaj.apellido_paterno,
                    identidad: this.nnaj.identidad,
                    disability_id: this.nnaj.disability_id.id,
                    genre_id: this.nnaj.genre_id.id,
                    card_id: this.nnaj.card_id.id,
                    sexo: this.nnaj.sexo,
                    green_carnet: this.nnaj.green_carnet,
                    percent_disability: this.nnaj.percent_disability,
                    nationality_id: this.nnaj.nationality_id.id,
                    fecha_nacimiento: this.nnaj.fecha_nacimiento,
                    ethnicity_id: this.nnaj.ethnicity_id.id,
                    institution_id: this.nnaj.institution_id.id
                };
                console.log(
                    "la data al agregar el createJoven es: ",
                    data
                );
                let me = this;
                axios
                    .post("/create_joven", data, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                    .then(function(response) {
                        console.log(
                            "la repsuesta al agregar el joven es: ",
                            response
                        );
                        Swal.fire('Joven creado!', '', 'success')
                        me.cancelarModal();
                        me.getJovenes();
                
                    })
                    .catch(function(error) {
                        console.log("FAILURE!!", error);
                    });
            }else{
                this.$nextTick(() => {
                    this.$refs.errorsForm.scrollTop = 0;
                });
            }
        },
        editJoven(e) {
            const validatedForm = this.checkForm(e);
            if(validatedForm){
                let data = {
                    id: this.nnaj.id,
                    nombres: this.nnaj.nombres,
                    apellido_materno: this.nnaj.apellido_materno,
                    apellido_paterno: this.nnaj.apellido_paterno,
                    identidad: this.nnaj.identidad,
                    disability_id: this.nnaj.disability_id.id,
                    genre_id: this.nnaj.genre_id.id,
                    card_id: this.nnaj.card_id.id,
                    sexo: this.nnaj.sexo,
                    green_carnet: this.nnaj.green_carnet,
                    percent_disability: this.nnaj.percent_disability,
                    nationality_id: this.nnaj.nationality_id.id,
                    fecha_nacimiento: this.nnaj.fecha_nacimiento,
                    ethnicity_id: this.nnaj.ethnicity_id.id,
                    institution_id: this.nnaj.institution_id.id
                };
                console.log(
                    "la data al agregar el createJoven es: ",
                    data
                );
                let me = this;
                axios
                    .post("/edit_joven", data, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                    .then(function(response) {
                        console.log(
                            "la repsuesta al editar el joven es: ",
                            response
                        );
                        Swal.fire('Joven editado!', '', 'success')
                        me.cancelarModal();
                        me.getJovenes();
                
                    })
                    .catch(function(error) {
                        console.log("FAILURE!!", error);
                    });
            }else{
                this.$nextTick(() => {
                    this.$refs.errorsForm.scrollTop = 0;
                });
            }
        },
        editInstitucion(e) {
            const validatedForm = this.checkForm(e);
            if(validatedForm){
                let data = {
                    id: this.institucion.id,
                    full_name: this.institucion.full_name,
                    short_name: this.institucion.short_name
                };
                console.log(
                    "la data al editar el institucion es: ",
                    data
                );
                let me = this;
                axios
                    .post("/editInstitucion", data, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                    .then(function(response) {
                        console.log(
                            "la repsuesta al agregar el usuario es: ",
                            response
                        );
                        Swal.fire('Institucion editada!', '', 'success')
                        me.cancelarModal();
                        me.getJovenes();
                
                    })
                    .catch(function(error) {
                        console.log("FAILURE!!", error);
                    });
            }else{
                this.$nextTick(() => {
                    this.$refs.errorsForm.scrollTop = 0;
                });
            }
        },
        editarJoven(nnaj){
            console.log("el joven quedo  de la forma ", nnaj);
            this.editing = true;
            this.nnaj.id = nnaj.id;
            this.nnaj.nombres = nnaj.nombres;
            this.nnaj.apellido_paterno = nnaj.apellido_paterno;
            this.nnaj.apellido_materno = nnaj.apellido_materno;
            this.nnaj.fecha_nacimiento = nnaj.fecha_nacimiento;
            this.nnaj.sexo = nnaj.sexo;
            this.nnaj.identidad = nnaj.identidad;
            this.nnaj.percent_disability = nnaj.percent_disability;
            this.nnaj.ethnicity_id = this.etnias.find(e => e.id == nnaj.ethnicity_id);
            this.nnaj.nationality_id = this.nacionalidades.find( e => e.id == nnaj.nationality_id);
            this.nnaj.card_id = this.cards.find(e => e.id == nnaj.card_id);
            this.nnaj.institution_id = this.instituciones.find(e => e.id == nnaj.institution_id);
            this.nnaj.disability_id = this.discapacidades.find(e => e.id == nnaj.disability_id);
            this.nnaj.genre_id = this.genres.find(e => e.id == nnaj.genre_id);
            this.nnaj.green_carnet = this.nnaj.green_carnet == 1? true: false;
            this.showModal = true;
            console.log("el joven quedo d ela forma: ", this.nnaj);
        },
        eliminarJoven(id){
              let me = this;
            Swal.fire({
                title: '¿Esta seguro de querer borrar al joven?',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: `Eliminar`,
                cancelButtonText:'Cancelar',
                }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    axios.delete(`/delete_joven/${id}`, {
                            headers: {
                                "Content-Type": "application/json"
                            }
                        })
                        .then(function(response) {
                            console.log(
                                "la repsuesta al eliminar el institucion es: ",
                                response
                            );
                             Swal.fire('Institucion eliminada!', '', 'success')
                            me.getJovenes();
                    
                        })
                        .catch(function(error) {
                            console.log("FAILURE!!", error);
                        });
                   
                } else if (result.isDenied) {
                    Swal.fire('Changes are not saved', '', 'info')
                }
                })
        },
    }
};
</script>
