<style>

    .modal-dialog {
        max-width: 100% !important;
        max-height: 100% !important;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
    }
    .modal-content {
        max-width: 100% !important;
        max-height: 100% !important;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
    }
</style>
<template>
    <div class="row">
        <div v-if="showModal">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog modal-xl" style="width: 100%">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title text-info">
                                        {{ this.editing == true ? "Editar" : "Crear" }}
                                        Caso
                                    </h4>
                                    <button type="button" class="close" @click="cancelarModal()">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body" id="errorsForm" ref="errorsForm">
                                    <div class="form-group" v-if="errors.length > 0">
                                        <div class="card">
                                            <div class="card-body bg-danger text-white">
                                                Errores al llenar los datos
                                                <ul>
                                                    <li :key="error" v-for="error in errors">
                                                        {{ error }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label>Usuarios:</label>
                                                <v-select
                                                    v-model="caso.users"
                                                    multiple="multiple"
                                                    label="name"
                                                    :options="usuarios"></v-select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label>Fecha</label>
                                                <input type="date" class="form-control" v-model="caso.date"/>
                                            </div>

                                        </div>
                                     
                                    </div>
                                    <div class="row justify-content-md-center">
                                        <div class="col-4 align-self-start">
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        value="relato"
                                                        v-model="showRelato"/>Relato
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        value="necesidad"
                                                        v-model="showNecesidad"/>Necesidades
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        value="emocion"
                                                        v-model="showEmocion"/>Emociones
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        value="estrategia"
                                                        v-model="showEstrategia"/>Estrategia
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-4 align-self-start">

                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        value="interes"
                                                        v-model="showInteres"/>Intereses
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        value="conflicto"
                                                        v-model="showConflicto"/>Conflicto
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        value="keyword"
                                                        v-model="showKeyword"/>Palabras clave
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        value="pregunta"
                                                        v-model="showPregunta"/>Pregunta
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-4 align-self-start">
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        value="actores"
                                                        v-model="showActores"/>Actores
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        value="hecho"
                                                        v-model="showHecho"/>Hechos
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        value="posicion"
                                                        v-model="showPosicion"/>Posiciones
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group" v-if="showRelato">
                                                    <div class="card border-primary">
                                                        <div class="card-body">
                                                            <h5 class="card-title">Relato</h5>
                                                            <vue-editor v-model="caso.relato"></vue-editor>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group" v-if="showHecho">
                                                    <div class="card border-primary">
                                                        <div class="card-body">
                                                            <h5 class="card-title">Hecho</h5>
                                                            <vue-editor v-model="caso.hecho"></vue-editor>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group" v-if="showNecesidad">
                                                    <div class="card border-primary">
                                                        <div class="card-body">
                                                            <h5 class="card-title">Necesidad</h5>
                                                            <vue-editor v-model="caso.necesidad"></vue-editor>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group" v-if="showEmocion">
                                                    <div class="card border-primary">
                                                        <div class="card-body">
                                                            <h5 class="card-title">Emoción</h5>
                                                            <vue-editor v-model="caso.emocion"></vue-editor>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group" v-if="showPosicion">
                                                    <div class="card border-primary">
                                                        <div class="card-body">
                                                            <h5 class="card-title">Posición</h5>
                                                            <vue-editor v-model="caso.posicion"></vue-editor>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group" v-if="showInteres">
                                                    <div class="card border-primary">
                                                        <div class="card-body">
                                                            <h5 class="card-title">Interes</h5>
                                                            <vue-editor v-model="caso.interes"></vue-editor>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group" v-if="showActores">
                                                    <div class="card border-primary">
                                                        <div class="card-body">
                                                            <h5 class="card-title">Actores</h5>
                                                            <vue-editor v-model="caso.actores"></vue-editor>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group" v-if="showConflicto">
                                                    <div class="card border-primary">
                                                        <div class="card-body">
                                                            <h5 class="card-title">Conflicto</h5>
                                                            <vue-editor v-model="caso.conflicto"></vue-editor>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group" v-if="showKeyword">
                                                    <div class="card border-primary">
                                                        <div class="card-body">
                                                            <h5 class="card-title">Palabras clave</h5>
                                                            <vue-editor v-model="caso.keyword"></vue-editor>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group" v-if="showPregunta">
                                                    <div class="card border-primary">
                                                        <div class="card-body">
                                                            <h5 class="card-title">Pregunta</h5>
                                                            <vue-editor v-model="caso.pregunta"></vue-editor>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group" v-if="showEstrategia">
                                                    <div class="card border-primary">
                                                        <div class="card-body">
                                                            <h5 class="card-title">Estrategia</h5>
                                                            <vue-editor v-model="caso.estrategia"></vue-editor>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="modal-footer">
                                        <button class="btn btn-danger" @click="cancelarModal()">
                                            <i class="fa fa-times"></i>
                                            Cancelar
                                        </button>
                                        <button class="btn btn-success" @click="crearAnalisis" v-if="editing == false">
                                            <i class="fa fa-check"></i>
                                            Crear Caso
                                        </button>
                                        <button class="btn btn-warning" @click="editarAnalisis" v-if="editing == true">
                                            <i class="fa fa-pencil"></i>
                                            Editar Caso
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>

            <div v-if="showModalView">
                <transition name="modal">
                    <div class="modal-mask">
                        <div class="modal-wrapper">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h4 class="modal-title text-primary">Caso
                                            {{ caso.id }}</h4>
                                        <button type="button" class="close" @click="cancelarModal()">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="form-group">
                                            <label class="text-primary">Usuarios:</label>
                                            <ul>
                                                <li :key="user.id" v-for="user in caso.users">
                                                    {{ user.name }}
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="form-group">
                                            <label class="text-primary">Joven:</label>
                                            {{ joven.nombres }} {{ joven.apellido_paterno}} {{ joven.apellido_materno}}
                                        </div>
                                        <div class="form-group">
                                            <label class="text-primary">Fecha:</label>
                                            {{ caso.date }}
                                        </div>
                                        <div class="form-group" v-if="caso.relato">
                                            <div class="card border-primary">
                                                <div class="card-body">
                                                    <h5 class="card-title">Relato</h5>
                                                    <blockquote class="blockquote">
                                                        <p v-html="caso.relato"></p>
                                                    </blockquote>    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" v-if="caso.hecho">
                                            <div class="card border-primary">
                                                <div class="card-body">
                                                    <h5 class="card-title">Hecho</h5>
                                                    <blockquote class="blockquote">
                                                        <p v-html="caso.hecho"></p>
                                                    </blockquote>    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" v-if="caso.necesidad">
                                            <div class="card border-primary">
                                                <div class="card-body">
                                                    <h5 class="card-title">Necesidad</h5>
                                                    <blockquote class="blockquote">
                                                        <p v-html="caso.necesidad"></p>
                                                    </blockquote>    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" v-if="caso.emocion">
                                            <div class="card border-primary">
                                                <div class="card-body">
                                                    <h5 class="card-title">Emoción</h5>
                                                    <blockquote class="blockquote">
                                                        <p v-html="caso.emocion"></p>
                                                    </blockquote>    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" v-if="caso.posicion">
                                            <div class="card border-primary">
                                                <div class="card-body">
                                                    <h5 class="card-title">Posición</h5>
                                                    <blockquote class="blockquote">
                                                        <p v-html="caso.posicion"></p>
                                                    </blockquote>    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" v-if="caso.interes">
                                            <div class="card border-primary">
                                                <div class="card-body">
                                                    <h5 class="card-title">Interés</h5>
                                                    <blockquote class="blockquote">
                                                        <p v-html="caso.interes"></p>
                                                    </blockquote>    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" v-if="caso.actores">
                                            <div class="card border-primary">
                                                <div class="card-body">
                                                    <h5 class="card-title">Actores</h5>
                                                    <blockquote class="blockquote">
                                                        <p v-html="caso.actores"></p>
                                                    </blockquote>    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" v-if="caso.conflicto">
                                            <div class="card border-primary">
                                                <div class="card-body">
                                                    <h5 class="card-title">Conflicto</h5>
                                                    <blockquote class="blockquote">
                                                        <p v-html="caso.conflicto"></p>
                                                    </blockquote>    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" v-if="caso.keyword">
                                            <div class="card border-primary">
                                                <div class="card-body">
                                                    <h5 class="card-title">Palabras clave</h5>
                                                    <blockquote class="blockquote">
                                                        <p v-html="caso.keyword"></p>
                                                    </blockquote>    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" v-if="caso.pregunta">
                                            <div class="card border-primary">
                                                <div class="card-body">
                                                    <h5 class="card-title">Pregunta</h5>
                                                    <blockquote class="blockquote">
                                                        <p v-html="caso.pregunta"></p>
                                                    </blockquote>    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" v-if="caso.estrategia">
                                            <div class="card border-primary">
                                                <div class="card-body">
                                                    <h5 class="card-title">Estrategia</h5>
                                                    <blockquote class="blockquote">
                                                        <p v-html="caso.estrategia"></p>
                                                    </blockquote>    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button class="btn btn-danger" @click="cancelarModal()">
                                            <i class="fa fa-times"></i>
                                            Cancelar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>

            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h1>Analisis de caso</h1>
                        <hr/>
                        <div class="form-group text-right">
                            <button class="btn btn-rosa" @click="showModal = true">
                                <i class="fa fa-plus"></i>
                                Crear Caso
                            </button>
                        </div>
                        <div class="form-group">
                            <vue-good-table
                                :compactMode="true"
                                :columns="columns"
                                :rows="casos"
                                :search-options="{
                                enabled: true,
                                placeholder: 'Buscar en la tabla',
                            }"
                                :pagination-options="{
                                enabled: true,
                                mode: 'records',
                                perPage: 5,
                                perPageDropdown: [3, 7, 9],
                                dropdownAllowAll: true,
                                setCurrentPage: 1,
                                nextLabel: 'siguiente',
                                prevLabel: 'anterior',
                                rowsPerPageLabel: 'Filas por página',
                                ofLabel: 'de',
                                pageLabel: 'página', // for 'pages' mode
                                allLabel: 'Todos',
                            }">
                                <template slot="table-row" slot-scope="props">
                                    <span v-if="props.column.field == 'participantes'">
                                        <ul>
                                            <li :key="item.id" v-for="item in props.row.users">
                                                {{ item.name }}
                                            </li>
                                        </ul>
                                    </span>
                                    <span v-if="props.column.field == 'actions'">
                                        <div class="btn-group">
                                            <button class="btn btn-danger text-white" @click="generarPdf(props.row.id)">
                                                <i class="fa fa-file-pdf"></i>
                                            </button>
                                            <button class="btn btn-info text-white" @click="verCaso(props.row)">
                                                <i class="fa fa-eye"></i>
                                            </button>
                                            <button class="btn btn-warning text-white" @click="editarCaso(props.row)">
                                                <i class="fa fa-pencil"></i>
                                            </button>
                                            <button class="btn btn-danger" @click="eliminarCaso(props.row.id)">
                                                <i class="fa fa-trash"></i>
                                            </button>
                                        </div>
                                    </span>
                                    <span v-else>
                                        {{ props.formattedRow[props.column.field] }}
                                    </span>
                                </template>
                            </vue-good-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
    <script>
        import VueEditor from "vue2-editor";
        import axios from "axios";
        import Swal from "sweetalert2";
        import "vue-select/dist/vue-select.css";
        export default {
            props: [
                "propsUsuarios", "propsDimensiones", "propsJoven"
            ],
            components: {},
            computed: {},
            created() {
                
                this.usuarios = JSON.parse(this.propsUsuarios);
                this.dimensiones = JSON.parse(this.propsDimensiones);
                this.joven = JSON.parse(this.propsJoven);
                console.log("los usuarios son: ", this.usuarios);
                console.log("los dimensiones son: ", this.dimensiones);
                console.log("los jovenes son: ", this.joven);
            },
            mounted(){
                this.interval = setInterval(() => this.getCasos(), 5000);
            },
            data() {
                return {
                    content: '<h1>Initial Content</h1>', errors: [],
                    //estados globales
                    editing: false,
                    //props
                    usuarios: [],
                    dimensiones: [],
                    jovenes: [],
                    //array para selects y mostrar datos
                    users: [],
                    casos: [],
                    //modelo de caso para crear/editar/ver
                    caso: {
                        id: "",
                        relato: "",
                        hecho: "",
                        necesidad: "",
                        interes: "",
                        emocion: "",
                        posicion: "",
                        actores: "",
                        conflicto: "",
                        keyword: "",
                        pregunta: "",
                        estrategia: "",
                        nnaj_id: "",
                        users: [],
                        errors: []
                    },
                    //boleanos
                    showRelato: false,
                    showHecho: false,
                    showNecesidad: false,
                    showInteres: false,
                    showEmocion: false,
                    showPosicion: false,
                    showActores: false,
                    showConflicto: false,
                    showKeyword: false,
                    showPregunta: false,
                    showEstrategia: false,
                    showModal: false,
                    showModalView: false,
                    //vue good table
                    columns: [
                        {
                            label: "Fecha",
                            field: "date",
                            dateInputFormat: "yyyy-MM-dd",
                            dateOutputFormat: "MMM do yy"
                        }, {
                            label: "Joven",
                            field: "joven.full_name",
                            type: "string"
                        }, {
                            label: "Actores",
                            field: "participantes",
                            tdClass: "text-center",
                            thClass: "text-center",
                            sortable: false
                        }, {
                            label: "Creado en",
                            field: "created_at",
                            formatFn: this.formatDate
                        }, {
                            label: "Acciones",
                            field: "actions",
                            tdClass: "text-center text-nowrap",
                            thClass: "text-center text-nowrap",
                            sortable: false
                        }
                    ]
                };
            },
            methods: {
                formatDate(date) {
                    var date = new Date(date);
                    return date.toLocaleString();
                },
                checkForm: function (e) {
                    console.log("el evento a validar es: ", e);
                    e.preventDefault();
                    let errors = [];
                    console.log("el caso es: ", this.caso);
                    if (this.caso.users.length == 0) {
                        errors.push("Debe seleccionar al menos un usuario.");
                    }
                    if (!this.caso.date) {
                        errors.push("Debe seleccionar un fecha.");
                    }
                
                    if (this.showRelato && !this.caso.relato) {
                        errors.push("Debe ingresar un relato.");
                    }
                    if (this.showHecho && !this.caso.hecho) {
                        errors.push("Debe ingresar un hecho.");
                    }
                    if (this.showNecesidad && !this.caso.necesidad) {
                        errors.push("Debe ingresar una necesidad.");
                    }
                    if (this.showInteres && !this.caso.interes) {
                        errors.push("Debe ingresar un interes.");
                    }
                    if (this.showEmocion && !this.caso.emocion) {
                        errors.push("Debe ingresar una emocion.");
                    }
                    if (this.showPosicion && !this.caso.posicion) {
                        errors.push("Debe ingresar una posicion.");
                    }
                    if (this.showActores && !this.caso.actores) {
                        errors.push("Debe ingresar actores.");
                    }
                    if (this.showConflicto && !this.caso.conflicto) {
                        errors.push("Debe ingresar conflicto.");
                    }
                    if (this.showKeyword && !this.caso.keyword) {
                        errors.push("Debe ingresar palabras clave.");
                    }
                    if (this.showPregunta && !this.caso.pregunta) {
                        errors.push("Debe ingresar pregunta.");
                    }
                    if (this.showEstrategia && !this.caso.estrategia) {
                        errors.push("Debe ingresar estrategia.");
                    }
                    if (!this.caso.estrategia && !this.caso.relato && !this.caso.conflicto && !this.caso.interes && !this.caso.emocion && !this.caso.actores && !this.caso.keyword && !this.caso.hecho && !this.caso.necesidad) {
                        errors.push(
                            "Debe ingresar al menos un hecho, relato, conflicto, interes, emocion...."
                        );
                    }
                    if (errors.length > 0) {
                        this.errors = errors;
                        return false;
                    } else {
                        return true;
                    }
                },
                async getCasos() {
                    axios
                        .get(`/getCasos/${this.joven.id}`)
                        .then((response) => {
                            console.log("la response es: ", response.data);
                            this.casos = response.data.casos;
                        })
                        .catch(function (error) {
                            // handle error
                            console.log("ocurrio un error: ", error);
                        });
                },
                async crearAnalisis(e) {
                    const validatedForm = this.checkForm(e);
                    if (validatedForm) {
                        let data = {
                            date: this.caso.date,
                            nnaj_id: this.joven.id,
                            comment: this.caso.comment,
                            users: this.caso.users,
                            relato: this.caso.relato,
                            hecho: this.caso.hecho,
                            necesidad: this.caso.necesidad,
                            interes: this.caso.interes,
                            emocion: this.caso.emocion,
                            posicion: this.caso.posicion,
                            actores: this.caso.actores,
                            conflicto: this.caso.conflicto,
                            keyword: this.caso.keyword,
                            pregunta: this.caso.pregunta,
                            estrategia: this.caso.estrategia
                        };
                        console.log("la data al agregar el analisis de caso es: ", data);
                        let me = this;
                        axios
                            .post("/create_caso", data, {
                                headers: {
                                    "Content-Type": "application/json"
                                }
                            })
                            .then(function (response) {
                                console.log("la repsuesta al agregar el caso es: ", response);
                                Swal.fire("Caso creado!", "", "success");
                                me.showModal = false;
                                me.limpiarFormulario();
                                me.getCasos();
                            })
                            .catch(function (error) {
                                console.log("FAILURE!!", error);
                            });
                    } else {
                        this.$nextTick(() => {
                            this.$refs.errorsForm.scrollTop = 0;
                        });
                    }
                },
                async editarAnalisis(e) {
                    const validatedForm = this.checkForm(e);
                    if (validatedForm) {
                        let data = {
                            id: this.caso.id,
                            date: this.caso.date,
                            nnaj_id: this.caso.nnaj_id.id,
                            users: this.caso.users,
                            relato: this.showRelato
                                ? this.caso.relato
                                : null,
                            hecho: this.showHecho
                                ? this.caso.hecho
                                : null,
                            necesidad: this.showNecesidad
                                ? this.caso.necesidad
                                : null,
                            interes: this.showInteres
                                ? this.caso.interes
                                : null,
                            emocion: this.showEmocion
                                ? this.caso.emocion
                                : null,
                            posicion: this.showPosicion
                                ? this.caso.posicion
                                : null,
                            actores: this.showActores
                                ? this.caso.actores
                                : null,
                            conflicto: this.showConflicto
                                ? this.caso.conflicto
                                : null,
                            keyword: this.showKeyword
                                ? this.caso.keyword
                                : null,
                            pregunta: this.showPregunta
                                ? this.caso.pregunta
                                : null,
                            estrategia: this.showEstrategia
                                ? this.caso.estrategia
                                : null
                        };
                        console.log("la data al editar el analisis de caso es: ", data);
                        let me = this;
                        axios
                            .post("/edit_caso", data, {
                                headers: {
                                    "Content-Type": "application/json"
                                }
                            })
                            .then(function (response) {
                                console.log("la repsuesta al editar el caso es: ", response);
                                Swal.fire("Caso editado!", "", "success");
                                me.cancelarModal();
                                me.getCasos();
                            })
                            .catch(function (error) {
                                console.log("FAILURE!!", error);
                            });
                    } else {
                        this.$nextTick(() => {
                            this.$refs.errorsForm.scrollTop = 0;
                        });
                    }
                },
                verCaso(caso) {
                    console.log("el caso es: ", caso),
                    (this.caso = caso);
                    this.showModalView = true;
                },
                editarCaso(caso) {
                    console.log("el caso a editar es: xf ", caso);
                    this.caso = caso;
                    this.caso.nnaj_id = caso.joven;
                    this.editing = true;
                    this.checkearEdicion(caso);
                    this.showModal = true;
                },
                checkearEdicion(caso) {
                    this.showRelato = caso.relato
                        ? true
                        : false;
                    this.showHecho = caso.hecho
                        ? true
                        : false;
                    this.showNecesidad = caso.necesidad
                        ? true
                        : false;
                    this.showInteres = caso.interes
                        ? true
                        : false;
                    this.showEmocion = caso.emocion
                        ? true
                        : false;
                    this.showPosicion = caso.posicion
                        ? true
                        : false;
                    this.showActores = caso.actores
                        ? true
                        : false;
                    this.showConflicto = caso.conflicto
                        ? true
                        : false;
                    this.showKeyword = caso.keyword
                        ? true
                        : false;
                    this.showPregunta = caso.pregunta
                        ? true
                        : false;
                    this.showEstrategia = caso.estrategia
                        ? true
                        : false;
                },
                cancelarModal() {
                    this.limpiarFormulario();
                    this.showModal = false;
                    this.showModalView = false;
                    this.editing = false;
                },
                limpiarFormulario() {
                    this.errors = [];
                    this.showEstrategia = false;
                    this.showPosicion = false;
                    this.showActores = false;
                    this.showPregunta = false;
                    this.showNecesidad = false;
                    this.showHecho = false;
                    this.showEmocion = false;
                    this.showConflicto = false;
                    this.showKeyword = false;
                    this.showInteres = false;
                    this.showRelato = false;
                    this.caso = {
                        user_id: "",
                        date: "",
                        nnaj_id: "",
                        dimension_id: "",
                        comment: "",
                        users: "",
                        relato: "",
                        hecho: "",
                        necesidad: "",
                        interes: "",
                        emocion: "",
                        posicion: "",
                        actores: "",
                        conflicto: "",
                        keyword: "",
                        pregunta: "",
                        estrategia: "",
                        users: [],
                        errors: []
                    };
                },
                async eliminarCaso(id) {
                    let me = this;
                    Swal
                        .fire(
                            {title: "¿Esta seguro de querer borrar el caso?", showDenyButton: false, showCancelButton: true, confirmButtonText: `Eliminar`, cancelButtonText: "Cancelar"}
                        )
                        .then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                axios
                                    .delete(`/delete_caso/${id}`, {
                                        headers: {
                                            "Content-Type": "application/json"
                                        }
                                    })
                                    .then(function (response) {
                                        console.log("la repsuesta al eliminar el institucion es: ", response);
                                        Swal.fire("Caso eliminado!", "", "success");
                                        me.getCasos();
                                    })
                                    .catch(function (error) {
                                        console.log("FAILURE!!", error);
                                    });
                            } else if (result.isDenied) {
                                Swal.fire("Changes are not saved", "", "info");
                            }
                        });
                },
                async generarPdf(id) {
                    Swal
                        .fire({
                            title: "Estamos generando tu reporte, espera unos segundos",
                            text: "El reporte PDF simplemente se desrgara en su equipo...",
                            iconHtml: "<i class='fas fa-circle-notch fa-spin'></i>",
                            timer: 5000,
                            timerProgressBar: true,
                            showConfirmButton: false,
                            didOpen: () => {
                                window.location.href = `/generate-pdf-from-view/${id}`;
                            }
                        })
                        .then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                console.log("I was closed by the timer");
                            }
                        });
                }
            }
        };
    </script>