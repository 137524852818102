<template>
    <div class="row">
        <div v-if="showModal">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog modal-sm">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title text-info">
                                        {{ this.editing ? 'Editar' : 'Crear' }} institucion
                                    </h4>
                                    <button
                                        type="button"
                                        class="close"
                                        @click="cancelarModal()"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body" id="errorsForm" ref="errorsForm">
                                    <div class="form-group" v-if="errors.length>0" >
                                        <div class="card">
                                            <div class="card-body bg-danger text-white">
                                                Errores al llenar los datos
                                                <ul>
                                                    <li :key="error" v-for="error in errors">{{ error }}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Nombre institucion</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="institucion.full_name"
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label>Nombre corto</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="institucion.short_name"
                                        />
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button
                                        class="btn btn-danger"
                                        @click="cancelarModal()"
                                    >
                                        <i class="fa fa-times"></i>
                                        Cancelar
                                    </button>
                                    <button v-if="!editing"
                                        class="btn btn-success"
                                        @click="createInstitucion"
                                    >
                                        <i class="fa fa-check"></i>
                                        Crear
                                    </button>
                                    <button
                                        class="btn btn-warning text-white"
                                        @click="editInstitucion"
                                        v-if="editing"
                                    >
                                        <i class="fa fa-pencil"></i>
                                        Editar
                                    </button>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h1>Instituciones</h1>
                    <hr>
                    <div class="form-group text-right">
                        <button
                            class="btn btn-rosa"
                            @click="showModal = true"
                        >
                            <i class="fa fa-plus"></i>
                            Crear Institucion
                        </button>
                    </div>
                    <div class="form-group">
                        <download-excel :data="instituciones">
                            <i class="fa fa-download"></i>
                            Descargar Excel
                        </download-excel>
                    </div>
                    <div class="form-group">
                        <vue-good-table
                            compactMode
                            :columns="columns"
                            :rows="instituciones" 
                            :search-options="{
                                enabled: true,
                                placeholder: 'Buscar en la tabla',
                            }" 
                            :pagination-options="{
                                enabled: true,
                                mode: 'records',
                                perPage: 5,
                                perPageDropdown: [3, 7, 9],
                                dropdownAllowAll: true,
                                setCurrentPage: 1,
                                nextLabel: 'siguiente',
                                prevLabel: 'anterior',
                                rowsPerPageLabel: 'Filas por página',
                                ofLabel: 'de',
                                pageLabel: 'página', // for 'pages' mode
                                allLabel: 'Todos',
                            }">
                              <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field == 'actions'">
                                    <div class="btn-group">
                                        <button class="btn btn-warning" @click="editarInstitucion(props.row)">
                                            <i class="fa fa-pencil"></i>
                                        </button>
                                        <button class="btn btn-danger" @click="eliminarInstitucion(props.row.id)">
                                            <i class="fa fa-trash"></i>
                                        </button>
                                    </div>
                                </span>
                                <span v-else>
                                {{props.formattedRow[props.column.field]}}
                                </span>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import Swal from 'sweetalert2';
export default {
    computed: {},
    created() {
        this.getInstituciones();
    },
    data() {
        return {
            errors: [],
            editing: false,
            institucion: {
                id: "",
                full_name: "",
                short_name: "",
            },
            showModal: false,
            instituciones: [],
            //vue good table
            columns: [
                {
                    label: 'Nombre Completo',
                    field: 'full_name',
                    type: 'string'
                },
                {
                    label: 'Nombre Corto',
                    field: 'short_name',
                    type: 'string',
                },
                {
                    label: 'Acciones',
                    field: 'actions',
                    tdClass: 'text-center text-nowrap',
                    thClass: 'text-center text-nowrap',
                    sortable: false,
                }
            ],
        };
    },
    methods: {
        cancelarModal(){
            this.limpiarFormulario();
            this.showModal = false;
            this.editing = false;
        },
        limpiarFormulario(){
            this.errors = [];
            this.institucion =  {
                id: "",
                full_name: "",
                short_name: "",
            };
        },
        getInstituciones() {
            axios
                .get("/getInstituciones")
                .then(response => {
                    console.log("la response es: ", response.data);
                    this.instituciones = response.data.instituciones;
                })
                .catch(function(error) {
                    // handle error
                    console.log("ocurrio un error: ", error);
                });
        },
        checkForm: function (e) {
            console.log("el evento a validar es: ", e);
            e.preventDefault();
            let errors = [];
            console.log("el institucion es: ", this.institucion);
            if (!this.institucion.full_name) {
                errors.push('Debe ingresar el nombre completo de la institucion.');
            }
            if (!this.institucion.short_name) {
                errors.push('Debe ingresar el nombre corto de la institucion.');
            }
            if(errors.length > 0 ){
                this.errors = errors;
                return false;
            }else{
                return true;
            }
        },
        createInstitucion(e) {
            const validatedForm = this.checkForm(e);
            if(validatedForm){
                let data = {
                    full_name: this.institucion.full_name,
                    short_name: this.institucion.short_name
                };
                console.log(
                    "la data al agregar el createInstitucion es: ",
                    data
                );
                let me = this;
                axios
                    .post("/create_institucion", data, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                    .then(function(response) {
                        console.log(
                            "la repsuesta al agregar el usuario es: ",
                            response
                        );
                        Swal.fire('Institucion creada!', '', 'success')
                        me.cancelarModal();
                        me.getInstituciones();
                
                    })
                    .catch(function(error) {
                        console.log("FAILURE!!", error);
                    });
            }else{
                this.$nextTick(() => {
                    this.$refs.errorsForm.scrollTop = 0;
                });
            }
        },
        editInstitucion(e) {
            const validatedForm = this.checkForm(e);
            if(validatedForm){
                let data = {
                    id: this.institucion.id,
                    full_name: this.institucion.full_name,
                    short_name: this.institucion.short_name
                };
                console.log(
                    "la data al editar el institucion es: ",
                    data
                );
                let me = this;
                axios
                    .post("/editInstitucion", data, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                    .then(function(response) {
                        console.log(
                            "la repsuesta al agregar el usuario es: ",
                            response
                        );
                        Swal.fire('Institucion editada!', '', 'success')
                        me.cancelarModal();
                        me.getInstituciones();
                
                    })
                    .catch(function(error) {
                        console.log("FAILURE!!", error);
                    });
            }else{
                this.$nextTick(() => {
                    this.$refs.errorsForm.scrollTop = 0;
                });
            }
        },
        editarInstitucion(institucion){
            this.editing = true;
            this.institucion = institucion;
            this.showModal = true;
        },
        eliminarInstitucion(id){
              let me = this;
            Swal.fire({
                title: '¿Esta seguro de querer borrar la institucion?',
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: `Eliminar`,
                cancelButtonText:'Cancelar',
                }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    axios.delete(`/delete_institution/${id}`, {
                            headers: {
                                "Content-Type": "application/json"
                            }
                        })
                        .then(function(response) {
                            console.log(
                                "la repsuesta al eliminar el institucion es: ",
                                response
                            );
                             Swal.fire('Institucion eliminada!', '', 'success')
                            me.getInstituciones();
                    
                        })
                        .catch(function(error) {
                            console.log("FAILURE!!", error);
                        });
                   
                } else if (result.isDenied) {
                    Swal.fire('Changes are not saved', '', 'info')
                }
                })
        },
    }
};
</script>
